import { useEffect, useState } from "react";
import { AppThemeProvider } from "../../theme/ThemeProvider";
import MobileView from "./MobileView";
import AppContent from "./AppContent";
export const LAST_CONNECTION = "LAST_CONNECTION";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  // Choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  if (isMobile) {
    return (
      <AppThemeProvider>
        <MobileView />
      </AppThemeProvider>
    );
  }

  return (
    <AppThemeProvider>
      <AppContent />
    </AppThemeProvider>
  );
}

export default App;
