export let getRequestFilterToUpdate = ({
  params,
  patient,
}: {
  params: any;
  patient: string;
}) => {
  // Optional everywere = patient
  // Require everywere = start/end
  const ltDate =
    new Date(Number(params.end)) > new Date()
      ? new Date()
      : new Date(Number(params.end));
  // 1 ----- STATUS === true && COLLABORATOR === true
  if (params.status && params.collaborator) {
    return {
      plannedOn: {
        $gte: new Date(Number(params.start)),
        $lt: ltDate,
      },
      deleted: false,
      ...(patient ? { patient } : {}),
      ...(params.status ? { status: params.status } : {}),
      ...(params.status === "done" ? { doneBy: params.collaborator } : {}),
      ...(params.status === "to-do" ? { createdBy: params.collaborator } : {}),
      ...(params.status === "billed" ? { billedBy: params.collaborator } : {}),
      ...(params.status === "ready-to-bill"
        ? { readyToBillBy: params.collaborator }
        : {}),
    };
  }
  // 2 ----- STATUS === false && COLLABORATOR === true
  if (!params.status && params.collaborator) {
    return {
      ...(patient ? { patient } : {}),
      plannedOn: {
        $gte: new Date(Number(params.start)),
        $lt: ltDate,
      },
      deleted: false,
      $or: [
        {
          readyToBillBy: params.collaborator,
        },
        {
          doneBy: params.collaborator,
        },
        {
          billedBy: params.collaborator,
        },
        {
          createdBy: params.collaborator,
        },
      ],
    };
  }
  // 3 ----- STATUS === true && COLLABORATOR === false
  if (params.status && !params.collaborator) {
    return {
      ...(patient ? { patient } : {}),
      plannedOn: {
        $gte: new Date(Number(params.start)),
        $lt: ltDate,
      },
      deleted: false,
      status: params.status,
    };
  }
  // 4 ----- STATUS === false && COLLABORATOR === false
  if (!params.status && !params.collaborator) {
    return {
      ...(patient ? { patient } : {}),
      plannedOn: {
        $gte: new Date(Number(params.start)),
        $lt: ltDate,
      },
      deleted: false,
    };
  }
};

export let getRequestFilter = (params: any, patient?: string) => {
  // Optional everywere = patient
  // Require everywere = start/end
  // 1 ----- STATUS === true && COLLABORATOR === true
  if (params.status && params.collaborator) {
    return {
      plannedOn: {
        $gte: new Date(Number(params.start)),
        $lt: new Date(Number(params.end)),
      },
      deleted: false,
      ...(patient ? { patient } : {}),
      ...(params.status ? { status: params.status } : {}),
      ...(params.status === "done" ? { doneBy: params.collaborator } : {}),
      ...(params.status === "to-do" ? { createdBy: params.collaborator } : {}),
      ...(params.status === "billed" ? { billedBy: params.collaborator } : {}),
      ...(params.status === "ready-to-bill"
        ? { readyToBillBy: params.collaborator }
        : {}),
    };
  }
  // 2 ----- STATUS === false && COLLABORATOR === true
  if (!params.status && params.collaborator) {
    return {
      ...(patient ? { patient } : {}),
      deleted: false,
      plannedOn: {
        $gte: new Date(Number(params.start)),
        $lt: new Date(Number(params.end)),
      },
      $or: [
        {
          readyToBillBy: params.collaborator,
        },
        {
          doneBy: params.collaborator,
        },
        {
          billedBy: params.collaborator,
        },
        {
          createdBy: params.collaborator,
        },
      ],
    };
  }
  // 3 ----- STATUS === true && COLLABORATOR === false
  if (params.status && !params.collaborator) {
    return {
      ...(patient ? { patient } : {}),
      deleted: false,
      plannedOn: {
        $gte: new Date(Number(params.start)),
        $lt: new Date(Number(params.end)),
      },
      status: params.status,
    };
  }
  // 4 ----- STATUS === false && COLLABORATOR === false
  if (!params.status && !params.collaborator) {
    return {
      deleted: false,
      plannedOn: {
        $gte: new Date(Number(params.start)),
        $lt: new Date(Number(params.end)),
      },
      ...(patient ? { patient } : {}),
    };
  }
};
