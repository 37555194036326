import { Button as MUIButton, ButtonBaseProps } from "@mui/material";
import { useState } from "react";
import { Box } from "./Box";
import { Text } from "./Text";
import { Modal } from "./Modal";
import { useTheme } from "@mui/material";

const ButtonCpn = (props) => {
  const theme = useTheme();
  return (
    <MUIButton
      {...props}
      sx={{
        backgroundColor: theme.colors.light,
        padding: "2px 10px",
        minHeight: 28,
        borderRadius: 1,
        color: "#303030",
        fontWeight: 300,
        textTransform: "inherit",
        "&:hover": {
          background: "#efefef",
          backgroundColor: theme.colors.primary,
          color: "white",
        },
        ...props?.style,
      }}
    />
  );
};
export function Button({
  textconfirm,
  withconfirm,
  onClick,
  ...props
}: {
  textconfirm?: string;
  withconfirm?: boolean;
  onClick?: any;
} & ButtonBaseProps) {
  const [open, setOpen] = useState(false);

  if (withconfirm) {
    return (
      <>
        <ButtonCpn textconfirm={textconfirm} {...props} onClick={() => setOpen(true)} />

        <Modal open={open}>
          <Text fontSize="22px" fontWeight="600">
            Confirmer ?
          </Text>
          <Box p={2}>
            <Text>{textconfirm ? textconfirm : "Etes-vous sûr de vouloir supprimer cet élément ?"}</Text>
          </Box>
          <Box p={2}>
            <MUIButton
              style={{
                backgroundColor: "#ebebeb",
                padding: "2px 10px",
                height: 28,
                borderRadius: 3,
                color: "#303030",
                fontWeight: 600,
              }}
              onClick={() => setOpen(false)}
            >
              Non
            </MUIButton>
            <Box p={2} />
            <MUIButton
              style={{
                backgroundColor: "#ebebeb",
                padding: "2px 10px",
                height: 28,
                borderRadius: 3,
                color: "#303030",
                fontWeight: 600,
              }}
              onClick={() => {
                onClick();
                setOpen(false);
              }}
            >
              Oui
            </MUIButton>
          </Box>
        </Modal>
      </>
    );
  }
  return <ButtonCpn textconfirm={textconfirm} onClick={onClick} {...props} />;
}
