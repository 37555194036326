import { Journey, Patient, User } from "@/types/taxi";
import { Box, Button, Text } from "@/ui";
import { useEffect, useState } from "react";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { JourneyItem } from "@/components/taxi/JourneyItem";
import RemoveRoadIcon from "@mui/icons-material/RemoveRoad";
import { useTheme } from "@mui/material";

export function RenderJourney({ patient }: { patient: Patient }) {
  const [limite, setLimite] = useState<number>(50);
  const [journeys, setJourneys] = useState<Journey[]>([]);
  const [currentUser, setCurrentUser] = useState<User>();
  const { dbName } = useApplicationContext();
  const { client, user } = useMongoContext();
  const theme = useTheme();
  const taxi_username = user?.identities[0]?.id;

  async function getData() {
    try {
      const patientCollection = client.db(dbName).collection("Journey");
      const userCollection = client.db(dbName).collection("User");
      const u = await userCollection.findOne({ username: taxi_username });
      const j = await patientCollection.find(
        { username: taxi_username, patient: patient._id, deleted: { $ne: true } },
        { limit: limite, sort: { outwardDepartureDatetime: -1 } }
      );
      setCurrentUser(u);
      setJourneys(j);
    } catch (error) {
      console.log("error :", error);
    }
  }

  useEffect(() => {
    if (user && client) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client, limite, patient]);

  return (
    <Box height="100%" flexDirection="column" style={{ overflowY: "scroll", overflowX: "hidden" }} width="100%">
      {journeys?.map((journey) => {
        return (
          <Box my={2} key={journey._id}>
            <JourneyItem journey={journey} currentUser={currentUser} />
          </Box>
        );
      })}
      {!journeys?.length && (
        <Box justifyContent="center" alignItems="center" width="100%" height="100%" flexDirection="column">
          <RemoveRoadIcon style={{ fontSize: 100, color: theme.palette?.primary.light }} />
          <Text p={2}>Aucun trajet pour ce patient</Text>
        </Box>
      )}
      {limite === (journeys?.length || 0) && (
        <Box justifyContent="center" alignItems="center" p={2}>
          <Button onClick={() => setLimite(limite + 50)}>Charger plus</Button>
        </Box>
      )}
    </Box>
  );
}
