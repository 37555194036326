import { FieldProps } from "formik";
import Select from "react-select";
import { Box } from "../Box";
import { FormControl, FormLabel, NativeSelect, useTheme } from "@mui/material";

export function SelectField({
  field: { name, value },
  form: { setFieldValue },
  label,
  width,
  data,
  ...props
}: FieldProps & { label: string; width: any; data: { value: string; label: string }[] }) {
  const theme = useTheme();
  return (
    <Box flexDirection="column" width={width}>
      <FormControl fullWidth>
        {label && <FormLabel>{label}</FormLabel>}

        <Box pt={2} width={width}>
          <Select
            options={data}
            onChange={(e) => setFieldValue(name, e)}
            styles={{
              container: (base, state) => ({ ...base, width: "100%", outlineColor: theme.palette.primary.main, height: "68px" }),
              option: (base, state) => ({ ...base, backgroundColor: "white", color: theme.colors.text, width: "100%" }),
              control: (base, state) => ({
                ...base,
                ":hover": {
                  borderColor: state?.isFocused ? theme.palette.primary.main : "rgba(0, 0, 0, 0.87)",
                  cursor: "pointer",
                },
                borderColor: state?.isFocused ? theme.palette.primary.main : "#c5c5c5",
                boxShadow: state?.isFocused ? `0 0 0 1px ${theme.palette.primary.main}` : "none",
                height: 56,
              }),
            }}
            name={name}
            value={value}
            {...props}
          />
          {/* <NativeSelect>
            {data?.map(({ label, value }) => (
              <option>{label}</option>
            ))}
          </NativeSelect> */}
        </Box>
      </FormControl>
    </Box>
  );
}
