import { Document, Patient } from "@/types/taxi";
import { Box, Button, Text } from "@/ui";
import { useEffect, useState } from "react";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { DocumentItem } from "@/components/taxi/DocumentItem";
import DescriptionIcon from "@mui/icons-material/Description";
import { useTheme } from "@mui/material";

export function RenderDocument({ patient }: { patient: Patient }) {
  const [limite, setLimite] = useState<number>(50);
  const [documents, setDocuments] = useState<Document[]>([]);
  const { dbName } = useApplicationContext();
  const { client, user } = useMongoContext();
  const taxi_username = user?.identities[0]?.id;
  const theme = useTheme();
  async function getData() {
    try {
      const patientCollection = client.db(dbName).collection("Document");
      const j = await patientCollection.find(
        { username: taxi_username, patient: patient._id, deleted: { $ne: true } },
        { limit: limite, sort: { timestamp: -1 } }
      );

      setDocuments(j);
    } catch (error) {
      console.log("error :", error);
    }
  }

  useEffect(() => {
    if (user && client) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client, limite, patient]);

  return (
    <Box height="100%" flexDirection="column" style={{ overflowY: "scroll", overflowX: "hidden" }} width="100%">
      {documents?.map((document) => {
        return (
          <Box my={2} key={document._id}>
            <DocumentItem document={document} key={document?._id} />
          </Box>
        );
      })}
      {!documents?.length && (
        <Box justifyContent="center" alignItems="center" width="100%" height="100%" flexDirection="column">
          <DescriptionIcon style={{ fontSize: 100, color: theme.palette?.primary.light }} />
          <Text p={2}>Aucun document pour ce patient</Text>
        </Box>
      )}
      {limite === (documents?.length || 0) && (
        <Box justifyContent="center" alignItems="center" p={2}>
          <Button onClick={() => setLimite(limite + 50)}>Charger plus</Button>
        </Box>
      )}
    </Box>
  );
}
