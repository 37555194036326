type Data = {
  idToken: string;
  refreshToken: string;
  token: string;
  user: {
    _id: string;
    email: string;
    firstname: string;
    lastname: string;
    _partitionKey: string;
    role: "titulaire" | "admin" | "remplacant" | "super-admin";
  };
  username: string;
  application: "inovidel" | "mobility" | "taxi";
};

export function getAccessToken(): Data["token"] {
  return localStorage.getItem("token");
}

export function setAccessToken(token: Data["token"]) {
  return localStorage.setItem("token", token);
}

export function getIdToken(): Data["idToken"] {
  return localStorage.getItem("id-token");
}

export function setIdToken(idToken: Data["idToken"]) {
  return localStorage.setItem("id-token", idToken);
}
export function getRefreshToken(): Data["refreshToken"] {
  return localStorage.getItem("refresh-token");
}

export function setRefreshToken(refreshToken: Data["refreshToken"]) {
  return localStorage.setItem("refresh-token", refreshToken);
}

export function setLocalStorageUser(user: Data["user"]) {
  return localStorage.setItem("user", JSON.stringify(user));
}

export function setLocalStorageApplication(application: Data["application"]) {
  return localStorage.setItem("application", JSON.stringify(application));
}

export function getLocalStorageApplication() {
  const application = localStorage.getItem("application");
  return application ? JSON.parse(application) : null;
}

export function saveInLocalStorage(data: Data) {
  setRefreshToken(data.refreshToken);
  setAccessToken(data.token);
  setLocalStorageUser(data.user);
}
