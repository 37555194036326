import { useNavigate } from "react-router-dom";
import { useMongoContext } from "./provider/MongoProvider";

export function PrivateRoute({ Component }) {
  const navigate = useNavigate();
  const { app } = useMongoContext();

  if (!app?.currentUser?.isLoggedIn) navigate("/welcome");

  return <Component />;
}
