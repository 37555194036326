import { ApplicationType } from "@/types/common";
import { OfficeWithRole } from "@/types/nurse";
import { fetchAPI } from "@/utils/fetchAPI";

type FetchSetCurrentOffice = { currentOffice: OfficeWithRole; realmApp: any; application: ApplicationType };

export async function fetchSetCurrentOffice({ currentOffice, realmApp, application }: FetchSetCurrentOffice) {
  try {
    const res = await fetchAPI({
      url: `/${application}/office/setAsCurrent`,
      options: {
        method: "POST",
        body: JSON.stringify({ currentOffice }),
      },
      realmApp,
      application,
    });
    console.log(" res:", res);
    return res;
  } catch (error) {
    throw new Error();
  }
}
