import { useField } from "formik";
import { Box } from "@/ui";
import { TextareaAutosize as Textarea, InputLabel as Label } from "@mui/material";

export function TextAreaField({ width = "400px", ...props }: any) {
  const [field] = useField(props);

  if (props?.label) {
    return (
      <Box style={{ width }}>
        <Label>{props?.label}</Label>
        <Textarea style={{ height: 28, marginTop: 3, backgroundColor: "#fff", borderColor: "#c7c7c7", color: "#7e7d7d" }} {...field} {...props} />
      </Box>
    );
  }
  return <Textarea style={{ height: 28, marginTop: 3, backgroundColor: "#fff", borderColor: "#c7c7c7", color: "#7e7d7d" }} {...field} {...props} />;
}
