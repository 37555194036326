import { Outlet } from "react-router-dom";
import { Box } from "@/ui";
import { Header, LeftNavBar } from "@/app/nurse/AppShell";
import { FormHoc } from "@/components/nurse/hoc/FormHoc";

export default function App() {
  return (
    <FormHoc>
      <Box flexDirection="column" height="100vh" justifyContent="center" alignItems="center" width="100vw">
        <div className="background-mountain-cofidoc" />
        <div className="background-cloud height40" />
        <div className="background-over" />
        <Header />
        <Box flexDirection="row" width="100%" height="calc(100% - 6vh)" justifyContent="center" alignItems="center">
          <LeftNavBar />

          <Box flexDirection="column" width="calc(100% - 196px)" height="100%" alignItems="center" className="overBg" style={{ overflowY: "scroll" }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </FormHoc>
  );
}
