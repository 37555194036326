/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@/ui";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { RenderInformation } from "./Tabs/info";
import { CustomTabPanel } from "../../../../../../components/common/CustomTabPanel";
import { RenderPrescription } from "./Tabs/prescriptions";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { Patient } from "@/types/nurse";
import { RenderTransmission } from "./Tabs/transmissions";

export default function PatientIdView() {
  const { patient }: { patient: Patient } = useOutletContext();
  const [value, setValue] = useState(0);

  function handleChange(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  }

  return (
    <Box width="100%" justifyContent="space-around">
      <Box sx={{ width: "100%" }} flexDirection="column">
        <Box>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{ height: "50px" }}>
            <Tab label="Ordonnances" />
            <Tab label="Information" />
            <Tab label="Transmissions" />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <RenderPrescription patient={patient} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <RenderInformation patient={patient} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <RenderTransmission patient={patient} />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
