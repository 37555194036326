import { StatusType } from "@/types/nurse";

export function getBackgroundByType(type: StatusType | "draft") {
  switch (type) {
    case "draft":
      return "yellow.pale";
    case "notStarted":
      return "green.main";
    case "pending":
      return "orange.main";
    case "over":
      return "grey.main";
    default:
      return "grey.main";
  }
}
