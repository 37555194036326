import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useAppContext } from "@/app/common/provider/AppProvider";
import { getIdToken, setIdToken, setAccessToken } from "@/utils/localStorage";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";

export default function Root() {
  const { application } = useApplicationContext();
  const { setUser, setClient, app, setApp } = useMongoContext();
  const { setLoading } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  const authorizedRoot = ["/taxi/login", "/taxi/forgot-password", "/taxi/create-password"];

  async function start() {
    try {
      let token = getIdToken();
      console.log("=>  token:", token);
      console.log("=>  application:", application);
      console.log("=>  currentUser", app);

      if (app?.currentUser?.isLoggedIn) {
        setUser(app?.currentUser);
        setApp(app);
        setClient(app.currentUser.mongoClient("mongodb-atlas"));
        setLoading(false);

        if (location?.pathname === "/" || location?.pathname === `/${application}`) {
          navigate(`/${application}/home`, { replace: true });
        }
      } else if (authorizedRoot.includes(location.pathname)) {
        return;
      } else if (token && !app?.currentUser?.isLoggedIn) {
        //@ts-ignore
        loginCustomJwt(token as string, app, application)
          .then((user) => {
            setUser(user);
            setApp(app);
            setClient(app.currentUser.mongoClient("mongodb-atlas"));
          })
          .catch(() => {
            app?.currentUser?.logOut();
            setAccessToken("");
            setIdToken("");
            navigate("/welcome");
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        navigate("/welcome");
      }
    } catch (e) {
      console.log("=> error:", e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
}
