import { Care, Patient, Prescription, Seance } from "@/types/nurse";
import { groupBy, orderBy, unionBy } from "lodash";

export function getFormattedSeanceByPatient(seances: Seance[], patients: Patient[], prescriptions: Prescription[], cares: Care[]) {
  const grouped = groupBy(seances, "patient");

  const sorted = Object.values(grouped).map((seancesByPatient) => {
    console.log("=>  seancesByPatient?.[0]?.patient:", seancesByPatient);
    const patient = patients.find((patient) => patient._id === seancesByPatient?.[0]?.patient);

    const mappedSeances = seancesByPatient?.map((seance) => {
      return {
        ...seance,
        cares: cares
          .filter((care) => seance.cares.includes(care._id))
          .map((care) => {
            return {
              ...care,
              prescription: prescriptions.find((p) => p._id === care.prescription),
            };
          }),
      };
    });

    return {
      patient,
      seances: mappedSeances,
      nbSeanceToBill: mappedSeances?.filter((a) =>
        a.cares?.every(
          (care) => ((care.prescription?.media?._id && care.prescription?.prescriptionDate) || care.prescription?.empty) && a.status === "done"
        )
      )?.length,
      nbSeanceMissingPrescription: unionBy(
        mappedSeances?.filter((a) =>
          a.cares?.every(
            (care) => (!care.prescription?.media?._id || !care.prescription?.prescriptionDate) && !care.prescription?.empty && a.status === "done"
          )
        ),
        "care.prescription._id"
      )?.length,
      nbSeanceToDo: mappedSeances?.filter((a) => a.status === "to-do")?.length,
      nbSeanceSentToBill: mappedSeances?.filter((a) => a.status === "ready-to-bill")?.length,
    };
  });

  return orderBy(
    sorted,
    [
      function (b) {
        return b?.nbSeanceToBill > 0;
      },
      function (a) {
        return a?.patient?.lastname;
      },
    ],
    ["desc", "asc"]
  );
  // return sorted;
}
