import { Document, DocumentStatus, DocumentTypes } from "@/types/taxi";
import { Box, Button, Modal, Text } from "@/ui";
import { format } from "date-fns";
import { useTheme } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LockClockIcon from "@mui/icons-material/LockClock";
import { useEffect, useState } from "react";
import { Spinner } from "@/ui/Spinner";
import { useGetMultiUri } from "@/utils/useGetMultiUri";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

export function DocumentItem({
  document,
  selectable,
  isSelected,
  setIsSelected,
}: {
  document: Document;
  selectable?: boolean;
  isSelected?: boolean;
  setIsSelected?: any;
}) {
  const theme = useTheme();
  const [showPictures, setShowPictures] = useState(false);
  const isDocumentNotSync = !!document?.media?.find((c) => c.provisionalHightQualityDocument);
  function statusColor() {
    if (isDocumentNotSync) return theme.colors.orange.main;
    if (document.status === DocumentStatus.uploaded) return theme.colors.blue.main;
    if (document.status === DocumentStatus.sentForBilling) return theme.colors.green.main;
  }

  function RenderStatus() {
    if (document.status === DocumentStatus.uploaded) {
      return (
        <Box
          bgcolor={theme.colors.white}
          position="absolute"
          top={0}
          left={0}
          alignItems="center"
          style={{
            borderBottom: `solid 1px ${statusColor()}`,
            borderRight: `solid 1px ${statusColor()}`,
            borderBottomRightRadius: 5,
            borderTopLeftRadius: 5,
          }}
        >
          <Box flexDirection="row" alignItems="center" px={3} bgcolor={`${theme.colors.blue.pale}80`}>
            <Text fontSize={12} color={theme.colors.blue.dark} fontWeight="500">
              Document à envoyer
            </Text>
          </Box>
        </Box>
      );
    }
    if (document.status === DocumentStatus.sentForBilling) {
      return (
        <Box
          bgcolor={theme.colors.white}
          position="absolute"
          top={0}
          left={0}
          alignItems="center"
          style={{
            borderBottom: `solid 1px ${statusColor()}`,
            borderRight: `solid 1px ${statusColor()}`,
            borderBottomRightRadius: 5,
            borderTopLeftRadius: 5,
          }}
        >
          <Box flexDirection="row" alignItems="center" px={3} bgcolor={`${theme.colors.green.pale}80`}>
            <Text fontSize={12} color={theme.colors.green.dark} fontWeight="500">
              Document envoyé
            </Text>
          </Box>
        </Box>
      );
    }
    if (isDocumentNotSync) {
      return (
        <Box
          bgcolor={theme.colors.white}
          position="absolute"
          top={0}
          left={0}
          alignItems="center"
          style={{
            borderBottom: `solid 1px ${statusColor()}`,
            borderRight: `solid 1px ${statusColor()}`,
            borderBottomRightRadius: 5,
            borderTopLeftRadius: 5,
          }}
        >
          <Box flexDirection="row" alignItems="center" px={3} bgcolor={`${theme.colors.orange.pale}80`}>
            <Text fontSize={12} color={theme.colors.orange.dark} fontWeight="500">
              Document en cours de synchronisation
            </Text>
          </Box>
        </Box>
      );
    }
  }

  return (
    <Box
      width="100%"
      borderRadius={1}
      border="solid 1px"
      borderColor={statusColor()}
      bgcolor={`${statusColor()}33`}
      position="relative"
      flexDirection="row"
      p={2}
      pt={4}
    >
      <RenderStatus />
      <Box justifyContent="center" alignItems="center" width="60px" style={{ cursor: "pointer" }} onClick={() => setShowPictures(true)}>
        <RenderIconDoc type={document.type} />
      </Box>
      <Box
        justifyContent="center"
        width="calc(100% - 60px)"
        flexDirection="column"
        style={{ cursor: "pointer" }}
        onClick={() => setShowPictures(true)}
      >
        <Box pb={1}>
          <Text fontWeight="500" color={getDocColor(document.type, theme)}>
            {documentTypeFormat(document?.type)}
          </Text>
        </Box>
        <Box pb={1}>
          <Text fontSize={12}>Créé le {format(document.timestamp, "dd MMMM yyyy à HH:mm")}</Text>
        </Box>
        {document?.sentForBillingOn ? (
          <Box pb={1}>
            <Text fontSize={12}>Envoyé le {format(document.sentForBillingOn, "dd MMMM yyyy à HH:mm")}</Text>
          </Box>
        ) : null}
        {document?.comment ? <Text pb={1}>{document?.comment}</Text> : null}
        {document?.keepToSend ? (
          <Box flexDirection="row" alignItems="center">
            <LockClockIcon style={{ color: theme.colors.text, fontSize: 12 }} />
            <Text fontSize={11} fontWeight="400" pl={1}>
              Document conservé pour série
            </Text>
          </Box>
        ) : null}
      </Box>
      <Box
        justifyContent="center"
        alignItems="center"
        width="60px"
        onClick={selectable && setIsSelected ? setIsSelected : null}
        style={{ cursor: selectable ? "pointer" : "inherit" }}
      >
        {selectable && !isSelected && <CheckBoxOutlineBlankIcon style={{ color: theme.palette.primary.main }} />}
        {selectable && isSelected && <CheckBoxIcon style={{ color: theme.palette.primary.main }} />}
      </Box>
      {showPictures && document?.media?.length && (
        <ModalPictures uris={document?.media?.map((m) => m.storagePath)} setShowPictures={setShowPictures} documentType={document.type} />
      )}
      {showPictures && !document?.media?.length && document?.paths?.length && (
        <ModalPictures uris={document?.paths} setShowPictures={setShowPictures} documentType={document.type} />
      )}
    </Box>
  );
}

function documentTypeFormat(docType: DocumentTypes) {
  switch (docType) {
    case DocumentTypes.annex4:
      return "Annexe 4";
    case DocumentTypes.custom:
      return "Personnalisé";
    case DocumentTypes.situationReport:
      return "Bulletin de situation";
    case DocumentTypes.ticket:
      return "Ticket";
    case DocumentTypes.transportVoucher:
      return "Prescription Médicale de Transport";
  }
}

function getDocColor(type: DocumentTypes, theme: any) {
  switch (type) {
    case DocumentTypes.annex4:
      return theme.colors.blueSky.main;
    case DocumentTypes.situationReport:
      return theme.colors.pink.main;
    case DocumentTypes.transportVoucher:
      return theme.colors.orange.main;
    case DocumentTypes.ticket:
      return theme.colors.purple.main;
    case DocumentTypes.custom:
      return theme.colors.blue.main;
  }
}

function RenderIconDoc({ type }: { type: DocumentTypes }) {
  const theme = useTheme();

  switch (type) {
    case DocumentTypes.annex4:
      return (
        <Box position="relative" justifyContent="center" alignItems="center">
          <Box zIndex={2} position="absolute" bgcolor={theme.colors.white} px={1} borderRadius={1}>
            <Text color={theme.colors.blueSky.main} fontWeight="600" style={{ fontSize: 9 }}>
              annexe
            </Text>
          </Box>
          <Box position="absolute" zIndex={1}>
            <InsertDriveFileIcon style={{ fontSize: "30px", color: theme.colors.blueSky.main }} />
          </Box>
        </Box>
      );
    case DocumentTypes.situationReport:
      return (
        <Box position="relative" justifyContent="center" alignItems="center">
          <Box zIndex={2} position="absolute" bgcolor={theme.colors.white} px={1} borderRadius={1}>
            <Text color={theme.colors.pink.main} fontWeight="600" style={{ fontSize: 9 }}>
              bulletin
            </Text>
          </Box>
          <Box position="absolute" zIndex={1}>
            <InsertDriveFileIcon style={{ fontSize: "30px", color: theme.colors.pink.main }} />
          </Box>
        </Box>
      );
    case DocumentTypes.transportVoucher:
      return (
        <Box position="relative" justifyContent="center" alignItems="center">
          <Box zIndex={2} position="absolute" bgcolor={theme.colors.white} px={1} borderRadius={1}>
            <Text color={theme.colors.orange.main} fontWeight="600" style={{ fontSize: 9 }}>
              pmt
            </Text>
          </Box>
          <Box position="absolute" zIndex={1}>
            <InsertDriveFileIcon style={{ fontSize: "30px", color: theme.colors.orange.main }} />
          </Box>
        </Box>
      );
    case DocumentTypes.ticket:
      return (
        <Box position="relative" justifyContent="center" alignItems="center">
          <Box zIndex={2} position="absolute" bgcolor={theme.colors.white} px={1} borderRadius={1}>
            <Text color={theme.colors.purple.main} fontWeight="600" style={{ fontSize: 9 }}>
              ticket
            </Text>
          </Box>
          <Box position="absolute" zIndex={1}>
            <InsertDriveFileIcon style={{ fontSize: "30px", color: theme.colors.purple.main }} />
          </Box>
        </Box>
      );
    case DocumentTypes.custom:
      return (
        <Box position="relative" justifyContent="center" alignItems="center">
          <Box zIndex={2} position="absolute" bgcolor={theme.colors.white} px={1} borderRadius={1}>
            <Text color={theme.colors.blue.main} fontWeight="600" style={{ fontSize: 9 }}>
              perso
            </Text>
          </Box>
          <Box position="absolute" zIndex={1}>
            <InsertDriveFileIcon style={{ fontSize: "30px", color: theme.colors.blue.main }} />
          </Box>
        </Box>
      );
    default:
      break;
  }
}

function ModalPictures({ uris, setShowPictures, documentType }: { uris: string[]; setShowPictures: any; documentType: DocumentTypes }) {
  const resUris = useGetMultiUri(uris);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Modal open={true} onClose={() => setShowPictures(false)}>
      <Text fontWeight="500" color={getDocColor(documentType, theme)} p={2}>
        {documentTypeFormat(documentType)}
      </Text>
      <Box m={2} bgcolor={theme.palette.grey[100]} height="80vh" width="50vw" maxWidth="1200px" justifyContent="center" alignItems="center">
        {loading && <Spinner />}
        {!loading && (
          <>
            {resUris?.map((res) => {
              const u = res.split("?")[0];
              const ispdf = u.includes("pdf");
              if (ispdf) return <embed src={res} width="100%" height="100%" style={{ borderRadius: 4 }} type="application/pdf" />;
              return <img src={res} width="100%" height="100%" alt={documentType} style={{ borderRadius: 4, objectFit: "cover" }} />;
            })}
          </>
        )}
      </Box>
      <Box flexDirection="row" alignItems="center" justifyContent="center" p={1}>
        <Button onClick={() => setShowPictures(false)}>Fermer</Button>
      </Box>
    </Modal>
  );
}
