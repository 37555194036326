import { Patient } from "@/types/taxi";
import { Box, Text } from "@/ui";
import { InputLabel, useTheme } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Spinner } from "@/ui/Spinner";
import { useGetURI } from "@/utils/useGetUri";

export function RenderInformation({ patient }: { patient: Patient }) {
  return (
    <Box flexDirection="row" style={{ overflowY: "scroll", overflowX: "hidden" }}>
      <Box flexDirection="column" width="50%">
        {patient.lastname && (
          <Box flexDirection="column" p={2}>
            <InputLabel>Nom de famille</InputLabel>
            <Text pl={5}>{patient.lastname}</Text>
          </Box>
        )}
        {patient.name && (
          <Box flexDirection="column" p={2}>
            <InputLabel>Prénom</InputLabel>
            <Text pl={5}>{patient.name}</Text>
          </Box>
        )}
        {patient.phoneNumber && (
          <Box flexDirection="column" p={2}>
            <InputLabel>Numéro de téléphone</InputLabel>
            <Text pl={5}>{patient.phoneNumber}</Text>
          </Box>
        )}
        {patient.birth && (
          <Box flexDirection="column" p={2}>
            <InputLabel>Date de naissance</InputLabel>
            <Text pl={5}>{format(patient.birth, "dd / MM / yyyy")}</Text>
          </Box>
        )}
        {patient.address && (
          <Box flexDirection="column" p={2}>
            <InputLabel>Date de naissance</InputLabel>
            <Text pl={5}>
              {patient.address} {patient.postcode}
            </Text>
          </Box>
        )}
        {patient.socialSecurityName && (
          <Box flexDirection="column" p={2}>
            <InputLabel>Nom de la caisse</InputLabel>
            <Text pl={5}>{patient.socialSecurityName}</Text>
          </Box>
        )}
        {patient.healthInsuranceName && (
          <Box flexDirection="column" p={2}>
            <InputLabel>Nom de la mutuelle</InputLabel>
            <Text pl={5}>{patient.healthInsuranceName}</Text>
          </Box>
        )}
      </Box>
      <Box flexDirection="column" width="50%">
        {!!patient?.mediaSocialSecurity?.length && (
          <>
            <InputLabel>Sécurité sociale</InputLabel>
            <Box flexDirection="row" flexWrap="wrap">
              {patient?.mediaSocialSecurity?.map((media) => {
                return <RenderPicture uri={media?.storagePath} />;
              })}
            </Box>
          </>
        )}
        {patient?.socialSecurityCard && !patient?.mediaSocialSecurity?.length && (
          <>
            <InputLabel>Sécurité sociale</InputLabel>
            <Box flexDirection="row" flexWrap="wrap">
              <RenderPicture uri={patient?.socialSecurityCard} />
            </Box>
          </>
        )}
        {!!patient?.mediaHealthInsurance?.length && (
          <>
            <InputLabel>Mutuelle</InputLabel>
            <Box flexDirection="row" flexWrap="wrap">
              {patient?.mediaHealthInsurance?.map((media) => {
                return <RenderPicture uri={media?.storagePath} />;
              })}
            </Box>
          </>
        )}
        {patient?.healthInsuranceCard && !patient?.mediaHealthInsurance?.length && (
          <>
            <InputLabel>Mutuelle</InputLabel>
            <Box flexDirection="row" flexWrap="wrap">
              <RenderPicture uri={patient?.healthInsuranceCard} />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

function RenderPicture({ uri, size = 200 }: { uri: string; size?: number }) {
  const res = useGetURI(uri);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return (
      <>
        <Box justifyContent="center" alignItems="center" m={2} borderRadius={1} bgcolor={theme.palette.grey[100]} height={size} width={size}>
          <Spinner />
        </Box>
      </>
    );
  }
  return (
    <Box m={2} bgcolor={theme.palette.grey[100]}>
      <img src={res} width={size} height={size} alt={res} style={{ borderRadius: 4 }} />
    </Box>
  );
}
