import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useAppContext } from "@/app/common/provider/AppProvider";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { Refund } from "@/types/nurse";
import { Box, Button, Text, Card, Accordion } from "@/ui";
import { useQueryParams } from "@/utils/useQueryParams";
import DownloadRefundPDF from "@/components/common/RefundPDF";
import { format, parseISO, isValid } from "date-fns";
import qs from "query-string";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Material UI
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { CustomTabPanel } from "../../../../components/common/CustomTabPanel";

function RefundView() {
  const params = useQueryParams();
  const { dbName } = useApplicationContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useAppContext();
  const { client, user } = useMongoContext();
  const currentUser = user?.customData;
  const [refunds, setRefunds] = useState<Refund[]>([]);

  // Get refunds function
  async function getFilteredRefunds() {
    const refundsCollection = client.db(dbName).collection("Refund");

    const refunds = await refundsCollection.find({
      adeli: currentUser?.adeli,
    });

    return refunds.sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  // Get data function
  async function getData() {
    try {
      const dateFilter = new Date(Number(params.period));
      setLoading(true);

      const refunds = await getFilteredRefunds();
      // Filter refunds for the selected month and for the selected year
      const filteredAndSortedRefunds = refunds.filter(
        (refund) =>
          refund.date.getMonth() === dateFilter.getMonth() &&
          refund.date.getFullYear() === dateFilter.getFullYear()
      );
      setRefunds(filteredAndSortedRefunds);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user && client) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.period, user, client]);

  // Back to period function for navigation bar component
  const handleBackToPeriod = ({
    period,
    order = "negative",
  }: {
    period: string;
    order: string;
  }) => {
    let date = new Date(Number(period));
    if (
      (date.getMonth() === 11 && order === "positive") ||
      (date.getMonth() === 0 && order === "negative")
    ) {
      date.setFullYear(date.getFullYear() + (order === "negative" ? -1 : +1));
      date.setMonth(order === "negative" ? 11 : 0);
    } else {
      date.setMonth(date.getMonth() + (order === "negative" ? -1 : +1));
    }
    let to = qs.stringify(
      { ...params, period: date.getTime() },
      { arrayFormat: "index" }
    );
    navigate(`${location.pathname}?${to}`, { replace: true });
  };

  // Navigation Bar Component
  const NavigationBar = ({ period }) => {
    return (
      <Card
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: "16px", width: "96%" }}
      >
        <Button
          onClick={() => handleBackToPeriod({ period, order: "negative" })}
        >
          <ArrowBackIcon />
        </Button>
        <Typography variant="h6">
          {firstLetterUppercase(format(new Date(Number(period)), "MMMM yyyy"))}
        </Typography>
        <Button
          disabled={
            new Date(Number(period) + 1000 * 60 * 60 * 24 * 31) > new Date()
          }
          onClick={() => handleBackToPeriod({ period, order: "positive" })}
        >
          <ArrowForwardIcon />
        </Button>
      </Card>
    );
  };

  // Format date function
  const formatDate = (dateStr: number | Date): string => {
    let date = dateStr;
    if (typeof dateStr === "string") {
      date = parseISO(dateStr);
    }
    return isValid(date) ? format(date, "dd/MM/yyyy") : "Date invalide";
  };

  // First letter in uppercase function
  const firstLetterUppercase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Pluralize function
  const pluralize = (count: number, singular: string, plural: string) => {
    return count === 1 ? singular : plural;
  };

  return (
    <Box
      width="100%"
      justifyContent="space-around"
      alignItems="center"
      flexDirection="column"
    >
      <Box m={2} />
      {/* Barre de navigation */}
      {/* <NavigationBar period={params.period} /> */}

      {/* Contenu de "Mes Remboursements" */}
      {refunds.length > 0 ? (
        <>
          <Card
            flexDirection="column"
            p={2}
            display="flex"
            style={{ marginBottom: "16px", width: "96%" }}
          >
            {/* Présentation montant et informations */}
            <Box
              flexDirection="row"
              justifyContent="space-evenly"
              alignItems="center"
              p={3}
              m={2}
            >
              <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
                flexShrink={0}
                p={2}
                m={2}
              >
                <Text fontSize={26} fontWeight={800}>
                  {refunds.reduce((acc, refund) => {
                    const total = refund?.total ? parseFloat(refund.total) : 0;
                    return acc + total;
                  }, 0)}{" "}
                  €
                </Text>
                <Text fontSize={16}>
                  {refunds.length}{" "}
                  {pluralize(refunds.length, "virement", "virements")} -{" "}
                  {
                    refunds.filter(
                      (refund, index, self) =>
                        self.findIndex(
                          (r) => r.nomOrganisme === refund.nomOrganisme
                        ) === index
                    ).length
                  }{" "}
                  {pluralize(
                    refunds.filter(
                      (refund, index, self) =>
                        self.findIndex(
                          (r) => r.nomOrganisme === refund.nomOrganisme
                        ) === index
                    ).length,
                    "organisme",
                    "organismes"
                  )}
                </Text>
              </Box>

              {/* Bouton pour télécharger */}
              <DownloadRefundPDF
                data={refunds}
                period={params.period}
                currentUser={currentUser}
              />
            </Box>
          </Card>

          {/* Accordéon pour les détails des remboursements */}
          <Card
            flexDirection="column"
            p={2}
            display="flex"
            style={{ width: "96%" }}
          >
            {refunds.map((refund, index) => (
              <Accordion
                key={refund._id || index}
                title={`${formatDate(refund.date)} - ${
                  refund.nomOrganisme
                } - ${parseFloat(refund.total).toFixed(2)} €`}
              >
                <Table size="small" style={{ border: "1px solid #e0e0e0" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Facture</TableCell>
                      <TableCell>Patient</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Payé</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {refund.details.map((detail, detailIndex) => (
                      <TableRow
                        key={detailIndex}
                        style={{
                          backgroundColor:
                            detailIndex % 2 === 0 ? "#fff" : "#f3e7e765",
                        }}
                      >
                        <TableCell>{detail.numFacture}</TableCell>
                        <TableCell>{detail.nomPatient}</TableCell>
                        <TableCell>
                          {formatDate(detail.debut)} - {formatDate(detail.fin)}
                        </TableCell>
                        <TableCell>
                          {parseFloat(detail.paye).toFixed(2)} €
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Accordion>
            ))}
          </Card>
        </>
      ) : (
        <Card flexDirection="column" display="flex" style={{ width: "100%" }}>
          <Box
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            p={2}
            m={2}
          >
            <Text fontSize={18} fontWeight={500}>
              Aucun compte rendu pour cette période
            </Text>
          </Box>
        </Card>
      )}
    </Box>
  );
}

function ReportView() {
  const params = useQueryParams();
  const { dbName } = useApplicationContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useAppContext();
  const { client, user } = useMongoContext();
  const currentUser = user?.customData;
  const [refunds, setRefunds] = useState<Refund[]>([]);

  // Get refunds function
  async function getFilteredRefunds() {
    const refundsCollection = client.db(dbName).collection("Refund");

    const refunds = await refundsCollection.find({
      adeli: currentUser?.adeli,
    });

    return refunds.sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  // Get data function
  async function getData() {
    try {
      const dateFilter = new Date(Number(params.period));
      setLoading(true);

      const refunds = await getFilteredRefunds();
      // Filter refunds for the selected month and for the selected year
      const filteredAndSortedRefunds = refunds.filter(
        (refund) =>
          refund.date.getMonth() === dateFilter.getMonth() &&
          refund.date.getFullYear() === dateFilter.getFullYear()
      );
      setRefunds(filteredAndSortedRefunds);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user && client) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.period, user, client]);

  // Back to period function for navigation bar component
  const handleBackToPeriod = ({
    period,
    order = "negative",
  }: {
    period: string;
    order: string;
  }) => {
    let date = new Date(Number(period));
    if (
      (date.getMonth() === 11 && order === "positive") ||
      (date.getMonth() === 0 && order === "negative")
    ) {
      date.setFullYear(date.getFullYear() + (order === "negative" ? -1 : +1));
      date.setMonth(order === "negative" ? 11 : 0);
    } else {
      date.setMonth(date.getMonth() + (order === "negative" ? -1 : +1));
    }
    let to = qs.stringify(
      { ...params, period: date.getTime() },
      { arrayFormat: "index" }
    );
    navigate(`${location.pathname}?${to}`, { replace: true });
  };

  // Navigation Bar Component
  const NavigationBar = ({ period }) => {
    return (
      <Card
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: "16px", width: "96%" }}
      >
        <Button
          onClick={() => handleBackToPeriod({ period, order: "negative" })}
        >
          <ArrowBackIcon />
        </Button>
        <Typography variant="h6">
          {firstLetterUppercase(format(new Date(Number(period)), "MMMM yyyy"))}
        </Typography>
        <Button
          disabled={
            new Date(Number(period) + 1000 * 60 * 60 * 24 * 31) > new Date()
          }
          onClick={() => handleBackToPeriod({ period, order: "positive" })}
        >
          <ArrowForwardIcon />
        </Button>
      </Card>
    );
  };

  // Format date function
  const formatDate = (dateStr: number | Date): string => {
    let date = dateStr;
    if (typeof dateStr === "string") {
      date = parseISO(dateStr);
    }
    return isValid(date) ? format(date, "dd/MM/yyyy") : "Date invalide";
  };

  // First letter in uppercase function
  const firstLetterUppercase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Pluralize function
  const pluralize = (count: number, singular: string, plural: string) => {
    return count === 1 ? singular : plural;
  };

  return (
    <Box
      width="100%"
      justifyContent="space-around"
      alignItems="center"
      flexDirection="column"
    >
      <Box m={2} />
      {/* Barre de navigation */}
      {/* <NavigationBar period={params.period} /> */}

      {/* Contenu de "Mes Remboursements" */}
      {refunds.length > 0 ? (
        <>
          <Card
            flexDirection="column"
            p={2}
            display="flex"
            style={{ marginBottom: "16px", width: "96%" }}
          >
            {/* Présentation montant et informations */}
            <Box
              flexDirection="row"
              justifyContent="space-evenly"
              alignItems="center"
              p={3}
              m={2}
            >
              <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
                flexShrink={0}
                p={2}
                m={2}
              >
                <Text fontSize={26} fontWeight={800}>
                  {refunds.reduce((acc, refund) => {
                    const total = refund?.total ? parseFloat(refund.total) : 0;
                    return acc + total;
                  }, 0)}{" "}
                  €
                </Text>
                <Text fontSize={16}>
                  {refunds.length}{" "}
                  {pluralize(refunds.length, "virement", "virements")} -{" "}
                  {
                    refunds.filter(
                      (refund, index, self) =>
                        self.findIndex(
                          (r) => r.nomOrganisme === refund.nomOrganisme
                        ) === index
                    ).length
                  }{" "}
                  {pluralize(
                    refunds.filter(
                      (refund, index, self) =>
                        self.findIndex(
                          (r) => r.nomOrganisme === refund.nomOrganisme
                        ) === index
                    ).length,
                    "organisme",
                    "organismes"
                  )}
                </Text>
              </Box>

              {/* Bouton pour télécharger */}
              <DownloadRefundPDF
                data={refunds}
                period={params.period}
                currentUser={currentUser}
              />
            </Box>
          </Card>

          {/* Accordéon pour les détails des remboursements */}
          <Card
            flexDirection="column"
            p={2}
            display="flex"
            style={{ width: "96%" }}
          >
            {refunds.map((refund, index) => (
              <Accordion
                key={refund._id || index}
                title={`${formatDate(refund.date)} - ${
                  refund.nomOrganisme
                } - ${parseFloat(refund.total).toFixed(2)} €`}
              >
                <Table size="small" style={{ border: "1px solid #e0e0e0" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Facture</TableCell>
                      <TableCell>Patient</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Payé</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {refund.details.map((detail, detailIndex) => (
                      <TableRow
                        key={detailIndex}
                        style={{
                          backgroundColor:
                            detailIndex % 2 === 0 ? "#fff" : "#f3e7e765",
                        }}
                      >
                        <TableCell>{detail.numFacture}</TableCell>
                        <TableCell>{detail.nomPatient}</TableCell>
                        <TableCell>
                          {formatDate(detail.debut)} - {formatDate(detail.fin)}
                        </TableCell>
                        <TableCell>
                          {parseFloat(detail.paye).toFixed(2)} €
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Accordion>
            ))}
          </Card>
        </>
      ) : (
        <Card flexDirection="column" display="flex" style={{ width: "100%" }}>
          <Box
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            p={2}
            m={2}
          >
            <Text fontSize={18} fontWeight={500}>
              Aucun remboursement pour cette période
            </Text>
          </Box>
        </Card>
      )}
    </Box>
  );
}

const FilterPeriodBox = () => {
  const params = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [periodVisible, setPeriodVisible] = useState(false);
  const theme = useTheme();
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="60px"
      position="relative"
    >
      <Box
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
        height="100%"
        position="relative"
      >
        {/* <Box justifyContent="center" alignItems="center">
          <Text fontWeight={300} fontSize={26} pl={3}>
            Remboursements
          </Text>
        </Box> */}
        <Box justifyContent="center" alignItems="flex-end" pb={1} pr={3}>
          {/* FILTER PERIODE */}
          <Box style={{ position: "relative" }} flexDirection="column">
            <Text fontSize={12}>Période du :</Text>
            <Box
              p="2px"
              pl={1}
              pr={1}
              style={{
                borderRadius: 5,
                backgroundColor: "#fff",
                border: "solid 1px",
                borderColor: "#c7c7c7",
                cursor: "pointer",
                fontSize: 13,
                fontWeight: 600,
                maxHeight: "28px",
                minHeight: "28px",
                color: "#454545",
                width: 100,
              }}
              alignItems="center"
              onClick={() => {
                setPeriodVisible(!periodVisible);
              }}
            >
              {format(new Date(Number(params.period)), "MM/yyyy")}
            </Box>

            {periodVisible && (
              <Box
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  boxShadow:
                    "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
                  borderRadius: "5px",
                  overflow: "hidden",
                  zIndex: 99999,
                }}
              >
                <Calendar
                  maxDetail="year"
                  onChange={(c) => {
                    const period = new Date(c as any as string);
                    let to = qs.stringify(
                      { ...params, period: period.getTime() },
                      { arrayFormat: "index" }
                    );
                    navigate(`${location.pathname}?${to}`, {
                      replace: true,
                    });
                    setPeriodVisible(false);
                  }}
                  maxDate={new Date()}
                  value={new Date(Number(params.period))}
                />
                <Box p={1}>
                  <Button onClick={() => setPeriodVisible(false)}>
                    Fermer
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default function BillingView() {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      flexDirection="column"
      width="calc(100% )"
      height="100%"
      justifyContent="center"
      alignItems="center"
      className="overBg"
    >
      <Box
        flexDirection="column"
        width="98%"
        height="98%"
        bgcolor="#fffffff6"
        // borderRadius={1}
      >
        <Box sx={{ width: "100%" }} p={5} flexDirection="column">
          <Box borderBottom={`solid 1px ${theme.colors.light}`}>
            <Box flex="0.8">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                style={{ height: "50px" }}
              >
                <Tab label="Compte rendu gestionnaire" />
                <Tab label="Remboursement" />
                <Tab label="Facture cofiodc" />
              </Tabs>
            </Box>
            <Box flex="0.2">
              <FilterPeriodBox />
            </Box>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <RefundView />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ReportView />
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
}
