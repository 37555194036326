import { Box, Button, Card, Text } from "@/ui";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  Care,
  FolderSentToBill,
  Office,
  Patient,
  Prescription,
  Seance,
  UserWithRoleInfo,
} from "@/types/nurse";
import { useNavigate, useLocation } from "react-router-dom";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { useOfficeContext } from "@/app/nurse/provider/OfficeProvider";
import { format } from "@/utils/format";
import { useQueryParams } from "@/utils/useQueryParams";
import qs from "query-string";
import { uniqBy } from "lodash";
import { useAppContext } from "@/app/common/provider/AppProvider";
import Select from "react-select";
import {
  add,
  addMonths,
  eachDayOfInterval,
  isSameMonth,
  subMonths,
} from "date-fns";
import PersonIcon from "@mui/icons-material/Person";
import { getRequestFilter } from "../../../../../../utils/getRequestFilter";
import { useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import { getFormattedSeanceByPatient } from "@/utils/getFormattedSeanceByPatient";
import { gradient } from "@/theme/ThemeProvider";
import { ModalConfirmSend } from "@/components/nurse/ModalUpdateSeance";
import { Checkbox } from "@mui/material";

const statusOptionsClient = [
  { label: "Tous les statuts", value: null },
  { label: "Séances à faire", value: "to-do" },
  { label: "Séances à envoyer", value: "done" },
  { label: "Séances envoyées", value: "ready-to-bill" },
];

const colors = [
  "#3288bd",
  "#d53e4f",
  "#98a361",
  "#abdda4",
  "#fdae61",
  "#f46d43",
  "#a598f5",
  "#66c2a5",
  "#d53ea0",
  "#3ed5b0",
  "#6ed53e",
  "#fb7f7f",
];

export default function ActivityView() {
  const { client, user } = useMongoContext();
  const { currentOfficeInfo } = useOfficeContext();
  const { dbName } = useApplicationContext();
  const { setLoading, loading } = useAppContext();
  let statusFiltered = statusOptionsClient;
  const params = useQueryParams();
  const theme = useTheme();
  const [filterFoldersSentToBill, setFilterFoldersSentToBill] = useState<Date>(
    new Date()
  );
  const [foldersSentToBill, setFoldersSentToBill] = useState<
    FolderSentToBill[]
  >([]);
  const [selectedPatientIds, setSelectedPatientIds] = useState<string[]>([]);
  const [formatedSeances, setFormatedSeances] = useState<any>([]);
  const [stat, setStat] = useState({
    statNbSeanceDone: 0,
    statNbSeanceTodo: 0,
    statNbSeanceMissingPrescription: 0,
    statNbSeanceBilled: 0,
  });
  const [praticiens, setPraticiens] = useState<
    (UserWithRoleInfo & { color: string })[]
  >([]);
  const [startVisible, setStartVisible] = useState(false);
  const [endVisible, setEndVisible] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  async function getData() {
    try {
      if (!currentOfficeInfo) {
        let to = qs.stringify({ ...params }, { arrayFormat: "index" });
        navigate(`/nurse/app/offices?${to}`);
      }
      if (params?.start && params?.end && currentOfficeInfo) {
        setLoading(true);
        const requestFilter = getRequestFilter(params);
        // DB
        const seancesCollection = client.db(dbName).collection("Seance");
        const officeCollection = client.db(dbName).collection("Office");
        const patientCollection = client.db(dbName).collection("Patient");
        const folderSentToBillCollection = client
          .db(dbName)
          .collection("FolderSendToBilling");
        const prescriptionCollection = client
          .db(dbName)
          .collection("Prescription");
        const careCollection = client.db(dbName).collection("Care");
        const seancesQuery: Seance[] = await seancesCollection.find(
          requestFilter
        );
        const prescriptionQuery: Prescription[] =
          await prescriptionCollection.find({ deleted: false });
        const patientsQuery: Patient[] = await patientCollection.find({
          deleted: false,
        });
        const careQuery: Care[] = await careCollection.find({ deleted: false });
        const office: Office = await officeCollection.findOne({
          _id: currentOfficeInfo.officeId,
        });
        //FOLDER SEND TO BILL
        const folderSentToBillQuery = await folderSentToBillCollection.find({
          officeId: office?._id,
        });
        setFoldersSentToBill(folderSentToBillQuery);
        //SEANCES
        const grouped = getFormattedSeanceByPatient(
          seancesQuery,
          patientsQuery,
          prescriptionQuery,
          careQuery
        );
        setFormatedSeances(grouped);
        // RESUME

        let nbSeanceDone = 0;
        let nbSeanceTodo = 0;
        let nbSeanceMissingPrescription = 0;
        let nbSeanceSend = 0;

        grouped.forEach((format) => {
          nbSeanceDone += format.nbSeanceToBill;
          nbSeanceTodo += format.nbSeanceToDo;
          nbSeanceMissingPrescription += format.nbSeanceMissingPrescription;
          nbSeanceSend += format.nbSeanceSentToBill;
        });
        setStat({
          statNbSeanceDone: nbSeanceDone,
          statNbSeanceTodo: nbSeanceTodo,
          statNbSeanceMissingPrescription: nbSeanceMissingPrescription,
          statNbSeanceBilled: nbSeanceSend,
        });

        // COLABORATORS

        const userCollaborator = office?.members?.find(
          (p) => p?.userId === user?.customData?._id
        );
        const collaboratorSet = uniqBy(office?.members, "userId")
          ?.filter((p) => p && p?.userId !== user?.customData?._id)
          ?.map((c, i) => ({ ...c, color: colors[i + 1] }))
          ?.sort((a, b) =>
            String(a?.userLastName)
              .toLowerCase()
              .trim()
              .localeCompare(String(b?.userLastName).toLowerCase().trim())
          );
        collaboratorSet.unshift({ ...userCollaborator, color: colors[0] });
        setPraticiens(collaboratorSet);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }

  // USE EFFECT TO GET SEANCE COUNT
  useEffect(() => {
    if (params.end && params.start) {
      if (user && client && currentOfficeInfo) {
        getData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.start,
    params.end,
    params.status,
    params.collaborator,
    user,
    client,
    currentOfficeInfo,
  ]);

  // USE EFFECT TO GET SEANCE COUNT ON NEED REFRESH DATA
  useEffect(() => {
    if (user && client && needRefresh) {
      getData();
      setNeedRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client, needRefresh]);

  const collaboratorSet = uniqBy(
    praticiens.map((c) => ({
      label: c.userFirstName + " " + c.userLastName,
      value: { id: c.userId, color: c.color },
    })),
    "value"
  );

  const collaboratorValue = [
    { label: "Tout le cabinet", value: null },
    ...collaboratorSet,
  ];

  const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {value?.color && (
        <PersonIcon
          style={{
            color: value?.color,
            fontSize: 18,
            margin: 2,
            marginRight: 5,
          }}
        />
      )}
      <Text
        fontSize={13}
        textOverflow="ellipsis"
        width="100%"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {label}
      </Text>
    </div>
  );

  // Navigation Bar Component
  const NavigationBar = () => {
    return (
      <Card
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="10%"
        width="100%"
        mb={2}
      >
        <Button
          onClick={() =>
            setFilterFoldersSentToBill(subMonths(filterFoldersSentToBill, 1))
          }
        >
          <ArrowBackIcon />
        </Button>
        <Text>
          {format(new Date(Number(filterFoldersSentToBill)), "MMMM yyyy")}
        </Text>
        <Button
          onClick={() =>
            setFilterFoldersSentToBill(addMonths(filterFoldersSentToBill, 1))
          }
        >
          <ArrowForwardIcon />
        </Button>
      </Card>
    );
  };

  function RenderStats() {
    return (
      <Box
        flexDirection="row"
        alignItems="center"
        marginLeft={2}
        p={1}
        bgcolor={theme.colors.bgPale}
        borderRadius={1}
        width="90%"
      >
        <Box justifyContent="center" alignItems="center">
          <AnalyticsIcon style={{ color: theme.colors.light as any }} />
        </Box>
        <Box flexDirection="row" flexWrap="wrap">
          {stat.statNbSeanceBilled > 0 && (
            <Box flexDirection="row" alignItems="center" paddingLeft={2}>
              <Box
                borderRadius={50}
                bgcolor={theme.colors.seanceReadyToBill}
                width={10}
                height={10}
              />
              <Text fontSize={13} fontWeight="300" mx={"2px"} ml={1}>
                {stat.statNbSeanceBilled}
              </Text>
              <Text fontSize={13} fontWeight="300">
                séance{stat.statNbSeanceBilled > 1 ? "s" : ""} envoyée
                {stat.statNbSeanceBilled > 1 ? "s" : ""}
              </Text>
            </Box>
          )}
          {stat.statNbSeanceDone > 0 && (
            <Box flexDirection="row" alignItems="center" paddingLeft={2}>
              <Box
                borderRadius={50}
                bgcolor={theme.colors.seanceDone}
                width={10}
                height={10}
              />
              <Text fontSize={13} fontWeight="300" mx={"2px"} ml={1}>
                {stat.statNbSeanceDone}
              </Text>
              <Text fontSize={13} fontWeight="300">
                séance{stat.statNbSeanceDone > 1 ? "s" : ""} à envoyer
              </Text>
            </Box>
          )}
          {stat.statNbSeanceTodo > 0 && (
            <Box flexDirection="row" alignItems="center" paddingLeft={2}>
              <Box
                borderRadius={50}
                bgcolor={"#c5c5c5"}
                width={10}
                height={10}
              />
              <Text fontSize={13} fontWeight="300" mx={"2px"} ml={1}>
                {stat.statNbSeanceTodo}
              </Text>
              <Text fontSize={13} fontWeight="300">
                séance{stat.statNbSeanceTodo > 1 ? "s" : ""} à valider
              </Text>
            </Box>
          )}
          {stat?.statNbSeanceMissingPrescription > 0 && (
            <Box flexDirection="row" alignItems="center" paddingLeft={2}>
              <Box
                borderRadius={50}
                bgcolor={theme.colors.red}
                width={10}
                height={10}
              />
              <Text fontSize={13} fontWeight="300" mx={"2px"} ml={1}>
                {stat?.statNbSeanceMissingPrescription}
              </Text>
              <Text fontSize={13} fontWeight="300">
                ordonnance{stat?.statNbSeanceMissingPrescription > 1 ? "s" : ""}{" "}
                incomplète{stat?.statNbSeanceMissingPrescription > 1 ? "s" : ""}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  function handleSelectAll() {
    if (selectedPatientIds?.length) {
      setSelectedPatientIds([]);
    } else {
      const arrayIds: string[] = [];
      formatedSeances.forEach((item) => {
        if (item?.nbSeanceToBill) {
          arrayIds.push(item.patient._id);
        }
      });
      setSelectedPatientIds(arrayIds);
    }
  }

  return (
    <Box
      flexDirection="column"
      width="98%"
      height="98%"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: "#fffffff6", borderRadius: 5 }}
    >
      <Box
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="60px"
        position="relative"
      >
        <Box
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          position="relative"
          borderBottom={`solid 1px ${theme.colors.light}`}
        >
          <Box justifyContent="center" alignItems="center">
            <Text fontWeight={300} fontSize={26} pl={3}>
              Activité
            </Text>
          </Box>

          <Box justifyContent="center" alignItems="flex-end" pb={1}>
            {/* ACTION BUTTON */}
            {/* COFIDOC CLIENT ONLY ACTIONS */}

            <Box mr={3}>
              <ModalConfirmSend
                selectedPatientIds={selectedPatientIds}
                setSelectedPatientIds={setSelectedPatientIds}
                formatedSeancesByPatient={formatedSeances}
                praticiens={praticiens}
              />
            </Box>

            {/* FILTER COLLABORATOR */}
            <Box
              style={{ position: "relative", width: "220px" }}
              flexDirection="column"
              pr={2}
            >
              <Text fontSize={12} pr={1}>
                Collaborateur :
              </Text>
              <Select
                formatOptionLabel={formatOptionLabel}
                options={collaboratorValue}
                onMenuOpen={() => {
                  setEndVisible(false);
                  setStartVisible(false);
                }}
                styles={{
                  container: (style) => ({ ...style, width: "100%" }),
                  control: (style) => ({
                    ...style,
                    borderColor: "#c7c7c7",
                    height: "28px",
                    maxHeight: "28px",
                    minHeight: "28px",
                    borderRadius: "5px",
                    fontSize: 13,
                    fontWeight: 600,
                  }),
                  indicatorsContainer: (style) => ({ ...style, padding: 0 }),
                  valueContainer: (style) => ({
                    ...style,
                    padding: 0,
                    flexWrap: "nowrap",
                  }),
                  option: (style, { isSelected }) => ({
                    ...style,
                    background: isSelected ? "#f72585" : "transparent",
                    padding: "3px 4px",
                    color: isSelected ? "#fff" : "",
                  }),
                  menuList: (style) => ({ ...style, maxHeight: 314 }),
                  singleValue: () => ({ paddingLeft: 5, color: "#454545" }),
                }}
                placeholder="Sélectionner un collaborateur"
                // to change with
                defaultValue={
                  collaboratorValue.find(
                    (c) => c.value?.id === params.collaborator
                  ) || collaboratorValue[0]
                }
                value={
                  collaboratorValue.find(
                    (c) => c.value?.id === params.collaborator
                  ) || collaboratorValue[0]
                }
                onChange={(item) => {
                  let to = qs.stringify(
                    { ...params, collaborator: item.value?.id },
                    { arrayFormat: "index" }
                  );
                  navigate(`${location.pathname}?${to}`, { replace: true });
                }}
              />
            </Box>
            {/* FILTER PERIODE START */}
            <Box style={{ position: "relative" }} flexDirection="column" pr={2}>
              <Text fontSize={12}>Période du :</Text>

              <Box
                border="solid 1px"
                borderColor="#c7c7c7"
                borderRadius={1}
                px={3}
                boxSizing="border-box"
                justifyContent="center"
                alignItems="center"
                height="28px"
                color="#454545"
                fontWeight={600}
                fontSize={13}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEndVisible(false);
                  setStartVisible(!startVisible);
                }}
              >
                {format(new Date(Number(params?.start)), "dd/MM/yyyy")}
              </Box>

              {startVisible && (
                <Box
                  style={{
                    position: "absolute",
                    top: "100%",
                    right: 0,
                    marginTop: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "#fff",
                    boxShadow:
                      "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
                    borderRadius: "5px",
                    overflow: "hidden",
                    zIndex: 99999,
                  }}
                >
                  <Calendar
                    onChange={(c: Date) => {
                      const start = new Date(c);
                      const end = new Date(Number(params.end));
                      start.setHours(0, 0);
                      if (
                        start.getTime() > end.getTime() ||
                        eachDayOfInterval({ start, end: end }).length > 31
                      ) {
                        const endToSet = add(start, { days: 31 });
                        let to = qs.stringify(
                          {
                            ...params,
                            start: start.getTime(),
                            end: endToSet.getTime(),
                          },
                          { arrayFormat: "index" }
                        );
                        navigate(`${location.pathname}?${to}`, {
                          replace: true,
                        });
                      } else {
                        let to = qs.stringify(
                          { ...params, start: start.getTime() },
                          { arrayFormat: "index" }
                        );
                        navigate(`${location.pathname}?${to}`, {
                          replace: true,
                        });
                      }
                    }}
                    value={new Date(Number(params.start))}
                  />
                  <Box p={1}>
                    <Button onClick={() => setStartVisible(false)}>
                      Fermer
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            {/* FILTER PERIODE END*/}
            <Box
              style={{ position: "relative" }}
              flexDirection="column"
              pr={"40px"}
            >
              <Text fontSize={12}>Au* :</Text>
              <Box
                border="solid 1px"
                borderColor="#c7c7c7"
                borderRadius={1}
                px={3}
                boxSizing="border-box"
                justifyContent="center"
                alignItems="center"
                height="28px"
                color="#454545"
                fontWeight={600}
                fontSize={13}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setStartVisible(false);
                  setEndVisible(!endVisible);
                }}
              >
                {format(new Date(Number(params.end)), "dd/MM/yyyy")}
              </Box>

              {endVisible && (
                <Box
                  style={{
                    position: "absolute",
                    top: "100%",
                    right: 0,
                    marginTop: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "#fff",
                    boxShadow:
                      "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
                    borderRadius: "5px",
                    overflow: "hidden",
                    zIndex: 99999,
                  }}
                >
                  <Calendar
                    minDate={new Date(Number(params.start))}
                    maxDate={add(new Date(Number(params.start)), { days: 31 })}
                    onChange={(c: Date) => {
                      const date = new Date(c);
                      date.setHours(23, 59);
                      let to = qs.stringify(
                        { ...params, end: date.getTime() },
                        { arrayFormat: "index" }
                      );
                      navigate(`${location.pathname}?${to}`);
                    }}
                    value={new Date(Number(params.end))}
                  />
                  <Box p={1}>
                    <Button onClick={() => setEndVisible(false)}>Fermer</Button>
                  </Box>
                </Box>
              )}
            </Box>

            <Box style={{ position: "absolute", bottom: 0, right: 110 }}>
              <Text color="#454545" fontSize={9} fontWeight="500">
                * 31 jours d'interval maximum
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        width="100%"
        height="calc(100% - 60px)"
        position="relative"
        justifyContent="space-around"
        alignItems="center"
      >
        <Box
          height="100%"
          width="55%"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          p={2}
        >
          <Box
            height="10%"
            width="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              fontWeight={300}
              fontSize={23}
              textAlign="left"
              alignItems="center"
              width="40%"
            >
              Patients sur la période
            </Text>
            <Box
              flexDirection="row"
              alignItems="center"
              width="60%"
              justifyContent="flex-end"
            >
              {!loading && <RenderStats />}
              <Checkbox
                style={{
                  color: theme.colors.primary as string,
                }}
                onClick={handleSelectAll}
                checked={selectedPatientIds?.length > 0}
              />
            </Box>
          </Box>

          <Card
            height="90%"
            width="100%"
            flexWrap="wrap"
            display={"flex"}
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              paddingBottom: 12,
              alignContent: "flex-start",
            }}
          >
            {!Object?.keys(formatedSeances).length && !loading && (
              <Box
                pb={1}
                fontSize={20}
                fontWeight="300"
                flexDirection="row"
                flexWrap="wrap"
                textAlign="center"
                justifyContent="center"
                alignItems="center"
              >
                Aucun patient
                {params?.collaborator && (
                  <span>
                    &nbsp;de&nbsp;
                    <span
                      style={{
                        fontWeight: 600,
                        color: theme.colors.primary as string,
                      }}
                    >
                      {
                        collaboratorValue.find(
                          (c) => c?.value?.id === params?.collaborator
                        )?.label
                      }
                    </span>
                  </span>
                )}
                {params?.status && (
                  <span>
                    &nbsp;pour les&nbsp;
                    <span
                      style={{
                        fontWeight: 600,
                        color: theme.colors.primary as string,
                      }}
                    >
                      {
                        statusFiltered.find((c) => c?.value === params?.status)
                          ?.label
                      }
                    </span>
                  </span>
                )}
                {params?.start && (
                  <>
                    &nbsp;sur la période du&nbsp;
                    <span
                      style={{
                        fontWeight: 600,
                        color: theme.colors.primary as string,
                      }}
                    >
                      {format(
                        new Date(Number(params.start)),
                        "EEEE dd MMMM yyyy"
                      )}
                    </span>
                  </>
                )}
                {params?.end && (
                  <>
                    &nbsp;au&nbsp;
                    <span
                      style={{
                        fontWeight: 600,
                        color: theme.colors.primary as string,
                      }}
                    >
                      {format(
                        new Date(Number(params.end)),
                        "EEEE dd MMMM yyyy"
                      )}
                    </span>
                  </>
                )}
              </Box>
            )}

            {Object?.keys(formatedSeances).length > 0 && (
              <>
                {Object?.values(formatedSeances)?.map(
                  (
                    {
                      patient,
                      nbSeanceToBill,
                      nbSeanceMissingPrescription,
                      nbSeanceToDo,
                      nbSeanceDone,
                      nbSeanceSentToBill,
                    }: any,
                    index
                  ) => {
                    return (
                      <Box
                        key={patient?._id}
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="row"
                        width="33%"
                        p={1}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (nbSeanceToBill > 0) {
                            const p = selectedPatientIds?.includes(patient?._id)
                              ? selectedPatientIds?.filter(
                                  (pId) => pId !== patient?._id
                                )
                              : [...selectedPatientIds, patient?._id];
                            setSelectedPatientIds(p);
                          }
                        }}
                      >
                        <Box
                          flexDirection="row"
                          width="100%"
                          height="100%"
                          alignItems="center"
                          p={1}
                          borderRadius={1}
                          bgcolor={
                            nbSeanceToBill ? theme.colors.bgPale : "#f5f5f5"
                          }
                        >
                          <Box>
                            <Checkbox
                              style={{
                                color: theme.colors.primary as string,
                              }}
                              checked={selectedPatientIds?.includes(
                                patient?._id
                              )}
                              disabled={nbSeanceToBill < 1}
                            />
                          </Box>
                          <Box flexDirection="column" width="80%">
                            <Box alignItems="center" pb={1}>
                              <Box
                                bgcolor="#fff"
                                width={25}
                                height={25}
                                borderRadius={50}
                                mr={1}
                              >
                                <Box
                                  width={25}
                                  height={25}
                                  borderRadius={50}
                                  bgcolor={`${
                                    theme.colors.gradient[
                                      String(index % 8) as keyof gradient
                                    ]
                                  }30`}
                                  border={`1px solid ${
                                    theme.colors.gradient[
                                      String(index % 8) as keyof gradient
                                    ]
                                  }`}
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <PersonIcon
                                    style={{
                                      color: theme.colors.gradient[
                                        String(index % 8) as keyof gradient
                                      ] as any,
                                      fontSize: 18,
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Text
                                display="block"
                                fontWeight={400}
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                                width="80%"
                              >
                                {patient?.firstname} {patient?.lastname}
                              </Text>
                            </Box>

                            <Box flexDirection="column" width="100%">
                              {nbSeanceToDo > 0 && (
                                <Box alignItems="center" width="100%">
                                  <Box
                                    height={11}
                                    width={11}
                                    bgcolor={theme.colors.seanceToDo}
                                    borderRadius={20}
                                    mr={1}
                                  />
                                  <Text
                                    fontSize={11}
                                    textOverflow="ellipsis"
                                    width="100%"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                  >
                                    {nbSeanceToDo} séance
                                    {nbSeanceToDo > 0 ? "s" : ""} à valider
                                  </Text>
                                </Box>
                              )}
                              {nbSeanceToBill > 0 && (
                                <Box alignItems="center" width="100%">
                                  <Box
                                    height={11}
                                    width={11}
                                    bgcolor={theme.colors.seanceDone}
                                    borderRadius={20}
                                    mr={1}
                                  />
                                  <Text
                                    fontSize={11}
                                    textOverflow="ellipsis"
                                    width="100%"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                  >
                                    {nbSeanceToBill} séance
                                    {nbSeanceToBill > 0 ? "s" : ""} à envoyer
                                  </Text>
                                </Box>
                              )}
                              {nbSeanceSentToBill > 0 && (
                                <Box alignItems="center" width="100%">
                                  <Box
                                    height={11}
                                    width={11}
                                    bgcolor={theme.colors.seanceReadyToBill}
                                    borderRadius={20}
                                    mr={1}
                                  />
                                  <Text
                                    fontSize={11}
                                    textOverflow="ellipsis"
                                    width="100%"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                  >
                                    {nbSeanceSentToBill} séance
                                    {nbSeanceSentToBill > 0 ? "s" : ""} envoyée
                                    {nbSeanceSentToBill > 0 ? "s" : ""}
                                  </Text>
                                </Box>
                              )}
                              {nbSeanceMissingPrescription > 0 && (
                                <Box alignItems="center" width="100%">
                                  <Box
                                    height={11}
                                    width={11}
                                    bgcolor={theme.colors.red}
                                    borderRadius={20}
                                    mr={1}
                                  />
                                  <Text
                                    fontSize={11}
                                    textOverflow="ellipsis"
                                    width="100%"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                  >
                                    {nbSeanceMissingPrescription} ordonnance
                                    {nbSeanceMissingPrescription > 0
                                      ? "s"
                                      : ""}{" "}
                                    incomplète
                                    {nbSeanceMissingPrescription > 0 ? "s" : ""}
                                  </Text>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    );
                  }
                )}
              </>
            )}
          </Card>
        </Box>
        <Box
          height="100%"
          width="40%"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          p={2}
        >
          <Text
            fontWeight={300}
            fontSize={23}
            textAlign="left"
            height="10%"
            width="100%"
            alignItems="center"
          >
            Historique d'envoi
          </Text>
          <NavigationBar />
          <Card height="80%" width="100%">
            <Box
              flexDirection="column"
              style={{ overflowY: "scroll", overflowX: "hidden" }}
              height="100%"
              width="100%"
            >
              {foldersSentToBill
                ?.filter((folder) =>
                  isSameMonth(folder.date, filterFoldersSentToBill)
                )
                ?.map((folder) => {
                  const seanceTotal = folder?.seances?.length;
                  const patientTotal = uniqBy(
                    folder?.seances,
                    "patient._id"
                  ).length;
                  return (
                    <Box
                      padding={2}
                      bgcolor={theme.colors.bgPale as string}
                      mb={1}
                      borderRadius={1}
                      flexDirection="column"
                      width="100%"
                    >
                      <Text py={1} color={theme.colors.primary}>
                        Envoyé le {format(folder?.date, "iiii dd MMMM")}
                      </Text>
                      <Box alignItems="center" width="100%">
                        <Text>Nombre de seance:&nbsp;</Text>
                        <Text fontWeight={500} color={theme.colors.primary}>
                          {seanceTotal}
                        </Text>
                      </Box>
                      <Box alignItems="center" width="100%">
                        <Text>Nombre de patient:&nbsp;</Text>
                        <Text fontWeight={500} color={theme.colors.primary}>
                          {patientTotal}
                        </Text>
                      </Box>
                      {folder?.comment && (
                        <Box alignItems="center" width="100%">
                          <Text width="35%">Commentaire:&nbsp;</Text>
                          <Text
                            width="63%"
                            fontSize={12}
                            fontWeight={300}
                            color={theme.colors.primary}
                            m={1}
                            bgcolor={theme.colors.background}
                            borderRadius={5}
                            p={1}
                            style={{ wordBreak: "break-all" }}
                          >
                            {folder?.comment}
                          </Text>
                        </Box>
                      )}
                    </Box>
                  );
                })}
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
