import { fetchAPI } from "@/utils/fetchAPI";

export const getBlob = async (fileUri: string) => {
  try {
    const resp = await fetch(fileUri);
    const blob = await resp.blob();
    return blob;
  } catch (err) {
    console.log("error on get blob", err);
  }
};

export async function fetchDownloadURLPath({ path }: { path: string }) {
  try {
    const result = await fetchAPI({
      url: "/taxi/document/generateS3Url",
      options: { method: "POST", body: JSON.stringify({ path }) },
      application: "taxi",
    });
    if (result?.status > 210) {
      console.log("Error loading S3", result);
      return false;
    }
    return result;
  } catch (e) {
    console.error("error", e);
  }
}
