export type ProductName = "Forfait avec Engagement" | "Gestion+ avec Engagement" | "Saisie PREMIER" | "Gestion+" | "eCofiCoffre";

type PalawaObject = {
  PieceID: number;
  Date: string;
  Numero: string;
  Montant: string;
};

export type CofidocBilling = {
  id?: number;
  date?: Date;
  reference?: string;
  amount?: string;
};

function proxiApiPalawa(objects: PalawaObject[]): CofidocBilling[] {
  const mapped = objects.map((o) => {
    const year = o.Date.substring(0, 4);
    const month = o.Date.substring(4, 6);
    const day = o.Date.substring(6, 8);

    return {
      date: new Date(`${year}-${month}-${day}`),
      id: o?.PieceID,
      reference: o?.Numero,
      amount: o?.Montant,
    };
  });
  return mapped;
}

export async function fetchCofidocBilling() {
  try {
    return proxiApiPalawa(data1 as PalawaObject[]);
  } catch (error) {
    throw new Error();
  }
}

const data1 = [
  {
    PieceID: 281474976780219,
    Date: "20240102",
    Numero: "FA0178788",
    Montant: "574.56",
  },
  {
    PieceID: 281474976782786,
    Date: "20240201",
    Numero: "FA0180054",
    Montant: "671.68",
  },
  {
    PieceID: 281474976785113,
    Date: "20240304",
    Numero: "FA0181997",
    Montant: "570.51",
  },
  {
    PieceID: 281474976787739,
    Date: "20240402",
    Numero: "FA0184750",
    Montant: "289.53",
  },
  {
    PieceID: 281474976789640,
    Date: "20240416",
    Numero: "AV0000226",
    Montant: "-19.32",
  },
  {
    PieceID: 281474976790734,
    Date: "20240502",
    Numero: "FA0186602",
    Montant: "667.09",
  },
];
