import { useRouteError } from "react-router-dom";

export function PageError() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div
      id="error-page"
      style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}
    >
      <h1>Oops 404!</h1>
      <p>Il semblerait que votre demande ait rencontré un problème</p>
      <p>
        <i>{error?.statusText || error?.message}</i>
      </p>
    </div>
  );
}
