import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useAppContext } from "@/app/common/provider/AppProvider";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { Box, Button, Card, Text } from "@/ui";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Document, FolderSendToBill, Patient } from "@/types/taxi";
import "react-calendar/dist/Calendar.css";
import { getRefreshToken } from "@/utils/localStorage";
import { groupBy } from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { DocumentItem } from "@/components/taxi/DocumentItem";
import { addMonths, format, isSameMonth, subMonths } from "date-fns";

export default function History() {
  const theme = useTheme();
  const { client, user } = useMongoContext();
  const { setLoading } = useAppContext();
  const { dbName } = useApplicationContext();
  const [patients, setPatients] = useState<Document[]>([]);
  const [foldersSendToBill, setFoldersSendToBill] = useState<FolderSendToBill[]>([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  const NavigationBar = () => {
    return (
      <Card display="flex" justifyContent="space-between" alignItems="center" width="100%" p={2}>
        <Button onClick={() => setCurrentDate(subMonths(currentDate, 1))}>
          <ArrowBackIcon />
        </Button>
        <Text>{format(new Date(currentDate), "MMMM yyyy")}</Text>
        <Button onClick={() => setCurrentDate(addMonths(currentDate, 1))}>
          <ArrowForwardIcon />
        </Button>
      </Card>
    );
  };
  const taxi_username = user?.identities[0]?.id;

  // Get data function
  async function getData() {
    try {
      setLoading(true);
      const patientCollection = client.db(dbName).collection("Patient");
      const folderSendToBillCollection = client.db(dbName).collection("FolderSendToBilling");
      const folder = await folderSendToBillCollection.find();
      const pat = await patientCollection.find({ username: taxi_username, deleted: { $ne: true } });
      console.log("=>  folder:", folder);
      setPatients(pat);
      setFoldersSendToBill(folder);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user && client) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client]);

  return (
    <>
      <Box alignItems="center" justifyContent="space-between" pb={1}>
        <Box p={2}>
          <Text>Historique de mes envois</Text>
        </Box>
      </Box>
      <Box alignItems="center" justifyContent="center" pb={1}>
        <NavigationBar />
      </Box>

      <Box flexDirection="column" style={{ overflowY: "scroll" }} width="100%">
        {foldersSendToBill
          ?.filter((folder) => isSameMonth(folder.date, currentDate))
          .map((folder) => {
            return (
              <Box>
                <Text>{format(folder.date, "dd MMMM yyyy")}</Text>
              </Box>
            );
          })}
      </Box>
    </>
  );
}
