/* eslint-disable react-hooks/exhaustive-deps */

import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Text, NavLink } from "../../ui";
import { useMongoContext } from "../common/provider/MongoProvider";
import HomeIcon from "@mui/icons-material/Home";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { fetchSetCurrentOffice } from "@/api/nurse/fetchSetCurrentOffice";
import qs from "query-string";
import { useTheme } from "@mui/material";
import { useApplicationContext } from "../common/provider/ApplicationContext";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import ArticleIcon from "@mui/icons-material/Article";

import {
  setIdToken,
  setAccessToken,
  setRefreshToken,
} from "@/utils/localStorage";
import { useOfficeContext } from "./provider/OfficeProvider";
import { useState } from "react";
import { useFormContext } from "./provider/FormProvider";

export function Header() {
  const isInovie = process.env.REACT_APP_INOVIE_DEL;
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      height="6vh"
      zIndex={9}
      className="overBg"
    >
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="10vw"
          mr={2}
          pt={1}
        >
          {isInovie ? (
            <div className="logo-inovie" style={{ height: "100%" }} />
          ) : (
            <div className="logo-cofidoc" style={{ height: "100%" }} />
          )}
        </Box>
      </Box>
      <Box>
        <Profile />
      </Box>
    </Box>
  );
}

export function LeftNavBar() {
  const { currentOfficeInfo } = useOfficeContext();
  const navigate = useNavigate();

  const location = useLocation();
  const theme = useTheme();

  const navigateAgenda = () => {
    if (currentOfficeInfo) {
      const start = new Date(new Date().setDate(new Date().getDate() - 31));
      start.setHours(0, 0);
      const end = new Date(new Date().setDate(new Date().getDate()));
      end.setHours(23, 59);

      let to = qs.stringify(
        {
          start: start.getTime(),
          end: end.getTime(),
        },
        { arrayFormat: "index" }
      );
      navigate(
        `/nurse/app/offices/${currentOfficeInfo?.officeId}/agenda?${to}`
      );
    } else {
      let to = qs.stringify(
        {
          redirect: "agenda",
        },
        { arrayFormat: "index" }
      );
      navigate(`/nurse/app/offices?${to}`);
    }
  };

  const navigatePatient = () => {
    if (currentOfficeInfo) {
      const start = new Date(new Date().setDate(new Date().getDate() - 31));
      start.setHours(0, 0);
      const end = new Date(new Date().setDate(new Date().getDate()));
      end.setHours(23, 59);

      let to = qs.stringify(
        {
          start: start.getTime(),
          end: end.getTime(),
        },
        { arrayFormat: "index" }
      );
      navigate(
        `/nurse/app/offices/${currentOfficeInfo?.officeId}/patient?${to}`
      );
    } else {
      let to = qs.stringify(
        {
          redirect: "agenda",
        },
        { arrayFormat: "index" }
      );
      navigate(`/nurse/app/offices?${to}`);
    }
  };

  const navigateActivity = () => {
    if (currentOfficeInfo) {
      const start = new Date(new Date().setDate(new Date().getDate() - 31));
      start.setHours(0, 0);
      const end = new Date(new Date().setDate(new Date().getDate()));
      end.setHours(23, 59);

      let to = qs.stringify(
        {
          start: start.getTime(),
          end: end.getTime(),
        },
        { arrayFormat: "index" }
      );
      navigate(
        `/nurse/app/offices/${currentOfficeInfo?.officeId}/activity?${to}`
      );
    } else {
      let to = qs.stringify(
        {
          redirect: "activity",
        },
        { arrayFormat: "index" }
      );
      navigate(`/nurse/app/offices?${to}`);
    }
  };

  const navigationRefund = () => {
    const period = new Date(new Date().setDate(new Date().getDate()));
    period.setHours(0, 0);

    let to = qs.stringify(
      {
        period: period.getTime(),
      },
      { arrayFormat: "index" }
    );
    navigate(`/nurse/app/refund?${to}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="13vw"
      height="100%"
      zIndex={100}
      className="overBg"
      alignItems="flex-start"
      pl={3}
    >
      <Box pt={5} />
      {/* <ButtonNew /> */}
      <Box p={2} />
      <NavLink
        onClick={() => navigate("/nurse/app/home")}
        selected={location.pathname.includes("home")}
      >
        <HomeIcon
          fontSize="small"
          style={{ color: theme.colors.dark as any }}
        />
        <Box pr={1} />
        <Text m={0}>Tableau de bord</Text>
      </NavLink>
      <NavLink
        onClick={navigationRefund}
        selected={location.pathname.includes("refund")}
      >
        <AccountBalanceIcon
          fontSize="small"
          style={{ color: theme.colors.dark as any }}
        />
        <Box pr={1} />
        <Text m={0}>Facturation</Text>
      </NavLink>

      {/* <NavLink onClick={navigatePatient} selected={location.pathname.includes("offices") && location.pathname.includes("patient")}>
        <PersonIcon fontSize="small" style={{ color: theme.colors.dark as any }} />
        <Box pr={1} />
        <Text m={0}>Patients</Text>
      </NavLink>
      <NavLink onClick={navigateAgenda} selected={location.pathname.includes("offices") && location.pathname.includes("agenda")}>
        <EventAvailableIcon fontSize="small" style={{ color: theme.colors.dark as any }} />
        <Box pr={1} />
        <Text m={0}>Agenda</Text>
      </NavLink>
      <NavLink onClick={navigateActivity} selected={location.pathname.includes("offices") && location.pathname.includes("activity")}>
        <AssessmentIcon fontSize="small" style={{ color: theme.colors.dark as any }} />
        <Box pr={1} />
        <Text m={0}>Envois</Text>
      </NavLink> */}
    </Box>
  );
}

function Profile() {
  const { app }: any = useMongoContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { application } = useApplicationContext();
  const isInovie = process.env.REACT_APP_INOVIE_DEL;

  return (
    <Box justifyContent="center" alignItems="center" pr={2}>
      {!isInovie && location.pathname.includes("refund") && (
        <Button
          style={{ marginRight: 20 }}
          // Aller sur le site espace-client.cofidoc.fr
          onClick={() => window.open("https://espace-client.cofidoc.fr")}
        >
          Accéder à mon ancien espace
        </Button>
      )}

      <Button
        onClick={async () => {
          await app?.currentUser?.logOut();
          fetchSetCurrentOffice({
            currentOffice: null,
            realmApp: null,
            application,
          });
          navigate("/welcome");
          setIdToken("");
          setAccessToken("");
          setRefreshToken("");
        }}
      >
        Déconnexion
      </Button>
    </Box>
  );
}

function ButtonNew() {
  const { handleForm } = useFormContext();
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  function handleVisible() {
    if (visible) {
      setOpen(false);
      setTimeout(() => setVisible(false), 500);
    } else {
      setVisible(true);
      setOpen(true);
    }
  }

  return (
    <Box
      ml={3}
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Box
        boxShadow={`0px 2px 6px -5px ${theme.palette.primary.dark}80`}
        borderRadius={2}
        p={2}
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        onClick={handleVisible}
        bgcolor={theme.colors.white}
        sx={{
          cursor: "pointer",
          transition: "all ease 250ms",
          "&:hover": {
            backgroundColor: `${theme.palette.primary.light}10`,
            boxShadow: `0px 2px 10px -5px ${theme.palette.primary.dark}80`,
          },
        }}
      >
        <AddIcon style={{ color: theme.palette.primary.main }} />
        <Text pl={2}>Nouveau</Text>
      </Box>
      {visible && (
        <Box
          position="absolute"
          top={50}
          width={270}
          p={2}
          borderRadius={2}
          bgcolor={theme.colors.white}
          flexDirection="column"
          left={0}
          boxShadow={`0px 2px 6px -3px ${theme.palette.primary.dark}80`}
          height={open ? 150 : 50}
          sx={{
            opacity: open ? 1 : 0,
            transition:
              "height 250ms cubic-bezier(0.4,0,0.2,1) 0ms,margin-top 250ms cubic-bezier(0.4,0,0.2,1),opacity 250ms cubic-bezier(0.4,0,0.2,1)",
            willChange: "height,margin-top,opacity",
          }}
        >
          <Box
            onClick={() => {
              handleVisible();
              handleForm("patient");
            }}
            flexDirection="row"
            alignItems="center"
            p={2}
            height={50}
            borderRadius={1}
            sx={{
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#e6e6e6",
              },
            }}
          >
            <PersonIcon
              fontSize="small"
              style={{ color: theme.colors.dark as any }}
            />
            <Text pl={2}>Nouveau patient</Text>
          </Box>
          <Box
            onClick={() => {
              handleVisible();
              handleForm("prescription");
            }}
            flexDirection="row"
            alignItems="center"
            p={2}
            height={50}
            borderRadius={1}
            sx={{
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#e6e6e6",
              },
            }}
          >
            <ArticleIcon
              fontSize="small"
              style={{ color: theme.colors.dark as any }}
            />
            <Text pl={2}>Nouvelle ordonnance</Text>
          </Box>
          <Box
            onClick={() => {
              handleVisible();
              handleForm("seance");
            }}
            flexDirection="row"
            alignItems="center"
            p={2}
            borderRadius={1}
            height={50}
            sx={{
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#e6e6e6",
              },
            }}
          >
            <InsertInvitationIcon
              fontSize="small"
              style={{ color: theme.colors.dark as any }}
            />
            <Text pl={2}>Nouvelle séance</Text>
          </Box>
        </Box>
      )}
    </Box>
  );
}
