import { useNavigate } from "react-router-dom";
import { Box, Card, Text } from "@/ui";
import { ApplicationType } from "@/types/common";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { useTheme } from "@mui/material";

export function Welcome() {
  const navigate = useNavigate();
  const isInovie = process.env.REACT_APP_INOVIE_DEL;
  const theme = useTheme();
  const { setApplication } = useApplicationContext();

  function selectApp(appType: ApplicationType) {
    if (appType === "taxi") {
      setApplication("taxi");
      navigate("/taxi/login");
    }
    if (appType === "mobility") {
      setApplication("mobility");
      navigate("/nurse/login");
    }
    if (appType === "inovidel") {
      setApplication("inovidel");
      navigate("/nurse/login");
    }
  }
  console.log("=>  isInovie:", isInovie);

  return (
    <Box
      height="100vh"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      style={{ backgroundColor: "#F1F1F1" }}
    >
      {isInovie ? (
        <div className="background-mountain-inovie" />
      ) : (
        <div className="background-mountain-cofidoc" />
      )}
      <div className="background-cloud height40" />
      <div className="background-over" />
      <Box style={{ position: "relative", zIndex: 2 }}>
        <Card
          justifyContent="center"
          alignItems="center"
          display="flex"
          flexDirection="column"
          width="650px"
        >
          <Box display="flex" p={2} alignItems="center" justifyContent="center">
            {isInovie ? (
              <div className="background-logo-inovie" />
            ) : (
              <div className="background-logo" />
            )}
          </Box>
          <Box
            style={{
              marginBottom: 20,
              textAlign: "center",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{ fontSize: 20, textAlign: "center", fontWeight: 500 }}
            >
              Bienvenue sur votre portail&nbsp;
              <span
                style={{
                  fontWeight: 500,
                  color: theme.colors.primary as any,
                }}
              >
                {isInovie ? "Inovidel" : "Cofidoc"}
              </span>
            </Text>
            {!isInovie && (
              <Text
                style={{
                  fontSize: 15,
                  textAlign: "center",
                  fontWeight: 400,
                  padding: "15px",
                }}
              >
                Sélectionnez le service Cofidoc auquel vous souhaitez accéder
              </Text>
            )}
          </Box>
          <Box justifyContent="center" alignItems="center" height={20}></Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {!isInovie && (
              <>
                <BoxLink onClick={() => selectApp("mobility")} height="100px">
                  <Box justifyContent="center" alignItems="center" width="20%">
                    <div
                      className="background-logo-mobility"
                      style={{
                        borderRadius: "10px",
                        border: "3px white solid",
                        overflow: "hidden",
                      }}
                    />
                  </Box>

                  <Text width="80%" textAlign="left" fontWeight="500" pl={2}>
                    Je suis paramédical
                  </Text>
                </BoxLink>
                <BoxLink onClick={() => selectApp("taxi")}>
                  <Box justifyContent="center" alignItems="center" width="20%">
                    <div
                      className="background-logo-taxi"
                      style={{
                        borderRadius: "10px",
                        border: "3px white solid",
                        overflow: "hidden",
                      }}
                    />
                  </Box>

                  <Text width="80%" textAlign="left" fontWeight="500" pl={2}>
                    Je suis taxi médical
                  </Text>
                </BoxLink>
              </>
            )}

            {isInovie && (
              <BoxLink onClick={() => selectApp("inovidel")}>
                <Box justifyContent="center" alignItems="center" width="20%">
                  <div
                    className="background-logo-inovidel"
                    style={{
                      borderRadius: "10px",
                      border: "3px white solid",
                      overflow: "hidden",
                    }}
                  />
                </Box>

                <Text width="80%" textAlign="left" fontWeight="500" pl={2}>
                  Connexion à mon espace client Inov'IDEL
                </Text>
              </BoxLink>
            )}
          </Box>
          <Box justifyContent="center" alignItems="center" height={20}></Box>
        </Card>
      </Box>
      <Box style={{ position: "absolute", bottom: 0, zIndex: 3, padding: 5 }}>
        <Text style={{ textAlign: "center", fontSize: 12 }}>
          © 2024 {!isInovie && "Cofidoc Groupe"}
        </Text>
      </Box>
    </Box>
  );
}

function BoxLink({ onClick, children }: any) {
  const theme = useTheme();

  return (
    <Box
      onClick={onClick}
      m={2}
      p={2}
      bgcolor={theme.colors.bgPale as string}
      sx={{
        borderRadius: 1,
        border: "1px solid #e0e0e0",
        width: "300px",
        height: "80px",
        cursor: "pointer",
        transition: "all ease 200ms",
        "&:hover": {
          backgroundColor: theme.colors.primary,
          color: "white",
        },
      }}
      alignItems="center"
      justifyContent="center"
      display="flex"
      flexDirection="row"
      textAlign="center"
      position="relative"
    >
      {children}
    </Box>
  );
}
