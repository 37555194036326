import { Patient } from "@/types/taxi";
import { Box } from "@/ui";
import { useTheme } from "@mui/material";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { RenderInformation } from "./TabInfo";
import { RenderJourney } from "./TabJourney";
import { RenderDocument } from "./TabDocument";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ height: "calc(100% - 50px)" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box px={5} flexDirection="column" height="100%">
          {children}
        </Box>
      )}
    </div>
  );
}

export default function PatientIdView() {
  const { patient }: { patient: Patient } = useOutletContext();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const theme = useTheme();
  return (
    <Box width="100%" justifyContent="space-around">
      <Box sx={{ width: "100%" }} flexDirection="column">
        <Box>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{ height: "50px" }}>
            <Tab label="Information" />
            <Tab label="Trajets" />
            <Tab label="Documents" />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <RenderInformation patient={patient} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <RenderJourney patient={patient} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <RenderDocument patient={patient} />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
