import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PrivateRoute } from "./common/PrivateRoute";
import { PageError } from "./common/PageError";
import { Welcome } from "@/routes/Welcome";
import { Root } from "@/routes";

// Nurse specific imports
import NurseActivityView from "@/routes/nurse/app/offices/$officeId/activity";
import NurseAgendaView from "@/routes/nurse/app/offices/$officeId/agenda";
import NurseRefundView from "@/routes/nurse/app/refund";
import NurseForgotPasswordView from "@/routes/nurse/auth/password-forgotten";
import NurseHomeView from "@/routes/nurse/app/home";
import NurseLoginView from "@/routes/nurse/auth/login";
import NurseOfficeIdView from "@/routes/nurse/app/offices/$officeId";
import NurseOfficePatient from "@/routes/nurse/app/offices/$officeId/patient";
import NurseOfficeListView from "@/routes/nurse/app/offices";
import NursePatientIdView from "@/routes/nurse/app/offices/$officeId/agenda/$patientId";
import NursePatientIdDetailsView from "@/routes/nurse/app/offices/$officeId/patient/$patientId";

import NurseRoot from "@/routes/nurse";
import NurseApp from "@/routes/nurse/app";

// Taxi specific imports
import TaxiRefundView from "@/routes/taxi/app/refund";
import TaxiForgotPassword from "@/routes/taxi/auth/password-forgotten";
import TaxiHomeView from "@/routes/taxi/app/home";
import TaxiLoginView from "@/routes/taxi/auth/login";
import TaxiPatientsListView from "@/routes/taxi/app/patient";
import TaxiPatientIdView from "@/routes/taxi/app/patient/$patientId";
import TaxiRoot from "@/routes/taxi";
import TaxiApp from "@/routes/taxi/app";
import TaxiActivity from "@/routes/taxi/app/activity";

import { ApplicationType } from "@/types/common";
import { UpdatePasswordNurse } from "@/routes/nurse/auth/password-update";

export function AppRouter({ application }: { application?: ApplicationType }) {
  const commonRoutes = [{ path: "/welcome", element: <Welcome /> }];

  const nurseRoutes = [
    {
      path: "/nurse",
      element: <NurseRoot />,
      children: [
        { path: "login", element: <NurseLoginView /> },
        { path: "create-password", element: <UpdatePasswordNurse /> },
        { path: "forgot-password", element: <NurseForgotPasswordView /> },
        {
          path: "app",
          element: <NurseApp />,
          children: [
            {
              path: "offices",
              element: <PrivateRoute Component={NurseOfficeListView} />,
            },
            {
              path: "home",
              element: <PrivateRoute Component={NurseHomeView} />,
            },
            {
              path: "offices/:officeId",
              element: <PrivateRoute Component={NurseOfficeIdView} />,
              children: [
                {
                  path: "agenda",
                  element: <PrivateRoute Component={NurseAgendaView} />,
                  children: [
                    {
                      path: ":patientId",
                      element: <PrivateRoute Component={NursePatientIdView} />,
                    },
                  ],
                },
                {
                  path: "activity",
                  element: <PrivateRoute Component={NurseActivityView} />,
                },
                {
                  path: "patient",
                  element: <PrivateRoute Component={NurseOfficePatient} />,
                  children: [
                    {
                      path: ":patientId",
                      element: (
                        <PrivateRoute Component={NursePatientIdDetailsView} />
                      ),
                    },
                  ],
                },
              ],
            },
            {
              path: "refund",
              element: <PrivateRoute Component={NurseRefundView} />,
            },
          ],
        },
      ],
    },
  ];

  const taxiRoutes = [
    {
      path: "/taxi",
      element: <TaxiRoot />,
      children: [
        { path: "login", element: <TaxiLoginView /> },
        { path: "forgot-password", element: <TaxiForgotPassword /> },
        {
          path: "app",
          element: <TaxiApp />,
          children: [
            {
              path: "home",
              element: <PrivateRoute Component={TaxiHomeView} />,
            },
            {
              path: "refund",
              element: <PrivateRoute Component={TaxiRefundView} />,
            },
            {
              path: "patient",
              element: <PrivateRoute Component={TaxiPatientsListView} />,
              children: [
                {
                  path: ":patientId",
                  element: <PrivateRoute Component={TaxiPatientIdView} />,
                },
              ],
            },
            {
              path: "activity",
              element: <PrivateRoute Component={TaxiActivity} />,
            },
          ],
        },
      ],
    },
  ];

  let children = [...commonRoutes];

  if (application === "taxi") {
    children = [...commonRoutes, ...taxiRoutes];
  }
  if (application === "mobility" || application === "inovidel") {
    children = [...commonRoutes, ...nurseRoutes];
  }

  const routes = [
    {
      path: "/",
      element: <Root />,
      errorElement: <PageError />,
      children,
    },
  ];

  const BrowserRouter = createBrowserRouter(routes);

  return <RouterProvider router={BrowserRouter} />;
}
