import { fetchAPI } from "@/utils/fetchAPI";

type FetchForgotPassword = { email: string; realmApp: any };
type FetchResetPassword = { email: string; code: string; password: string; realmApp: any };

export async function fetchForgotPassword({ email, realmApp }: FetchForgotPassword) {
  const res = await fetchAPI({
    url: "/taxi/passwordForgotten",
    options: {
      method: "POST",
      body: JSON.stringify({ email }),
    },
    realmApp,
    application: "taxi",
  });
  console.log(" res:", res);
  return res;
}

export async function fetchResetPassword({ email, code, password, realmApp }: FetchResetPassword) {
  const res = await fetchAPI({
    url: "/taxi/changePassword",
    options: {
      method: "POST",
      body: JSON.stringify({ email, code, password }),
    },
    realmApp,
    application: "taxi",
  });
  console.log(" res:", res);
  return res;
}
