import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { createContext, useContext, useEffect, useState } from "react";

export enum ClientType {
  cofidoc,
  not_client,
  trial,
}

type UserClientType = {
  isLoadingClient: boolean;
  clientType: ClientType;
  isClient: boolean;
};

export const UserClient = createContext<UserClientType>({} as UserClientType);

export function UserClientProvider({ children }: any) {
  const { app } = useMongoContext();
  const [clientType, setClientType] = useState();
  const [isLoadingClient, setIsLoadingClient] = useState<boolean>(true);

  useEffect(() => {
    setClientType((app?.currentUser?.customData?.currentOffice as any)?.clientType);
    setIsLoadingClient(false);
  }, [app?.currentUser?.customData]);

  return (
    <UserClient.Provider
      value={{
        isLoadingClient,
        clientType: clientType,
        isClient: Boolean(clientType),
      }}
    >
      {children}
    </UserClient.Provider>
  );
}
export function useUserClient() {
  const officecontext = useContext(UserClient);
  return officecontext;
}
