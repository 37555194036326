import { Formik, Form } from "formik";
import { Box, Text, Button, Link } from "../../../../ui";
import { Card } from "../../../../ui/Card";
import { TextField } from "../../../../ui/field/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { fetchUpdatePassword } from "@/api/nurse/fetchAuth";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";

export function UpdatePasswordNurse() {
  const { app } = useMongoContext();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { application } = useApplicationContext();
  const isInovie = process.env.REACT_APP_INOVIE_DEL;
  const { state } = useLocation();

  return (
    <Box
      height="100vh"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      style={{ backgroundColor: "#f1f1f1" }}
    >
      <div className="background-cloud height40" />
      <div className="background-over" />
      {isInovie ? (
        <div className="background-mountain-inovie" />
      ) : (
        <div className="background-mountain-cofidoc" />
      )}
      <Box style={{ position: "relative", zIndex: 2 }}>
        <Formik
          initialValues={{ newPassword: "" }}
          onSubmit={async (values) => {
            try {
              setError(null);
              await fetchUpdatePassword({
                email: state.email,
                oldPassword: state.password as string,
                newPassword: values.newPassword,
                realmApp: app,
                application,
              });

              navigate("/nurse/login");
            } catch (e) {
              console.log("error", e);
              setError(
                "Une erreur est survenue merci de contacter votre gestionnaire"
              );
            }
          }}
        >
          <Form>
            <Card width={456} display="flex" flexDirection="column">
              <Box display="flex" alignItems="left">
                <Link onClick={() => navigate("/welcome")}>
                  <ArrowBackIcon fontSize="small" />
                  <Box pr={1} />
                  <Text style={{ textAlign: "center", fontSize: 12 }}>
                    Retour au portail de connexion
                  </Text>
                </Link>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  p={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  {isInovie ? (
                    <div
                      className="background-logo-inovidel"
                      style={{
                        borderRadius: "10px",
                        overflow: "hidden",
                        height: "150px",
                        width: "150px",
                      }}
                    />
                  ) : (
                    <div
                      className="background-logo-mobility"
                      style={{
                        borderRadius: "10px",
                        overflow: "hidden",
                        height: "100px",
                        width: "100px",
                      }}
                    />
                  )}
                </Box>
                <Box
                  style={{ margin: 20, textAlign: "center", fontWeight: 600 }}
                >
                  <Text style={{ textAlign: "center", fontWeight: 600 }}>
                    Creer votre mot de passe
                  </Text>
                </Box>

                <>
                  <TextField
                    type="password"
                    label="Nouveau mot de passe"
                    name="newPassword"
                     
                  />
                </>

                <Box justifyContent="center" alignItems="center" height={60}>
                  {error && (
                    <Box fontSize={11} color="#ff0000">
                      {error}
                    </Box>
                  )}
                </Box>
                <Box flex={1} justifyContent="center" mt={3}>
                  <Button type="submit">Valider</Button>
                </Box>
                <Box justifyContent="center" alignItems="center" height={60}>
                  <Link onClick={() => navigate("/nurse/login")}>
                    <Text style={{ textAlign: "center", fontSize: 12 }}>
                      Retour à la page de connexion
                    </Text>
                  </Link>
                </Box>
              </Box>
            </Card>
          </Form>
        </Formik>
      </Box>
      <Box style={{ position: "absolute", bottom: 0, zIndex: 3, padding: 5 }}>
        <Text style={{ textAlign: "center", fontSize: 12 }}>
          © 2024 {!isInovie && "Cofidoc Groupe"}
        </Text>
      </Box>
    </Box>
  );
}
