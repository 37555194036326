import { Patient } from "@/types/nurse";
import { Box, TextField } from "@/ui";
import { Form, Formik } from "formik";

type Props = { initialValues: Partial<Patient>; handleSubmit: any };

export const CareForm = ({ initialValues, handleSubmit }: Props) => {
  return (
    <Box flexDirection="column">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <TextField label="Numéro de sécurité social" name="socialSecurityNumber" />
          <TextField label="Nom de famille" name="lastname" />
          <TextField label="Prénom" name="name" />
        </Form>
      </Formik>
    </Box>
  );
};
