import { fetchDownloadURLMedia } from "@/api/taxi/fetchDownloadURLMedia";
import { fetchDownloadURLPath } from "@/api/taxi/fetchDownloadURLPath";
import { useEffect, useState } from "react";

export function useGetURI(uri: string) {
  const [response, setResponse] = useState<string | undefined>(undefined);

  async function main() {
    try {
      // new media system
      if (!String(uri)?.startsWith("https")) {
        const url = await fetchDownloadURLMedia({ path: uri });
        if (url) setResponse(url);
      }
      // old path system, retrocompatibility
      if (String(uri)?.startsWith("https")) {
        const url = await fetchDownloadURLPath({ path: uri });
        if (url) setResponse(url);
      }
    } catch (error) {
      console.error("Log error use get uri:", error);
    }
  }

  useEffect(() => {
    main();
  }, []);

  return response;
}
