export type Patient = {
  _id: string;
  address: string;
  birth: Date;
  citycode?: string;
  createdAt?: Date;
  deleted?: boolean;
  externalId?: string;
  geocodedAddress?: GeocodedAddressPatient;
  health?: string;
  healthInsuranceCard?: string;
  healthInsuranceName?: string;
  insuranceHolderFirstName?: string;
  insuranceHolderLastName?: string;
  isInsuranceHolder: boolean;
  lastname: string;
  managementCentre?: string;
  mediaHealthInsurance?: Media[];
  mediaSocialSecurity?: Media[];
  name: string;
  nonInsuredBirth?: Date;
  phoneNumber?: string;
  postcode?: string;
  socialSecurityCard?: string;
  socialSecurityName?: string;
  socialSecurityNumber: string;
  updatedAt?: number;
  username: string;
};

export type GeocodedAddress = GeocodedAddressPatient | GeocodedAddressArrivalJourney | GeocodedAddressDepartureJourney;

export type GeocodedAddressPatient = {
  lat?: string;
  lng?: string;
  zipCode?: string;
  address?: string;
  city?: string;
};
export type GeocodedAddressArrivalJourney = {
  lat?: string;
  lng?: string;
  zipCode?: string;
  address?: string;
  city?: string;
};
export type GeocodedAddressDepartureJourney = {
  lat?: string;
  lng?: string;
  zipCode?: string;
  address?: string;
  city?: string;
};

export type Media = RealmMediaHealthInsurance | RealmMediaSocialSecurity | RealmMediaDocument;

export type RealmMediaHealthInsurance = {
  _id: string;
  type: string;
  provisionalHightQualityDocument: ArrayBuffer;
  lowQualityDocument: ArrayBuffer;
  storagePath: string;
  contentType?: string;
};

export type RealmMediaSocialSecurity = {
  _id: string;
  type: string;
  provisionalHightQualityDocument: ArrayBuffer;
  lowQualityDocument: ArrayBuffer;
  storagePath: string;
  contentType?: string;
};

export type RealmMediaDocument = {
  _id: string;
  type: string;
  provisionalHightQualityDocument: ArrayBuffer;
  lowQualityDocument: ArrayBuffer;
  storagePath: string;
  contentType?: string;
};

export enum DocumentStatus {
  uploaded = "uploaded",
  sentForBilling = "sentForBilling",
}

export type Document = {
  _id: string;
  paths?: string[];
  type: DocumentTypes;
  timestamp: Date;
  comment?: string;
  patient: Patient;
  status: DocumentStatus;
  sentForBillingOn?: Date;
  keepToSend?: boolean;
  updatedAt?: number;
  media?: Media[];
};

export enum DocumentTypes {
  transportVoucher = "transportVoucher",
  situationReport = "situationReport",
  annex4 = "annex4",
  ticket = "ticket",
  custom = "custom",
}

export enum JourneyStatus {
  PREPARED = "prepared",
  SIGNED = "signed",
  ANNEX_4_GENERATED = "annex4Generated",
  FINISHED_WITHOUT_SIGNATURE = "finishedWithoutSignature",
}

export type Car = {
  _id: string;
  mark: string;
  model: string;
  deleted?: boolean;
  numberplate: string;
  isDefault: boolean;
  username: string;
  updatedAt?: number;
  parkingNumber?: string;
};

export type Journey = {
  _id: string;
  annex4Id?: string;
  arrivalCitycode?: string;
  arrivalGeocodedAddress?: GeocodedAddressArrivalJourney;
  arrivalLocation: string;
  arrivalPostcode?: string;
  car: Car;
  comment?: string;
  concurrentJourneyIds?: string[];
  concurrentJourneysCount?: string;
  counterPrice?: string;
  counterPriceReturn?: string;
  createdAt?: Date;
  dayTimeKm?: string;
  deleted?: boolean;
  departureCitycode?: string;
  departureGeocodedAddress?: GeocodedAddressDepartureJourney;
  departureLocation: string;
  departurePostcode?: string;
  distancer: boolean;
  driver?: string;
  externalId?: string;
  isRoundTrip?: boolean;
  isWaitingTimeWay?: boolean;
  journeyType: string;
  journeyTypeReturn?: string;
  nightTimeKm?: string;
  outwardArrivalDatetime: Date;
  outwardDepartureDatetime: Date;
  patient: string;
  pricePackage?: string;
  publicHoliday?: boolean;
  publicHolidayReturn?: boolean;
  reason?: string;
  returnArrivalDatetime?: Date;
  returnDayTimeKm?: string;
  returnDepartureDatetime?: Date;
  returnNightTimeKm?: string;
  returnToll?: string;
  snow?: boolean;
  snowReturn?: boolean;
  status: JourneyStatus;
  stringOutwardArrivalDatetime?: string;
  stringOutwardDepartureDatetime?: string;
  stringReturnArrivalDatetime?: string;
  stringReturnDepartureDatetime?: string;
  takeOver?: boolean;
  takeOverReturn?: boolean;
  toll?: string;
  tpmr?: boolean;
  tpmrReturn?: boolean;
  updatedAt?: number;
  version?: string;
  waitingTime?: string;
};
export type User = {
  _id: string;
  lastname: string;
  name: string;
  email: string;
  finess: string;
  enterprise?: Enterprise;
  hasAcceptedCGU?: boolean;
  username: string;
  needRestoreBackup?: boolean;
  needManualUploadLocalChange?: boolean;
  lastManualUploadLocalChangeSuccessDate?: Date;
  groupId?: string;
};

export type Enterprise = {
  username: string;
  _id: string;
  nameAndAddress: string;
  signature: string;
  practiceDepartment?: string;
  ameliProLogin?: string;
  ameliProPassword?: string;
  updatedAt?: number;
};

export type Driver = {
  readonly username: string;
  readonly _id: string;
  firstName?: string;
  lastName?: string;
  defaultCar?: string | null;
  deleted?: boolean;
  createdAt?: Date;
};

export type Invoice = {
  _id: any;
  username: string;
  date: Date;
  documentPath: string;
};

export type FolderSendToBill = {
  _id: string;
  date: Date;
  userId: string;
  journeys: Journey[];
  documents: PatientDocuments[];
  status: "sending" | "send" | "error";
  comment?: string;
};

export type PatientDocuments = {
  documentPaths: Partial<Document>[];
  patient: Patient;
};
