import { useTheme } from "@mui/material";
import { Box } from "./Box";

export function Spinner({ size = 20 }: { size?: number }) {
  const theme = useTheme();

  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: 50,
        borderWidth: 3,
        borderStyle: "solid",
        borderTopColor: theme.palette.primary.main,
        borderLeftColor: theme.palette.primary.main,
        borderRightColor: theme.palette.primary.main,
        borderBottomColor: "transparent",
        animation: "rotation 1s linear infinite",
      }}
      // className="anime-loader"
    />
  );
}
