import { createContext, useContext } from "react";

type MongoContextType = {
  app: Realm.App | null;
  client: Realm.Services.MongoDB | null;
  user: Realm.User | null;
  setApp: any | null;
  setClient: any | null;
  setUser: any | null;
};
const mongoContext = createContext<MongoContextType | undefined>({
  app: null,
  client: null,
  user: null,
  setApp: (app: any) => {},
  setClient: (user: any) => {},
  setUser: (user: any) => {},
});
export const MongoProvider = mongoContext.Provider;

export function useMongoContext() {
  const c = useContext(mongoContext);
  if (!c) throw new Error("Can't use useApp outside of AppProvider");
  return c;
}
