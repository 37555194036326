import { Box } from "@/ui";
import { useFormContext } from "../../../app/nurse/provider/FormProvider";
import { PatientCreate } from "../form/patient/PatientCreate";
import { CareCreate } from "../form/care/CareCreate";
import { PrescriptionCreate } from "../form/presciption/PrescriptionCreate";
import { SeanceCreate } from "../form/seance/SeanceCreate";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";

export function FormHoc({ children }: any) {
  const { careForm, patientForm, prescriptionForm, seanceForm, handleForm } = useFormContext();
  const theme = useTheme();
  return (
    <>
      {careForm && (
        <Box position="fixed" width="100vw" height="100vh" justifyContent="center" alignItems="center" bgcolor="#a9a9a932" zIndex={9999999999}>
          <Box width="80vw" position="relative" maxWidth="1600px" height="90vh" borderRadius={5} bgcolor="#fff">
            <Box position="absolute" zIndex={2} top={10} right={10} style={{ cursor: "pointer" }} onClick={() => handleForm(undefined)}>
              <CloseIcon style={{ color: theme.palette.primary.main }} />
            </Box>
            <CareCreate />
          </Box>
        </Box>
      )}
      {patientForm && (
        <Box position="fixed" width="100vw" height="100vh" justifyContent="center" alignItems="center" bgcolor="#a9a9a932" zIndex={9999999999}>
          <Box width="80vw" position="relative" maxWidth="1600px" height="90vh" borderRadius={5} bgcolor="#fff">
            <Box position="absolute" zIndex={2} top={10} right={10} style={{ cursor: "pointer" }} onClick={() => handleForm(undefined)}>
              <CloseIcon style={{ color: theme.palette.primary.main }} />
            </Box>
            <PatientCreate />
          </Box>
        </Box>
      )}
      {prescriptionForm && (
        <Box position="fixed" width="100vw" height="100vh" justifyContent="center" alignItems="center" bgcolor="#a9a9a932" zIndex={9999999999}>
          <Box width="80vw" position="relative" maxWidth="1600px" height="90vh" borderRadius={5} bgcolor="#fff">
            <Box position="absolute" zIndex={2} top={10} right={10} style={{ cursor: "pointer" }} onClick={() => handleForm(undefined)}>
              <CloseIcon style={{ color: theme.palette.primary.main }} />
            </Box>
            <PrescriptionCreate />
          </Box>
        </Box>
      )}
      {seanceForm && (
        <Box position="fixed" width="100vw" height="100vh" justifyContent="center" alignItems="center" bgcolor="#a9a9a932" zIndex={9999999999}>
          <Box width="80vw" position="relative" maxWidth="1600px" height="90vh" borderRadius={5} bgcolor="#fff">
            <Box position="absolute" zIndex={2} top={10} right={10} style={{ cursor: "pointer" }} onClick={() => handleForm(undefined)}>
              <CloseIcon style={{ color: theme.palette.primary.main }} />
            </Box>
            <SeanceCreate />
          </Box>
        </Box>
      )}
      {children}
    </>
  );
}
