import { Outlet } from "react-router-dom";
import { Box } from "@/ui";
import { Header, LeftNavBar } from "@/app/taxi/AppShell";
import { useEffect } from "react";
import { getAccessToken, getIdToken, getRefreshToken, setIdToken } from "@/utils/localStorage";
import { useMongoContext } from "@/app/common/provider/MongoProvider";

export default function App() {
  const { app } = useMongoContext();

  useEffect(() => {
    const refreshToken = getRefreshToken();
    const idToken = getIdToken();
    const accesToken = getAccessToken();
    if (!app.currentUser?.isLoggedIn) window.location.replace("/welcome");
    if (!refreshToken || !idToken || !accesToken) window.location.replace("/welcome");
  }, []);
  return (
    <>
      <Box flexDirection="column" height="100vh" justifyContent="center" alignItems="center" width="100vw">
        <div className="background-mountain-cofidoc" />
        <div className="background-cloud height40" />
        <div className="background-over" />
        <Header />
        <Box flexDirection="row" width="100%" height="calc(100% - 6vh)" justifyContent="center" alignItems="center">
          <LeftNavBar />

          <Box flexDirection="column" width="calc(100% - 256px)" height="100%" alignItems="center" className="overBg" style={{ overflowY: "scroll" }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
}
