import { createContext, useContext } from "react";

type AppProviderType = {
  loading: boolean;
  setLoading: any;
  periodeStart: Date;
  setPeriodeStart: React.Dispatch<React.SetStateAction<Date>>;
  periodeEnd: Date;
  setPeriodeEnd: React.Dispatch<React.SetStateAction<Date>>;
};
const appContext = createContext<AppProviderType | undefined>(undefined);

export const AppProvider = appContext.Provider;

export function useAppContext() {
  const c = useContext(appContext);
  if (!c) throw new Error("Can't use useApp outside of AppProvider");
  return c;
}
