import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./image.css";
import AppSelector from "./app/AppSelector";
import { ApplicationProvider } from "./app/common/provider/ApplicationContext";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ApplicationProvider>
      <AppSelector />
    </ApplicationProvider>
  </React.StrictMode>
);

reportWebVitals();