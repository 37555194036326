import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { Box, Button, Card, Text } from "@/ui";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { format, addMonths, getYear } from "date-fns";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Schedule = ({ contract }) => {
  const { client, user } = useMongoContext();
  const [period, setPeriod] = useState<Date[]>([]);
  const theme = useTheme();
  const [schedules, setSchedules] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  // Generate schedule data based on contract details
  const generateSchedules = () => {
    const startDate = new Date(contract.subscriptionDate);
    const endDate = addMonths(startDate, contract.commitmentDuration);
    const monthlyAmount = contract.productBase;
    const schedules = [];

    for (let date = startDate; date <= endDate; date = addMonths(date, 1)) {
      schedules.push({
        month: format(date, "MMMM"),
        amount: monthlyAmount,
        year: date.getFullYear(),
        isExpired: date < new Date(),
      });
    }

    setSchedules(schedules);
    setPeriod([startDate, endDate]);
  };

  useEffect(() => {
    if (user && client && contract) {
      generateSchedules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client, contract]);

  const NavigationBar = () => {
    const startYear = getYear(period[0]);
    const endYear = getYear(period[1]);

    return (
      <Card display="flex" justifyContent="space-between" alignItems="center" width="100%" p={2}>
        <Button onClick={() => setSelectedYear(selectedYear - 1)} disabled={selectedYear <= startYear}>
          <ArrowBackIcon />
        </Button>
        <Text>Année {selectedYear}</Text>
        <Button onClick={() => setSelectedYear(selectedYear + 1)} disabled={selectedYear >= endYear}>
          <ArrowForwardIcon />
        </Button>
      </Card>
    );
  };

  const filteredSchedules = schedules.filter(schedule => schedule.year === selectedYear);

  return (
    <>
      <NavigationBar />
      <Box flexDirection="column" width="100%" mt={2}>
        <Box display="flex" flexDirection="row" p={2} style={{ backgroundColor: theme.palette.grey[200] }}>
          <Text flex={1} fontWeight="bold">Mois</Text>
          <Text flex={1} fontWeight="bold">Montant</Text>
        </Box>
        {filteredSchedules.length > 0 ? (
          filteredSchedules.map((schedule, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="row"
              p={2}
              style={{
                backgroundColor: schedule.isExpired ? "white": theme.palette.grey[300],
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
              }}
            >
              <Text flex={1}>{schedule.month}</Text>
              <Text flex={1}>{schedule.amount}€</Text>
            </Box>
          ))
        ) : (
          <Box p={2}>
            <Text>Aucun montant pour cette année.</Text>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Schedule;