import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { Box, Button, Card, Text } from "@/ui";
import { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { useTheme } from "@mui/material";
import { addYears, format, subYears } from "date-fns";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CofidocBilling, fetchCofidocBilling } from "@/api/nurse/fetchCofidocBilling";
import { getBlob } from "@/api/nurse/fetchDownloadURL";
import { saveAs } from "file-saver";
import DownloadIcon from "@mui/icons-material/Download";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

export default function Billing() {
  const { client, user } = useMongoContext();
  const [period, setPeriod] = useState<Date>(new Date());
  const [billing, setBilling] = useState<CofidocBilling[]>();

  // Get data function
  async function getData() {
    try {
      const b = await fetchCofidocBilling();
      b.sort((a, b) => a.date.getTime() - b.date.getTime());
      const filteredInvoices = b.filter((invoice) => invoice.date.getFullYear() === period.getFullYear());
      setBilling(filteredInvoices);
    } catch (error) {
      console.log("error :", error);
    } finally {
    }
  }

  useEffect(() => {
    if (user && client) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, user, client]);

  const NavigationBar = () => {
    return (
      <Card display="flex" justifyContent="space-between" alignItems="center" width="100%" p={2}>
        <Button onClick={() => setPeriod(subYears(period, 1))}>
          <ArrowBackIcon />
        </Button>
        <Text>Année {format(period, "yyyy")}</Text>
        <Button disabled={period.getFullYear() === new Date().getFullYear()} onClick={() => setPeriod(addYears(period, 1))}>
          <ArrowForwardIcon />
        </Button>
      </Card>
    );
  };

  return (
    <>
      <NavigationBar />
      <Box flexDirection="row" width="100%" height="100%" flexWrap="wrap">
        {billing?.map((invoice) => {
          return <InvoiceItem key={invoice?.id?.toString()} invoice={invoice} user={user} />;
        })}
      </Box>
    </>
  );
}

const InvoiceItem = ({ invoice, user }: { invoice: CofidocBilling, user: any }) => {
  const theme = useTheme();
  const adeli = user?.customData?.adeli;

  const downloadInvoicesByMonth = async (invoice: CofidocBilling) => {
    try {
      // http://facturation.palawa.fr/api/v1/facture/{id}?adeli={adeli}
      const urlInvoice = `http://facturation.palawa.fr/api/v1/facture/${invoice.id}?adeli=${adeli}`;
      console.log("urlInvoice", urlInvoice);
      const pdfBlob = await getBlob(urlInvoice);
      if (pdfBlob === undefined) {
        console.error("Error downloading invoice: pdfBlob is undefined");
        return;
      }
      const fileName = `Facture_${format(invoice?.date, "dd_MM_yyyy")}.pdf`;
      saveAs(pdfBlob, fileName);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };

  return (
    <Box
      p={2}
      m={2}
      flexDirection="column"
      position="relative"
      alignItems="center"
      justifyContent="center"
      style={{ cursor: "pointer" }}
      onClick={() => downloadInvoicesByMonth(invoice)}
    >
      <InsertDriveFileIcon style={{ position: "absolute",  zIndex: 0, color: theme.palette.grey[200], fontSize: 150 }} />
      <InsertDriveFileIcon style={{ zIndex: 1, color: theme.palette.grey[100], fontSize: 140 }} />
      <Box zIndex={1} p={2} style={{ position: "absolute", zIndex: 2 }} flexDirection="column" alignItems="center" justifyContent="center">
        <DownloadIcon style={{ color: `${theme.palette.primary.main}80`, fontSize: 35 }} />
        <Text fontSize={14} fontWeight="500">{format(invoice?.date, "dd MMMM")}</Text>
      </Box>
    </Box>
  );
};
