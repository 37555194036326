import { Formik, Form } from "formik";
import { Box, Text, Button, Link } from "../../../../ui";
import { Card } from "../../../../ui/Card";
import { TextField } from "../../../../ui/field/TextField";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { fetchForgotPassword, fetchResetPassword } from "@/api/nurse/fetchAuth";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";

export default function ForgotPasswordView() {
  const { app } = useMongoContext();
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { application } = useApplicationContext();
  const isInovie = process.env.REACT_APP_INOVIE_DEL;

  return (
    <Box height="100vh" justifyContent="center" alignItems="center" width="100vw" style={{ backgroundColor: "#f1f1f1" }}>
      <div className="background-cloud height40" />
      <div className="background-over" />
      {isInovie ? <div className="background-mountain-inovie" /> : <div className="background-mountain-cofidoc" />}
      <Box style={{ position: "relative", zIndex: 2 }}>
        <Formik
          initialValues={step === 1 ? { email: "" } : { code: "", newPassword: "" }}
          onSubmit={async (values) => {
            try {
              setError(null);
              if (step === 1) {
                await fetchForgotPassword({
                  email: values.email,
                  realmApp: app,
                  application,
                });
                setStep(2);
              } else {
                await fetchResetPassword({
                  email: values.email,
                  code: values.code,
                  password: values.newPassword,
                  realmApp: app,
                  application,
                });
                navigate("/nurse/login");
              }
            } catch (e) {
              console.log("error", e);
              setError(e?.message);
            }
          }}
        >
          <Form>
            <Card width={456} display="flex" flexDirection="column">
              <Box display="flex" alignItems="left">
                <Link onClick={() => navigate("/welcome")}>
                  <ArrowBackIcon fontSize="small" />
                  <Box pr={1} />
                  <Text style={{ textAlign: "center", fontSize: 12 }}>Retour au portail de connexion</Text>
                </Link>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Box display="flex" p={2} alignItems="center" justifyContent="center">
                  {isInovie ? (
                    <div className="background-logo-inovidel" style={{ borderRadius: "10px", overflow: "hidden", height: "150px", width: "150px" }} />
                  ) : (
                    <div className="background-logo-mobility" style={{ borderRadius: "10px", overflow: "hidden", height: "100px", width: "100px" }} />
                  )}
                </Box>
                <Box style={{ margin: 20, textAlign: "center", fontWeight: 600 }}>
                  <Text style={{ textAlign: "center", fontWeight: 600 }}>
                    {step === 1 ? "Réinitialiser le mot de passe" : "Entrez le code et le nouveau mot de passe"}
                  </Text>
                </Box>
                {step === 1 ? (
                  <TextField label="Email" name="email"   />
                ) : (
                  <>
                    <TextField label="Code" name="code"   />
                    <TextField type="password" label="Nouveau mot de passe" name="newPassword"   />
                  </>
                )}
                <Box justifyContent="center" alignItems="center" height={60}>
                  {error && (
                    <Box fontSize={11} color="#ff0000">
                      {error}
                    </Box>
                  )}
                </Box>
                <Box flex={1} justifyContent="center" mt={3}>
                  <Button type="submit">{step === 1 ? "Envoyer" : "Valider"}</Button>
                </Box>
                <Box justifyContent="center" alignItems="center" height={60}>
                  <Link onClick={() => navigate("/nurse/login")}>
                    <Text style={{ textAlign: "center", fontSize: 12 }}>Retour à la page de connexion</Text>
                  </Link>
                </Box>
              </Box>
            </Card>
          </Form>
        </Formik>
      </Box>
      <Box style={{ position: "absolute", bottom: 0, zIndex: 3, padding: 5 }}>
        <Text style={{ textAlign: "center", fontSize: 12 }}>© 2024 {!isInovie && "Cofidoc Groupe"}</Text>
      </Box>
    </Box>
  );
}
