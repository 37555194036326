/* eslint-disable react-hooks/exhaustive-deps */

import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Text, NavLink } from "../../ui";
import { useMongoContext } from "../common/provider/MongoProvider";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import qs from "query-string";
import { useTheme } from "@mui/material";

export function Header() {
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      height="6vh"
      zIndex={9}
      className="overBg"
    >
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="10vw"
          mr={2}
          pt={1}
        >
          <div className="logo-cofidoc" style={{ height: "100%" }} />
        </Box>
      </Box>
      <Box p={2}>
        <Profile />
      </Box>
    </Box>
  );
}

export function LeftNavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const navigationRefund = () => {
    const period = new Date(new Date().setDate(new Date().getDate()));
    period.setHours(0, 0);
    let to = qs.stringify(
      {
        period: period.getTime(),
      },
      { arrayFormat: "index" }
    );
    navigate(`/taxi/app/refund?${to}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="256px"
      height="100%"
      zIndex={10}
      className="overBg"
      pt="2vh"
      alignItems="center"
    >
      <NavLink
        onClick={() => navigate("/taxi/app/home")}
        selected={location.pathname.includes("home")}
      >
        <DashboardIcon
          fontSize="small"
          style={{ color: theme.colors.dark as any }}
        />
        <Box pr={1} />
        <Text m={0}>Tableau de bord</Text>
      </NavLink>
      <NavLink
        onClick={() => navigate("/taxi/app/patient")}
        selected={location.pathname.includes("patient")}
      >
        <PersonIcon
          fontSize="small"
          style={{ color: theme.colors.dark as any }}
        />
        <Box pr={1} />
        <Text m={0}>Patients</Text>
      </NavLink>
      <NavLink
        onClick={() => navigate("/taxi/app/activity")}
        selected={location.pathname.includes("activity")}
      >
        <AssessmentIcon
          fontSize="small"
          style={{ color: theme.colors.dark as any }}
        />
        <Box pr={1} />
        <Text m={0}>Envois</Text>
      </NavLink>
      <NavLink
        onClick={navigationRefund}
        selected={location.pathname.includes("refund")}
      >
        <AccountBalanceIcon
          fontSize="small"
          style={{ color: theme.colors.dark as any }}
        />
        <Box pr={1} />
        <Text m={0}>Remboursement</Text>
      </NavLink>
    </Box>
  );
}

function Profile() {
  const { app }: any = useMongoContext();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      {location.pathname.includes("refund") && (
        <Box justifyContent="center" alignItems="center" pr={3}>
          <Button
            // Aller sur le site espace-client.cofidoc.fr
            onClick={() => window.open("https://espace-client.cofidoc.fr")}
          >
            Accéder à mon ancien espace
          </Button>
        </Box>
      )}
      <Box justifyContent="center" alignItems="center" pr={3}>
        <Button
          onClick={async () => {
            await app?.currentUser?.logOut();
            navigate("/welcome");
          }}
        >
          Déconnexion
        </Button>
      </Box>
    </>
  );
}
