import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { Box, Href, Text } from "@/ui";
import { useRef } from "react";
import "react-calendar/dist/Calendar.css";
import { useTheme } from "@mui/material";
import DataGraph from "./DataGraph";
import Contract from "./Contract";
import Billing from "./Billing";

export default function HomeView() {
  const theme = useTheme();
  const { user }: any = useMongoContext();
  const scrollRef = useRef(null);

  return (
    <Box
      flexDirection="column"
      width="calc(100%)"
      height="100%"
      justifyContent="flex-start"
      alignItems="center"
      className="overBg"
      style={{ overflowY: "scroll" }}
      id="scrollContainer"
    >
      <div
        ref={scrollRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Welcome */}
        <Box
          flexDirection="column"
          width="98%"
          alignItems="center"
          mb={5}
          style={{ backgroundColor: "#fffffff6", borderRadius: 5 }}
        >
          <Box
            width="100%"
            position="relative"
            flexDirection="column"
            px={5}
            pt={5}
          >
            <Box style={{ fontSize: 40 }}>
              <Text style={{ fontSize: 40, fontWeight: 200 }}>Bonjour</Text>
              &nbsp;
              <Text
                style={{
                  fontSize: 40,
                  fontWeight: 500,

                  color: theme.colors.primary,
                }}
              ></Text>
            </Box>
          </Box>
          <Box width="100%" justifyContent="space-between" p={3}>
            <Box width="65%" p={3} flexDirection="column">
              <Box flexDirection="column">
                <Text fontSize={25} fontWeight="300">
                  Mon activité
                </Text>
                <Box flexDirection="column">
                  <DataGraph />
                </Box>
              </Box>
              <Box p={2} />
              <Box flexDirection="column">
                <Text fontSize={25} fontWeight="300">
                  Mes factures cofidoc
                </Text>
                <Box flexDirection="column">
                  <Billing />
                </Box>
              </Box>
            </Box>
            <Box width="30%" p={3} flexDirection="column">
              <Box flexDirection="column">
                <Text fontSize={25} fontWeight="300">
                  Mes contrats
                </Text>
                <Contract />
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Notre métier */}
        <Box
          flexDirection="column"
          width="98%"
          justifyContent="center"
          alignItems="center"
          mb={5}
          style={{ backgroundColor: "#fffffff6", borderRadius: 5 }}
          minHeight="500px"
        >
          <Box
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            position="relative"
            flexDirection="column"
            pl={4}
            pr={4}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <Box width="50%" height="100%">
                <div className="image-cofidoc-philippe-soual" />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="50%"
              >
                <Box fontSize="60px">
                  <Text fontSize={"30px"} style={{ fontWeight: 200 }}>
                    L'univers
                  </Text>
                  &nbsp;
                  <Text
                    style={{
                      fontWeight: 600,
                      fontSize: "30px",
                      color: theme.colors.primary,
                    }}
                  >
                    Cofidoc
                  </Text>
                </Box>
                <Box p={2} />
                <Text textAlign="left">
                  Depuis 1998, Philippe Soual dirige l'entreprise Cofidoc. Cet
                  infirmier de formation propose aux professionnels de santé de
                  les décharger des tâches improductives dans le but de
                  permettre à ces professionnels de mieux se concentrer sur leur
                  mission de soignant et d'accompagnant. La mission de
                  l'entreprise est de gérer entièrement les flux de données
                  entre le professionnel et les organismes payeurs évitant tout
                  à la fois les impayés et les erreurs. Aujourd'hui,
                  l'entreprise se compose de 34 agences en France
                  Métropolitaine, aux Antilles et à La Réunion. Avec plus de
                  2000 clients sur l'ensemble du territoire, Cofidoc est là pour
                  vous accompagner et s'occuper de votre facturation.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Notre metier au service du votre  */}
        <Box
          flexDirection="column"
          width="98%"
          justifyContent="center"
          alignItems="center"
          mb={5}
          style={{ backgroundColor: "#fffffff6", borderRadius: 5 }}
        >
          <Box
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            position="relative"
            flexDirection="column"
            pl={4}
            pr={4}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="50%"
              >
                <Box style={{ fontSize: 70 }}>
                  <Text style={{ fontSize: 70, fontWeight: 200 }}>Notre</Text>
                  &nbsp;
                  <Text
                    style={{
                      fontSize: 70,
                      fontWeight: 500,

                      color: theme.colors.primary,
                    }}
                  >
                    métier
                  </Text>
                </Box>

                <Text
                  style={{
                    fontSize: 48,
                    fontWeight: 200,

                    color: theme.colors.primary,
                  }}
                >
                  Au service du vôtre
                </Text>
                <Box p={2} />
                <Text textAlign="left">
                  C'est aujourd'hui presque 100 gestionnaires en données de
                  santé qui accompagnent nos clients au quotidien dans leur
                  facturation.
                </Text>
                <Text textAlign="left">
                  Le coeur de notre métier consiste à effectuer la facturation
                  et la télétransmission des feuilles de soins ou bons de
                  transport aux organismes payeurs pour le compte de
                  professionnels de santé libéraux (infirmiers,
                  kinésithérapeutes, orthophonistes, sage-femmes) et de
                  transporteurs sanitaires (taxis, ambulanciers).
                </Text>
                <Text textAlign="left">
                  Notre rôle est également de guider pas à pas les
                  professionnels libéraux dans leur quotidien en les conseillant
                  sur la NGAP.
                </Text>
                <Text textAlign="left">
                  À l'écoute et disponibles pour nos clients, nos équipes se
                  font un réel plaisir de vous accueillir dans chacune de nos
                  agences afin de vous présenter nos offres.
                </Text>
                <Box m={3}>
                  <Href
                    target="blank"
                    href="https://www.cofidoc.fr/les-avantages/"
                  >
                    Voir tous les avantages
                  </Href>
                </Box>
              </Box>
              <Box width="50%" height="100%">
                <div className="image-cofidoc-metier" />
              </Box>
            </Box>
          </Box>
        </Box>
        {/* 3 phrases */}
        <Box
          flexDirection="column"
          width="98%"
          justifyContent="center"
          alignItems="center"
          mb={5}
          style={{ backgroundColor: "#fffffff6", borderRadius: 5 }}
          minHeight="500px"
        >
          <Box
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            position="relative"
            flexDirection="row"
            pl={4}
            pr={4}
          >
            <Box
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              width="30%"
            >
              <Text
                textAlign="center"
                style={{
                  fontSize: 30,
                  fontWeight: 200,

                  color: theme.colors.primary,
                }}
              >
                Performance économique
              </Text>
              <Box p={1} />
              <Text textAlign="center">
                La RSE s'intéresse aussi au développement de l'innovation, pour
                cette raison le Groupe a travaillé sur la refonte de son système
                informatique ainsi que sur le développement de ses applications
                mobiles afin de proposer des outils performants et actuels.
              </Text>
            </Box>
            <Box
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              width="30%"
            >
              <Text
                textAlign="center"
                style={{
                  fontSize: 30,
                  fontWeight: 200,

                  color: theme.colors.primary,
                }}
              >
                Responsabilité environnementale
              </Text>
              <Box p={1} />
              <Text textAlign="center">
                L'ensemble des agences du Groupe mettent en place des actions
                afin de préserver notre environnement comme le recyclage du
                papier. Cofidoc est également partenaire avec la société
                EcoTree, entreprise investie dans le secteur de l'écologie et la
                préservation de notre nature.
              </Text>
            </Box>
            <Box
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              width="30%"
            >
              <Text
                textAlign="center"
                style={{
                  fontSize: 30,
                  fontWeight: 200,

                  color: theme.colors.primary,
                }}
              >
                Impact sociétal
              </Text>
              <Box p={1} />
              <Text textAlign="center">
                L'humain est au coeur de nos préoccupations chez Cofidoc, nous
                mettons en place une écoute active de nos équipes afin d'assurer
                un équilibre en la vie professionnelle et personnelle. Nous
                développons des actions en lien avec la qualité de vie au
                travail en proposant aux employés de nombreux avantages.
              </Text>
            </Box>
          </Box>
        </Box>
        <Box p={2} />
      </div>
    </Box>
  );
}
