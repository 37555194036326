import { Box, Text } from "@/ui";
import { InputLabel, useTheme } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Spinner } from "@/ui/Spinner";
import { useGetURI } from "@/utils/useGetUri";
import { Patient, Transmission } from "@/types/nurse";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { useMongoContext } from "@/app/common/provider/MongoProvider";

export function RenderTransmission({ patient }: { patient: Patient }) {
  const [transmissions, setTransmissions] = useState([]);
  const { dbName } = useApplicationContext();
  const { client } = useMongoContext();

  async function getData() {
    try {
      const transmissionCollection = client.db(dbName).collection("Transmission");
      const resTransmission = await transmissionCollection.find({ deleted: false, idPatient: patient._id });
      setTransmissions(resTransmission);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    getData();
  }, [patient?._id]);

  return (
    <Box flexDirection="column" style={{ overflowY: "scroll", overflowX: "hidden" }}>
      {transmissions?.map((item) => {
        return <Box p={2}>{item?._id}</Box>;
      })}
    </Box>
  );
}
