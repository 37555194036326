import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export function Root() {
  const { application } = useApplicationContext();
  const { app } = useMongoContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.pathname === "/") {
      if (app?.currentUser?.isLoggedIn) {
        if (application === "taxi") {
          navigate("/taxi/app/home");
        }
        if (application === "inovidel" || application === "mobility") {
          navigate("/nurse/app/home");
        }
      } else {
        navigate("/welcome");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Outlet />
    </>
  );
}
