import { ApplicationType } from "@/types/common";
import { fetchAPI } from "@/utils/fetchAPI";

type FetchSendDocument = {
  version: string;
  seanceIds: string[];
  officeId: string;
  comment: string;
  realmApp: any;
  application: ApplicationType;
};

export async function fetchSendDocument({ version, seanceIds, officeId, comment, realmApp, application }: FetchSendDocument) {
  try {
    const res = await fetchAPI({
      url: `/${application}/billing/syncSeanceToS3`,
      options: {
        method: "POST",
        body: JSON.stringify({ version, seanceIds, officeId, comment }),
      },
      realmApp,
      application,
    });
    console.log(" res:", res);
    return res;
  } catch (error) {
    throw new Error();
  }
}
