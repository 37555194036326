import { Box, Button, Text } from "@/ui";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Office, Patient, UserWithRoleInfo } from "@/types/nurse";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useOfficeContext } from "@/app/nurse/provider/OfficeProvider";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { format } from "@/utils/format";
import { useQueryParams } from "@/utils/useQueryParams";
import qs from "query-string";
import { countBy, uniqBy } from "lodash";
import { useAppContext } from "@/app/common/provider/AppProvider";
import Select from "react-select";
import { ModalConfirmDone } from "../../../../../../components/nurse/ModalUpdateSeance";
import toast from "react-hot-toast";
import { add, eachDayOfInterval } from "date-fns";
import PersonIcon from "@mui/icons-material/Person";
import {
  getRequestFilter,
  getRequestFilterToUpdate,
} from "../../../../../../utils/getRequestFilter";
import { useTheme } from "@mui/material";
import { gradient } from "@/theme/ThemeProvider";

const statusOptionsClient = [
  { label: "Tous les statuts", value: null },
  { label: "Séances à faire", value: "to-do" },
  { label: "Séances à envoyer", value: "done" },
  { label: "Séances envoyées", value: "ready-to-bill" },
];

const colors = [
  "#3288bd",
  "#d53e4f",
  "#98a361",
  "#abdda4",
  "#fdae61",
  "#f46d43",
  "#a598f5",
  "#66c2a5",
  "#d53ea0",
  "#3ed5b0",
  "#6ed53e",
  "#fb7f7f",
];

export default function AgendaView() {
  const { dbName } = useApplicationContext();
  const { client, user } = useMongoContext();
  const { currentOfficeInfo } = useOfficeContext();
  const { setLoading, loading } = useAppContext();
  let statusFiltered = statusOptionsClient;
  const params = useQueryParams();
  const theme = useTheme();
  const [patients, setPatients] = useState<any>([]);
  const [seancesCountbyPatient, setSeanceCountByPatient] = useState<any>({});
  const [praticiens, setPraticiens] = useState<
    (UserWithRoleInfo & { color: string })[]
  >([]);

  const [selectedPatient, setSelectedPatient] = useState<Patient>(null);
  const [needRefresh, setNeedRefresh] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  async function getDataSeances() {
    try {
      if (!currentOfficeInfo) {
        let to = qs.stringify({ ...params }, { arrayFormat: "index" });
        navigate(`/nurse/app/offices?${to}`);
      }
      setLoading(true);

      const officeCollection = client.db(dbName).collection("Office");
      const patientCollection = client.db(dbName).collection("Patient");
      let patients = await patientCollection.find({ deleted: false });

      if (!patients?.find((p) => p?._id === selectedPatient?._id)) {
        const patienZero = patients?.sort((a, b) =>
          String(a?.lastname)
            .toLowerCase()
            .trim()
            .localeCompare(String(b?.lastname).toLowerCase().trim())
        )[0];
        if (patienZero) {
          setSelectedPatient(patienZero);
          let to = qs.stringify({ ...params }, { arrayFormat: "index" });
          navigate(
            `/nurse/app/offices/${currentOfficeInfo?.officeId}/patient/${patienZero?._id}?${to}`,
            { replace: true }
          );
        }
      }

      setPatients(patients);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }

  // USE EFFECT TO GET SEANCE COUNT
  useEffect(() => {
    if (user && client && currentOfficeInfo) {
      getDataSeances();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.status, params.collaborator, user, client, currentOfficeInfo]);

  // USE EFFECT TO GET SEANCE COUNT ON NEED REFRESH DATA
  useEffect(() => {
    if (user && client && needRefresh) {
      getDataSeances();
      setNeedRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client, needRefresh]);

  // USE EFFECT CHECK IF CURRENT SELECTED PATIENT AND URL PATH ARE CORRECT
  useEffect(() => {
    const patientIdUrl = location?.pathname.split("patient/")[1];

    if (
      ((!loading &&
        user &&
        client &&
        selectedPatient &&
        !patients[selectedPatient?._id] &&
        patientIdUrl !== selectedPatient?._id) ||
        (!loading && user && client && !selectedPatient && patientIdUrl)) &&
      currentOfficeInfo?.officeId
    ) {
      setSelectedPatient(null);
      let to = qs.stringify({ ...params }, { arrayFormat: "index" });
      navigate(
        `/nurse/app/offices/${currentOfficeInfo?.officeId}/patient?${to}`,
        {
          replace: true,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client, loading, location, currentOfficeInfo]);

  const collaboratorSet = uniqBy(
    praticiens.map((c) => ({
      label: c.userFirstName + " " + c.userLastName,
      value: { id: c.userId, color: c.color },
    })),
    "value"
  );

  const collaboratorValue = [
    { label: "Tout le cabinet", value: null },
    ...collaboratorSet,
  ];

  return (
    <Box
      flexDirection="column"
      width="98%"
      height="98%"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: "#fffffff6", borderRadius: 5 }}
    >
      <Box
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="60px"
        position="relative"
      >
        <Box
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          position="relative"
          borderBottom={`solid 1px ${theme.colors.light}`}
        >
          <Box justifyContent="center" alignItems="center">
            <Text fontWeight={300} fontSize={26} pl={3}>
              Mes patients
            </Text>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          width: "100%",
          height: "calc(100% - 60px)",
          position: "relative",
        }}
        justifyContent="center"
        alignItems="center"
      >
        {!Object.keys(patients).length && !loading && (
          <Box
            style={{ position: "absolute" }}
            width="50%"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              pb={1}
              fontSize={20}
              fontWeight="300"
              flexDirection="row"
              flexWrap="wrap"
              textAlign="center"
              justifyContent="center"
              alignItems="center"
            >
              Aucun patient
              {params?.collaborator && (
                <span>
                  &nbsp;de&nbsp;
                  <span
                    style={{
                      fontWeight: 600,
                      color: theme.colors.primary as any,
                    }}
                  >
                    {
                      collaboratorValue.find(
                        (c) => c?.value?.id === params?.collaborator
                      )?.label
                    }
                  </span>
                </span>
              )}
              {params?.status && (
                <span>
                  &nbsp;pour les&nbsp;
                  <span
                    style={{
                      fontWeight: 600,
                      color: theme.colors.primary as any,
                    }}
                  >
                    {
                      statusFiltered.find((c) => c?.value === params?.status)
                        ?.label
                    }
                  </span>
                </span>
              )}
              {params?.start && (
                <>
                  &nbsp;sur la période du&nbsp;
                  <span
                    style={{
                      fontWeight: 600,
                      color: theme.colors.primary as any,
                    }}
                  >
                    {format(
                      new Date(Number(params.start)),
                      "EEEE dd MMMM yyyy"
                    )}
                  </span>
                </>
              )}
              {params?.end && (
                <>
                  &nbsp;au&nbsp;
                  <span
                    style={{
                      fontWeight: 600,
                      color: theme.colors.primary as any,
                    }}
                  >
                    {format(new Date(Number(params.end)), "EEEE dd MMMM yyyy")}
                  </span>
                </>
              )}
            </Box>
          </Box>
        )}
        {Object.keys(patients).length > 0 && (
          <>
            <Box
              flexDirection="column"
              width="20%"
              pr={2}
              pl={2}
              style={{ overflowY: "scroll" }}
              height="100%"
            >
              {patients
                //@ts-ignore
                ?.sort((a, b) =>
                  String(a?.lastname)
                    .toLowerCase()
                    .trim()
                    .localeCompare(String(b?.lastname).toLowerCase().trim())
                )
                ?.map((patient, index) => {
                  if (
                    location.pathname.includes(`${patient?._id}`) &&
                    !location.pathname.includes(`${selectedPatient?._id}`)
                  ) {
                    setSelectedPatient(patient);
                    let to = qs.stringify(
                      { ...params },
                      { arrayFormat: "index" }
                    );
                    navigate(`${patient?._id}?${to}`, { replace: true });
                  }
                  return (
                    <Box
                      onClick={() => {
                        setSelectedPatient(patient);
                        let to = qs.stringify(
                          { ...params },
                          { arrayFormat: "index" }
                        );
                        navigate(`${patient?._id}?${to}`, { replace: true });
                      }}
                      key={patient._id}
                      alignItems="center"
                      justifyContent="space-between"
                      style={{
                        margin: 5,
                        width: "90%",
                        borderRadius: 25,
                        paddingLeft: 15,
                        minHeight: 35,
                        cursor: "pointer",
                        position: "relative",
                        backgroundColor:
                          selectedPatient?._id === patient._id
                            ? theme.colors.light?.toString()
                            : "#f5f5f5",
                      }}
                    >
                      <Box
                        bgcolor="#fff"
                        width={25}
                        height={25}
                        borderRadius={50}
                        mr={1}
                      >
                        <Box
                          width={25}
                          height={25}
                          borderRadius={50}
                          bgcolor={`${
                            theme.colors.gradient[
                              String(index % 8) as keyof gradient
                            ]
                          }30`}
                          border={`1px solid ${
                            theme.colors.gradient[
                              String(index % 8) as keyof gradient
                            ]
                          }`}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <PersonIcon
                            style={{
                              color:
                                theme.colors.gradient[
                                  String(index % 8) as keyof gradient
                                ],
                              fontSize: 18,
                            }}
                          />
                        </Box>
                      </Box>
                      <Text
                        display="block"
                        fontWeight="400"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        width="100%"
                      >
                        {patient.lastname} {patient.firstname}
                      </Text>
                    </Box>
                  );
                })}
            </Box>
            <Box width="80%" pl={2} height="100%">
              {selectedPatient && (
                <OutletPatient
                  patient={selectedPatient}
                  praticiens={praticiens}
                  setNeedRefresh={setNeedRefresh}
                  needRefresh={needRefresh}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

function OutletPatient({
  patient,
  praticiens,
  setNeedRefresh,
  needRefresh,
}: {
  patient: Patient;
  praticiens: (UserWithRoleInfo & { color: string })[];
  setNeedRefresh: any;
  needRefresh: boolean;
}) {
  return (
    <Outlet context={{ patient, praticiens, setNeedRefresh, needRefresh }} />
  );
}
