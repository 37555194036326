import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import { Box } from "./Box";

const StyledBox = styled(Box)<any>``;

export function Link({ onClick, children }: any) {
    const theme = useTheme();
    return (
      <StyledBox
        onClick={onClick}
        mb={2}
        p="4px"
        pl={1}
        color={theme.colors.primary}
        style={{
          cursor: "pointer",
        }}
        alignItems="center"
      >
        {children}
      </StyledBox>
    );
  }