/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@/ui";
import { useEffect, useState } from "react";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { Care, Patient, Prescription } from "@/types/nurse";
import { PrescriptionItem } from "@/components/nurse/PrescriptionItem";

export function RenderPrescription({ patient }: { patient: Patient }) {
  const [items, setItems] = useState<{ prescription: Prescription; cares: Care[] }[]>([]);
  const { dbName } = useApplicationContext();
  const { client } = useMongoContext();

  async function getData() {
    try {
      const prescriptionCollection = client.db(dbName).collection("Prescription");
      const careCollection = client.db(dbName).collection("Care");

      const resPrescription = (await prescriptionCollection.find(
        { deleted: false, patient: patient._id },
        { sort: { prescriptionDate: -1 } }
      )) as Prescription[];
      const resCare = (await careCollection.find({ deleted: false, patient: patient._id })) as Care[];

      const careGroupByPrescription = resPrescription.map((prescription) => {
        return {
          prescription,
          cares: resCare.filter((care) => care.prescription === prescription._id),
        };
      });

      setItems(careGroupByPrescription);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    getData();
  }, [patient?._id]);

  return (
    <Box flexDirection="column" style={{ overflowY: "scroll", overflowX: "hidden" }} p={1}>
      {items?.map((item) => {
        return <PrescriptionItem {...item} />;
      })}
    </Box>
  );
}
