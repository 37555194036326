export type ProductName = "Forfait avec Engagement" | "Gestion+ avec Engagement" | "Saisie PREMIER" | "Gestion+" | "eCofiCoffre";

type PalawaObject = {
  DateAdhesion: string;
  DateRadiation: string;
  Police: string;
  Produit: ProductName;
  Base: number;
  QuantiteTaux: number;
  DureeEngagement: number;
  CAAnnuel: number;
  NumTranche: number;
  CAAnnuelMin: number;
  CAAnnuelMax: number;
  CAMensuelMin: number;
  CAMensuelMax: number;
};

export type CofidocContract = {
  productName?: ProductName;
  productBase?: number;
  percentage?: number;
  active?: boolean;
  subscriptionDate?: Date;
  terminationDate?: Date;
  commitmentDuration?: number;
  annualTurnover?: number;
  annualTurnoverMin?: number;
  annualTurnoverMax?: number;
  monthlyTurnoverMin?: number;
  monthlyTurnoverMax?: number;
};

function proxiApiPalawa(objects: PalawaObject[]): CofidocContract[] {
  const mapped = objects.map((o) => {
    return {
      productName: o.Produit,
      productBase: o.Base,
      percentage: o.QuantiteTaux,
      active: o.DateRadiation === "0000-00-00",
      subscriptionDate: new Date(o.DateAdhesion),
      terminationDate: o.DateRadiation !== "0000-00-00" ? new Date(o.DateRadiation) : undefined,
      commitmentDuration: o.DureeEngagement,
      annualTurnover: o.CAAnnuel,
      annualTurnoverMin: o.CAAnnuelMin,
      annualTurnoverMax: o.CAAnnuelMax,
      monthlyTurnoverMin: o.CAMensuelMin,
      monthlyTurnoverMax: o.CAMensuelMax,
    };
  });
  return mapped;
}

export async function fetchCofidocContracts() {
  try {
    return proxiApiPalawa(data1 as PalawaObject[]);
  } catch (error) {
    throw new Error();
  }
}

const data1 = [
  {
    DateAdhesion: "2024-05-01",
    DateRadiation: "0000-00-00",
    Police: "CT00186759",
    Produit: "Forfait avec Engagement",
    Base: 95,
    QuantiteTaux: 1,
    DureeEngagement: 36,
    CAAnnuel: 0,
    NumTranche: 4,
    CAAnnuelMin: 30001,
    CAAnnuelMax: 40000,
    CAMensuelMin: 2500,
    CAMensuelMax: 3333,
  },
  {
    DateAdhesion: "2024-05-01",
    DateRadiation: "0000-00-00",
    Police: "CT00186760",
    Produit: "Gestion+ avec Engagement",
    Base: 15,
    QuantiteTaux: 1,
    DureeEngagement: 36,
    CAAnnuel: 0,
    NumTranche: 4,
    CAAnnuelMin: 30001,
    CAAnnuelMax: 40000,
    CAMensuelMin: 2500,
    CAMensuelMax: 3333,
  },
  {
    DateAdhesion: "2019-11-14",
    DateRadiation: "2024-04-30",
    Police: "CT00183084",
    Produit: "Saisie PREMIER",
    Base: 0,
    QuantiteTaux: 4,
    DureeEngagement: 0,
    CAAnnuel: 0,
    NumTranche: 0,
    CAAnnuelMin: 0,
    CAAnnuelMax: 0,
    CAMensuelMin: 0,
    CAMensuelMax: 0,
  },
  {
    DateAdhesion: "2019-11-14",
    DateRadiation: "2024-04-30",
    Police: "CT00183085",
    Produit: "Gestion+",
    Base: 0,
    QuantiteTaux: 1,
    DureeEngagement: 0,
    CAAnnuel: 0,
    NumTranche: 0,
    CAAnnuelMin: 0,
    CAAnnuelMax: 0,
    CAMensuelMin: 0,
    CAMensuelMax: 0,
  },
];
const data2 = [
  {
    DateAdhesion: "2024-05-01",
    DateRadiation: "0000-00-00",
    Police: "CT00185490",
    Produit: "eCofiCoffre",
    Base: 5.75,
    QuantiteTaux: 1,
    DureeEngagement: 0,
    CAAnnuel: 0,
    NumTranche: 0,
    CAAnnuelMin: 0,
    CAAnnuelMax: 0,
    CAMensuelMin: 0,
    CAMensuelMax: 0,
  },
  {
    DateAdhesion: "2008-12-01",
    DateRadiation: "0000-00-00",
    Police: "CT00180791",
    Produit: "Gestion+",
    Base: 0,
    QuantiteTaux: 1,
    DureeEngagement: 0,
    CAAnnuel: 0,
    NumTranche: 0,
    CAAnnuelMin: 0,
    CAAnnuelMax: 0,
    CAMensuelMin: 0,
    CAMensuelMax: 0,
  },
  {
    DateAdhesion: "2023-03-01",
    DateRadiation: "2024-04-30",
    Police: "CT00180792",
    Produit: "eCofiCoffre",
    Base: 6.9,
    QuantiteTaux: 1,
    DureeEngagement: 0,
    CAAnnuel: 0,
    NumTranche: 0,
    CAAnnuelMin: 0,
    CAAnnuelMax: 0,
    CAMensuelMin: 0,
    CAMensuelMax: 0,
  },
  {
    DateAdhesion: "2010-06-01",
    DateRadiation: "0000-00-00",
    Police: "CT00180793",
    Produit: "Saisie PREMIER",
    Base: 0,
    QuantiteTaux: 3,
    DureeEngagement: 0,
    CAAnnuel: 0,
    NumTranche: 0,
    CAAnnuelMin: 0,
    CAAnnuelMax: 0,
    CAMensuelMin: 0,
    CAMensuelMax: 0,
  },
];

const data3 = [
  {
    DateAdhesion: "2010-06-01",
    DateRadiation: "0000-00-00",
    Police: "CT00180793",
    Produit: "Saisie PREMIER",
    Base: 0,
    QuantiteTaux: 3,
    DureeEngagement: 0,
    CAAnnuel: 0,
    NumTranche: 0,
    CAAnnuelMin: 0,
    CAAnnuelMax: 0,
    CAMensuelMin: 0,
    CAMensuelMax: 0,
  },
];
