import { Box } from "@/ui";

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ height: "calc(100% - 50px)" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box px={5} flexDirection="column" height="100%">
          {children}
        </Box>
      )}
    </div>
  );
}
export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
