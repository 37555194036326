import { ThemeProvider as MaterialThemeProvider, createTheme } from "@mui/material/styles";

export type gradient = {
  "1": string;
  "2": string;
  "3": string;
  "4": string;
  "5": string;
  "6": string;
  "7": string;
  "8": string;
};

declare module "@mui/material/styles" {
  interface Theme {
    colors: {
      seanceToDo: string;
      seanceDone: string;
      seanceReadyToBill: string;
      seancePutOnHold: string;
      seanceBilled: string;
      primary: string;
      light: string;
      dark: string;
      secondary: string;
      lightgray: string;
      background: string;
      bgPale: string;
      text: string;
      seanceStatus: {
        "to-do": string;
        done: string;
        "ready-to-bill": string;
        "put-on-hold": string;
        billed: string;
      };
      blue: { main: string; dark: string; pale: string };
      blueSky: { main: string; dark: string; pale: string };
      green: { main: string; dark: string; pale: string };
      grey: { main: string; light: string };
      magenta: { main: string; dark: string; pale: string };
      orange: { main: string; dark: string; pale: string };
      pink: { main: string; dark: string; pale: string };
      purple: { main: string; dark: string; pale: string };
      purplePink: { main: string; dark: string; pale: string };
      red: { main: string; pale: string };
      white: string;
      yellow: { main: string; pale: string };
      gradient: {
        "0": string;
        "1": string;
        "2": string;
        "3": string;
        "4": string;
        "5": string;
        "6": string;
        "7": string;
        "8": string;
      };
    };
    fonts: {
      body: string;
      heading: string;
      monospace: string;
    };
  }
  interface ThemeOptions {
    colors: {
      seanceToDo: string;
      seanceDone: string;
      seanceReadyToBill: string;
      seancePutOnHold: string;
      seanceBilled: string;
      primary: string;
      light: string;
      dark: string;
      secondary: string;
      lightgray: string;
      background: string;
      bgPale: string;
      text: string;
      seanceStatus: {
        "to-do": string;
        done: string;
        "ready-to-bill": string;
        "put-on-hold": string;
        billed: string;
      };
      blue: { main: string; dark: string; pale: string };
      blueSky: { main: string; dark: string; pale: string };
      green: { main: string; dark: string; pale: string };
      grey: { main: string; light: string };
      magenta: { main: string; dark: string; pale: string };
      orange: { main: string; dark: string; pale: string };
      pink: { main: string; dark: string; pale: string };
      purple: { main: string; dark: string; pale: string };
      purplePink: { main: string; dark: string; pale: string };
      red: { main: string; pale: string };
      white: string;
      yellow: { main: string; pale: string };

      gradient: {
        "0": string;
        "1": string;
        "2": string;
        "3": string;
        "4": string;
        "5": string;
        "6": string;
        "7": string;
        "8": string;
      };
    };
    fonts: {
      body: string;
      heading: string;
      monospace: string;
    };
  }
}

export const colorPalette = {
  blue: { main: "#8ebaef", dark: "#3b6ead", pale: "#d7e4f2" },
  blueSky: { main: "#68d5f2", dark: "#2198b9", pale: "#d6e9ed" },
  green: { main: "#6CE9AE", dark: "#02aa80", pale: "#e1f4ef" },
  grey: { main: "#4F4F4F", light: "#ebebeb" },
  magenta: { main: "#FF008D", dark: "#c3006b", pale: "#ffcae7" },
  orange: { main: "#f2af78", dark: "#de7723", pale: "#f9ddc7" },
  pink: { main: "#fcaeb6", dark: "#ff7684", pale: "#ffe6ea" },
  purple: { main: "#bca2ed", dark: "#9161eb", pale: "#e8e6ef" },
  purplePink: { main: "#ed9fe2", dark: "#d065c2", pale: "#f2e4f2" },
  red: { main: "#e15757", pale: "#ffa8a8" },
  white: "#ffffff",
  yellow: { main: "#f4cb1c", pale: "#fff0b1" },
};

const customTheme = (type: "cofidoc" | "inovie") =>
  createTheme({
    palette: {
      primary: {
        main: type === "cofidoc" ? colorPalette.magenta.main : "#576dc4",
        light: type === "cofidoc" ? colorPalette.magenta.pale : "#b5cdec",
        dark: type === "cofidoc" ? "#573246" : "#1a2d79",
      },
    },
    colors: {
      seanceToDo: "#e3e3e3",
      seanceDone: "#ffe196",
      seanceReadyToBill: "#96adff",
      seancePutOnHold: "#ff96c2",
      seanceBilled: "#99ff96",
      primary: type === "cofidoc" ? colorPalette.magenta.main : "#576dc4",
      dark: type === "cofidoc" ? "#573246" : "#1a2d79",
      light: type === "cofidoc" ? colorPalette.magenta.pale : "#b5cdec",
      secondary: colorPalette.magenta.pale,
      lightgray: "#f6f6ff",
      background: "#fff",
      text: "#262626",
      bgPale: type === "cofidoc" ? "#fef4f9" : "#e5ecf4",
      seanceStatus: {
        "to-do": "#e3e3e3",
        done: "#ffe196",
        "ready-to-bill": "#96adff",
        "put-on-hold": "#ff96c2",
        billed: "#99ff96",
      },
      ...colorPalette,
      gradient: {
        "0": "#fff671",
        "1": "#ffcfd2",
        "2": "#f1c0e8",
        "3": "#cfbaf0",
        "4": "#a3c4f3",
        "5": "#90dbf4",
        "6": "#98f5e1",
        "7": "#b9fbc0",
        "8": "#fbf8cc",
      },
    },
    fonts: {
      body: "system-ui, sans-serif",
      heading: "inherit",
      monospace: "Menlo, monospace",
    },
    spacing: [0, 4, 8, 12, 16, 32],
  });
export function AppThemeProvider({ children }) {
  const isInovie = process.env.REACT_APP_INOVIE_DEL;
  const theme = customTheme(isInovie ? "inovie" : "cofidoc");

  return <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>;
}
