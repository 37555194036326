export function formatSocialSecurityNumber(ssNumber: string): string {
  if (!ssNumber) {
    return "";
  }
  const main =
    ssNumber.substring(0, 1) +
    " " +
    ssNumber.substring(1, 3) +
    " " +
    ssNumber.substring(3, 5) +
    " " +
    ssNumber.substring(5, 7) +
    " " +
    ssNumber.substring(7, 10) +
    " " +
    ssNumber.substring(10, 13);

  return ssNumber.length === 13 ? main : main + " " + ssNumber.substring(13);
}
