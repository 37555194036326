import { Box } from "./Box";
import { useTheme } from "@mui/material";

export function NavLink({ onClick, children, selected }: any) {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      mb={2}
      p="4px"
      px={3}
      py={2}
      bgcolor={selected ? theme.palette.primary.light : "transparent"}
      sx={{
        borderRadius: 40,
        width: "100%",
        cursor: "pointer",
        backgroundColor: selected ? theme.palette.primary.light : "transparent",
        "&:hover": {
          backgroundColor: selected ? theme.palette.primary.light : "#e6e6e6",
        },
      }}
      alignItems="center"
    >
      {children}
    </Box>
  );
}
