export type ProductName = "Forfait avec Engagement" | "Gestion+ avec Engagement" | "Saisie PREMIER" | "Gestion+" | "eCofiCoffre";

type PalawaObject = {
  Mois: string;
  CA: number;
  NbRemplacant: number;
  CAMensuelMin: number;
  CAMensuelMax: number;
  Produit: string;
  Quota: string;
};

export type CofidocHistory = {
  date?: Date;
  turnover?: number;
  nbReplacing?: number;
  monthlyTurnoverMin?: number;
  monthlyTurnoverMax?: number;
  productName?: string;
};

function proxiApiPalawa(objects: PalawaObject[]): CofidocHistory[] {
  const mapped = objects.map((o) => {
    const year = o.Mois.substring(0, 4);
    const month = o.Mois.substring(4, 6);
    const day = o.Mois.substring(6, 8);

    return {
      date: new Date(`${year}-${month}-${day}`),
      productName: o.Produit,
      nbReplacing: o.NbRemplacant,
      turnover: o.CA,
      monthlyTurnoverMin: o.CAMensuelMin,
      monthlyTurnoverMax: o.CAMensuelMax,
    };
  });
  return mapped;
}

export async function fetchCofidocHistory() {
  try {
    return proxiApiPalawa(data1 as PalawaObject[]);
  } catch (error) {
    throw new Error();
  }
}

const data1 = [
  {
    Mois: "20240201",
    CA: 11712.91,
    NbRemplacant: 0,
    CAMensuelMin: 0,
    CAMensuelMax: 0,
    Produit: "Saisie PREMIER",
    Quota: "Non lieu",
  },
  {
    Mois: "20240301",
    CA: 5859.33,
    NbRemplacant: 0,
    CAMensuelMin: 0,
    CAMensuelMax: 0,
    Produit: "Saisie PREMIER",
    Quota: "Non lieu",
  },
  {
    Mois: "20240401",
    CA: 13754.09,
    NbRemplacant: 0,
    CAMensuelMin: 0,
    CAMensuelMax: 0,
    Produit: "Saisie PREMIER",
    Quota: "Non lieu",
  },
  {
    Mois: "20240501",
    CA: 19455.87,
    NbRemplacant: 0,
    CAMensuelMin: 0,
    CAMensuelMax: 0,
    Produit: "Saisie PREMIER",
    Quota: "Non lieu",
  },
];
