import { formatISO, format as formatFns } from "date-fns";
import { fr } from "date-fns/locale";

const options = { locale: fr };

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export function format(date: number | Date, formatStr = "PP", log?: boolean) {
  let newDate = new Date(date);
  if (log) {
    console.log({ newDate });
    console.log({ parsed: formatISO(newDate, { format: "extended" }) });
  }

  newDate.setHours(newDate.getUTCHours(), newDate.getMinutes());

  return formatFns(newDate, formatStr, options);
}
