import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useAppContext } from "@/app/common/provider/AppProvider";
import { Box, Card, Text, Button, Href, Modal } from "@/ui";
import { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { useTheme } from "@mui/material";
import { CofidocContract, fetchCofidocContracts } from "@/api/taxi/fetchCofidocContracts";
import { format } from "date-fns";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import InventoryIcon from "@mui/icons-material/Inventory";
import InfoIcon from "@mui/icons-material/Info";
import Schedule from "./Schedule";

const modalDetails = {
  ecoficoffreNonSouscrit: {
    type: "type1",
    title: "eCofiCoffre",
    texts: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio."],
    buttonText: "Contactez ma gestionnaire",
    buttonLink: "https://www.cofidoc.fr/contact/",
    image: "image-cofidoc-metier",
  },
  gestionNonSouscrit: {
    type: "type1",
    title: "Gestion+",
    texts: [
      "Vous n'avez pas le temps de faire vos rapprochements bancaires et de gérer vos impayés ? Nous nous en occupons !",
      "Complétez votre offre en souscrivant l'option Gestion + afin de confier à votre agence Cofidoc vos rapprochements bancaires et la relance automatique de vos impayés.",
      "Nous effectuons le pointage à partir de vos relevés de compte bancaire et de la comptabilité de notre logiciel de facturation.",
      "Vous devrez fournir à votre agence vos relevés régulièrement afin de pouvoir suivre vos paiements et effectuer la relance des impayés si nécessaire.",
      "Vous êtes intéressé ?"
    ],
    buttonText: "Contactez ma gestionnaire",
    buttonLink: "https://www.cofidoc.fr/contact/",
    image: "image-cofidoc-metier",
  },
  forfaitEngagement: {
    type: "type2",
    title: "Forfait avec Engagement",
    schedule: true,
    buttonText: "Contactez ma gestionnaire",
    buttonLink: "https://www.cofidoc.fr/contact/",
  },
  forfait: {
    type: "type1",
    title: "Saisie PREMIER",
    texts: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio."],
    buttonText: "Contactez ma gestionnaire",
    buttonLink: "https://www.cofidoc.fr/contact/",
    image: "image-cofidoc-metier",
  },
  gestionEngagement: {
    type: "type2",
    title: "Gestion+ avec Engagement",
    schedule: true,
    buttonText: "Contactez ma gestionnaire",
    buttonLink: "https://www.cofidoc.fr/contact/",
  },
  gestion: {
    type: "type1",
    title: "Gestion+",
    texts: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio."],
    buttonText: "Contactez ma gestionnaire",
    buttonLink: "https://www.cofidoc.fr/contact/",
    image: "image-cofidoc-metier",
  },
  ecoficoffre: {
    type: "type1",
    title: "eCofiCoffre",
    texts: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio."],
    buttonText: "Contactez ma gestionnaire",
    buttonLink: "https://www.cofidoc.fr/contact/",
    image: "image-cofidoc-metier",
  }
};

const ModalContent = ({ detail, theme, contract }) => {
  const content = (
    <>
      {detail.type === "type1" ? (
        <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row" pl={4} pr={4} width="100%">
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="50%">
            <Text style={{ fontSize: 30, fontWeight: 500, color: theme.colors.primary, textAlign: 'center' }}>
              {detail.title}
            </Text>
            <Box p={2} />
            {detail.texts.map((text, index) => (
              <Text key={index} textAlign="left" mt={1}>{text}</Text>
            ))}
            {detail.buttonText && (
              <Box m={3} width="100%" alignItems="center" justifyContent="center" mb={2}>
                <Href target="blank" href={detail.buttonLink}>
                  {detail.buttonText}
                </Href>
              </Box>
            )}
            <Text textAlign="center" mt={2}>
              <a href="tel:0806080808" style={{ color: theme.colors.primary }}>
                08 06 08 08 08
              </a>
            </Text>
          </Box>
          <Box width="50%" height="100%">
            <div className={detail.image} />
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" pl={4} pr={4} width="100%">
          <Text style={{ fontSize: 30, fontWeight: 500, color: theme.colors.primary, textAlign: 'center' }}>
            {detail.title}
          </Text>
          <Box p={2} />
          <Text textAlign="center" mt={1}>
            Voici les détails de votre contrat :
          </Text>
          <Box mt={2} />
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" width="100%">
            <Card style={{ marginTop: 10, padding: 10, width: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Text>
                Prix mensuel :
              </Text>
              <Text style={{ color: theme.colors.primary, fontWeight: 'bold', marginTop: 'auto' }}>
                {contract?.productBase}€ / mois
              </Text>
            </Card>
            <Card style={{ marginTop: 10, padding: 10, width: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Text>
                Durée d'engagement :
              </Text>
              <Text style={{ color: theme.colors.primary, fontWeight: 'bold', marginTop: 'auto' }}>
                {contract?.commitmentDuration} mois
              </Text>
            </Card>
          </Box>
          {detail.schedule && (
            <>
              <Box mt={4} />
              <Schedule contract={contract} />
            </>
          )}
          {detail.buttonText && (
            <Box m={3} width="100%" alignItems="center" justifyContent="center" mb={2}>
              <Href target="blank" href={detail.buttonLink}>
                {detail.buttonText}
              </Href>
            </Box>
          )}
          <Text textAlign="center" mt={2}>
            <a href="tel:0806080808" style={{ color: theme.colors.primary }}>
              08 06 08 08 08
            </a>
          </Text>
        </Box>
      )}
    </>
  );

  return content;
};

const ContractCard = ({ contract, handleOpenModal, theme, icon: Icon, type, title }) => (
  <Box my={2} borderRadius={1} bgcolor={contract ? `${theme.palette.primary.light}20` : theme.palette.grey[100]} overflow="hidden" flexDirection="column" minHeight={150}>
    <Box bgcolor={contract ? `${theme.palette.primary.light}20` : theme.palette.grey[200]} p={2}>
      <Icon style={{ color: contract ? theme.palette.primary.main : theme.palette.grey[500] }} />
      <Text color={contract ? theme.palette.primary.main : theme.palette.grey[500]} fontSize={20} pl={2}>
        {title}
      </Text>
    </Box>
    <Box p={2} flexDirection="column">
      {contract ? (
        <>
          <Box flexDirection="column" mb={1}>
            <Text fontSize={14} fontWeight="600">
              Date de souscription
            </Text>
            <Text>{format(contract?.subscriptionDate, "dd MMMM yyyy")}</Text>
          </Box>
          {contract.productName.includes('Engagement') && (
            <Box flexDirection="column" mb={1}>
              <Text fontSize={14} fontWeight="600">
                Mon taux
              </Text>
              <Text>{contract?.productBase}€ / mois</Text>
            </Box>
          )}
          {!contract.productName.includes('Engagement') && (
            <Box flexDirection="column" mb={1}>
              <Text fontSize={14} fontWeight="600">
                Mon taux
              </Text>
              <Text>{contract?.percentage}% du chiffre d'affaire traité</Text>
            </Box>
          )}
        </>
      ) : (
        <Box flexDirection="column" mb={1}>
          <Text>Vous n'avez pas encore souscrit.</Text>
          <Text>Pour souscrire, veuillez contacter votre gestionnaire :</Text>
          <Box m={3} width="100%" alignItems="center" justifyContent="center">
            <Href target="blank" href="https://www.cofidoc.fr/contact/">
              Contactez ma gestionnaire
            </Href>
          </Box>
        </Box>
      )}
      <Box p={2} display="flex" justifyContent="center" alignItems="center">
        <Button onClick={() => handleOpenModal(contract ? (contract.productName.includes('Engagement') ? `${type}Engagement` : type) : `${type}NonSouscrit`, contract)}>
          <InfoIcon fontSize="small" />
          <Box pr={1} />
          <Text style={{ textAlign: "center", fontSize: 12 }}>En savoir plus</Text>
        </Button>
      </Box>
    </Box>
  </Box>
);

const Contract = () => {
  const theme = useTheme();
  const { client, user } = useMongoContext();
  const { setLoading } = useAppContext();
  const [contract, setContract] = useState<CofidocContract>();
  const [optionGestion, setOptionGestion] = useState<CofidocContract>();
  const [optionECoficoffre, setOptionECoficoffre] = useState<CofidocContract>();
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  async function getData() {
    try {
      const contracts = await fetchCofidocContracts();
      const resContract = contracts.find((c) => ["Saisie PREMIER", "Forfait avec Engagement"].includes(c.productName) && c.active);
      const gestion = contracts.find((c) => ["Gestion+", "Gestion+ avec Engagement"].includes(c.productName) && c.active);
      const coficoffre = contracts.find((c) => c.productName === "eCofiCoffre" && c.active);
      setContract(resContract);
      setOptionGestion(gestion);
      setOptionECoficoffre(coficoffre);
      setLoading(true);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user && client) {
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client]);

  const handleOpenModal = (type, contractInfo) => {
    const detail = modalDetails[type];
    const content = <ModalContent detail={detail} theme={theme} contract={contractInfo} />;
    setModalContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalContent(null);
  };

  return (
    <>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box width="800px" maxWidth="98%" mx="auto" my={5}>
          {modalContent}
        </Box>
      </Modal>

      <ContractCard
        contract={contract}
        handleOpenModal={handleOpenModal}
        theme={theme}
        icon={LocalOfferIcon}
        type="forfait"
        title={contract?.productBase ? "Forfait avec Engagement" : "Saisie PREMIER"}
      />

      <ContractCard
        contract={optionGestion}
        handleOpenModal={handleOpenModal}
        theme={theme}
        icon={AddModeratorIcon}
        type="gestion"
        title="Gestion+"
      />

      <ContractCard
        contract={optionECoficoffre}
        handleOpenModal={handleOpenModal}
        theme={theme}
        icon={InventoryIcon}
        type="ecoficoffre"
        title="eCofiCoffre"
      />
    </>
  );
};

export default Contract;