/* eslint-disable react-hooks/exhaustive-deps */
import { Car, Document, Journey, JourneyStatus, User } from "@/types/taxi";
import { Box, Button, Modal, Text } from "@/ui";
import { useEffect, useState } from "react";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useTheme } from "@mui/material";
import { format } from "date-fns";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PersonIcon from "@mui/icons-material/Person";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import TollIcon from "@mui/icons-material/Toll";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import MessageIcon from "@mui/icons-material/Message";
import { Spinner } from "@/ui/Spinner";
import { useGetURI } from "@/utils/useGetUri";

export function JourneyItem({ journey, currentUser }: { journey: Journey; currentUser: User }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [showAnnex, setShowAnnex] = useState(false);
  const { dbName } = useApplicationContext();
  const { client } = useMongoContext();
  const [relatedAnnex4, setRelatedAnnexe4] = useState<Document>();
  const [car, setCar] = useState<Car>();
  const [driver, setDriver] = useState<{ lastName: string; firstName: string }>();

  async function getData() {
    try {
      const carCollection = client.db(dbName).collection("Car");
      const driverCollection = client.db(dbName).collection("Driver");
      const carFind = await carCollection.findOne({ _id: journey.car });
      const driverFind = await driverCollection.findOne({ _id: journey.driver });
      setCar(carFind);
      if (driver) setDriver({ lastName: driverFind?.lastName, firstName: driverFind.firstName });
      if (!driver) setDriver({ lastName: currentUser?.lastname, firstName: currentUser.name });
    } catch (error) {
      console.log("error :", error);
    }
  }
  async function getAnnex4() {
    try {
      const patientCollection = client.db(dbName).collection("Document");
      const doc = await patientCollection.findOne({ _id: journey.annex4Id });
      setRelatedAnnexe4(doc);
    } catch (error) {
      console.log("error :", error);
    }
  }

  useEffect(() => {
    if (journey?.annex4Id) {
      getAnnex4();
    }
    getData();
  }, []);

  function statusColor() {
    if (journey.status === JourneyStatus.PREPARED) {
      return theme.colors.orange.main;
    }
    if (
      (journey.status === JourneyStatus.SIGNED || journey.status === JourneyStatus.FINISHED_WITHOUT_SIGNATURE) &&
      relatedAnnex4?.status !== "sentForBilling"
    ) {
      return theme.colors.blue.main;
    }
    if (
      (journey.status === JourneyStatus.SIGNED || journey.status === JourneyStatus.FINISHED_WITHOUT_SIGNATURE) &&
      relatedAnnex4?.status === "sentForBilling"
    ) {
      return theme.colors.green.main;
    }
  }

  function RenderStatus() {
    if (journey.status === JourneyStatus.PREPARED) {
      return (
        <Box
          bgcolor={theme.colors.white}
          position="absolute"
          top={0}
          left={0}
          alignItems="center"
          style={{
            borderBottom: `solid 1px ${statusColor()}`,
            borderRight: `solid 1px ${statusColor()}`,
            borderBottomRightRadius: 5,
            borderTopLeftRadius: 5,
          }}
        >
          <Box flexDirection="row" alignItems="center" px={3} bgcolor={`${theme.colors.yellow.pale}80`}>
            <Text fontSize={12} color={theme.colors.orange.dark} fontWeight="600">
              Trajet à signer
            </Text>
          </Box>
        </Box>
      );
    }
    if (
      (journey.status === JourneyStatus.SIGNED || journey.status === JourneyStatus.FINISHED_WITHOUT_SIGNATURE) &&
      relatedAnnex4?.status !== "sentForBilling"
    ) {
      return (
        <Box
          bgcolor={theme.colors.white}
          position="absolute"
          top={0}
          left={0}
          alignItems="center"
          style={{
            borderBottom: `solid 1px ${statusColor()}`,
            borderRight: `solid 1px ${statusColor()}`,
            borderBottomRightRadius: 5,
            borderTopLeftRadius: 5,
          }}
        >
          <Box flexDirection="row" alignItems="center" px={3} bgcolor={`${theme.colors.blue.pale}80`}>
            <Text fontSize={12} color={theme.colors.blue.dark} fontWeight="500">
              Trajet signé
            </Text>
          </Box>
        </Box>
      );
    }
    if (
      (journey.status === JourneyStatus.SIGNED || journey.status === JourneyStatus.FINISHED_WITHOUT_SIGNATURE) &&
      relatedAnnex4?.status === "sentForBilling"
    ) {
      return (
        <Box
          bgcolor={theme.colors.white}
          position="absolute"
          top={0}
          left={0}
          alignItems="center"
          style={{
            borderBottom: `solid 1px ${statusColor()}`,
            borderRight: `solid 1px ${statusColor()}`,
            borderBottomRightRadius: 5,
            borderTopLeftRadius: 5,
          }}
        >
          <Box flexDirection="row" alignItems="center" px={3} bgcolor={`${theme.colors.green.pale}80`}>
            <Text fontSize={12} color={theme.colors.green.dark} fontWeight="500">
              Trajet envoyé
            </Text>
          </Box>
        </Box>
      );
    }
  }

  return (
    <>
      <Box
        key={journey?._id}
        width="100%"
        borderRadius={1}
        border="solid 1px"
        borderColor={statusColor()}
        bgcolor={`${statusColor()}33`}
        position="relative"
        flexDirection="column"
        p={2}
        pt={3}
      >
        <RenderStatus />
        <Box alignItems="center" justifyContent="space-between" style={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
          <Box flexDirection="column">
            <Box p={1}>
              <Text fontWeight="600">{format(journey?.outwardDepartureDatetime, "dd MMMM yyyy à HH:mm")}</Text>
            </Box>
            <Box flexDirection="column" p={1} pt={0}>
              {!journey?.isRoundTrip && (
                <Text fontSize={12} fontWeight="600">
                  Aller simple
                </Text>
              )}
              {journey?.isRoundTrip && (
                <Text fontSize={12} fontWeight="600">
                  Aller retour
                </Text>
              )}
              <Text textAlign="justify">{journey.departureLocation}</Text>
              <Text textAlign="justify">{journey.arrivalLocation}</Text>
            </Box>
          </Box>
          <Box justifyContent="center" alignItems="center">
            <Box style={{ transform: open ? "rotate(-180deg)" : "rotate(0deg)", transition: "all ease 300ms" }}>
              <ExpandMoreIcon style={{ color: statusColor() }} />
            </Box>
          </Box>
        </Box>
        {open && (
          <Box pt={3} flexDirection="column">
            {!!driver && (
              <Box flexDirection="row" alignItems="center" p={1}>
                <PersonIcon style={{ color: statusColor() }} />
                <Text textAlign="justify" pl={2}>
                  {driver?.lastName} {driver?.firstName}
                </Text>
              </Box>
            )}
            {!!car && (
              <Box flexDirection="row" alignItems="center" p={1}>
                <DirectionsCarIcon style={{ color: statusColor() }} />
                <Box pl={2} flexDirection="column">
                  <Text textAlign="justify">
                    {car?.mark} {car?.model}
                  </Text>
                  <Text textAlign="justify">{car?.numberplate}</Text>
                </Box>
              </Box>
            )}
            {!!journey?.reason && (
              <Box flexDirection="row" alignItems="center" p={1}>
                <Box justifyContent="center" alignItems="center">
                  <Box zIndex={2} position="absolute" bgcolor={theme.colors.white} borderRadius={1} px={1}>
                    <Text color={statusColor()} fontWeight="bold" style={{ fontSize: 7 }}>
                      IHC
                    </Text>
                  </Box>
                  <Box zIndex={1}>
                    <InsertDriveFileIcon style={{ color: statusColor() }} />
                  </Box>
                </Box>
                <Text textAlign="justify" pl={2}>
                  {journey?.reason}
                </Text>
              </Box>
            )}
            {(!!journey.dayTimeKm || !!journey.nightTimeKm) && (
              <Box flexDirection="row" alignItems="center" p={1}>
                <LocalGasStationIcon style={{ color: statusColor() }} />
                <Box pl={2} flexDirection="column">
                  {!!journey.dayTimeKm && (
                    <Text>
                      {journey.dayTimeKm} kms de jour {journey?.isRoundTrip ? "à l'aller" : ""}
                    </Text>
                  )}
                  {!!journey.nightTimeKm && (
                    <Text>
                      {journey.nightTimeKm} kms de nuit {journey?.isRoundTrip ? "à l'aller" : ""}
                    </Text>
                  )}
                </Box>
              </Box>
            )}
            {!!journey?.isRoundTrip && (!!journey.returnDayTimeKm || !!journey.returnNightTimeKm) && (
              <Box flexDirection="row" alignItems="center" p={1}>
                <LocalGasStationIcon style={{ color: statusColor() }} />
                <Box pl={2} flexDirection="column">
                  {!!journey.returnDayTimeKm && <Text>{journey.returnDayTimeKm} kms de jour au retour</Text>}
                  {!!journey.returnNightTimeKm && <Text>{journey.returnNightTimeKm} kms de nuit au retour</Text>}
                </Box>
              </Box>
            )}
            {!!journey?.toll && (
              <Box flexDirection="row" alignItems="center" p={1}>
                <TollIcon style={{ color: statusColor() }} />
                <Text textAlign="justify" pl={2}>
                  {journey?.toll}€ de péage {journey?.isRoundTrip ? "à l'aller" : ""}
                </Text>
              </Box>
            )}
            {!!journey?.returnToll && (
              <Box flexDirection="row" alignItems="center" p={1}>
                <TollIcon style={{ color: statusColor() }} />
                <Text textAlign="justify" pl={2}>
                  {journey?.returnToll}€ au retour
                </Text>
              </Box>
            )}
            {!!journey?.pricePackage && (
              <Box flexDirection="row" alignItems="center" p={1}>
                <FolderSpecialIcon style={{ color: statusColor() }} />
                <Text textAlign="justify" pl={2}>
                  Tarif: {journey?.pricePackage}
                </Text>
              </Box>
            )}
            {!!journey?.comment && (
              <Box flexDirection="row" alignItems="center" p={1}>
                <MessageIcon style={{ color: statusColor() }} />
                <Text textAlign="justify" pl={2}>
                  {journey?.comment}
                </Text>
              </Box>
            )}
            {!!relatedAnnex4 && (
              <>
                <Box flexDirection="row" alignItems="center" justifyContent="center" p={1}>
                  <Button onClick={() => setShowAnnex(true)}>Voir l'annexe 4</Button>
                </Box>
                {showAnnex && relatedAnnex4?.media && <ModalAnnex uri={relatedAnnex4?.media[0]?.storagePath} setShowAnnex={setShowAnnex} />}
                {showAnnex && !relatedAnnex4?.media && <ModalAnnex uri={relatedAnnex4?.paths[0]} setShowAnnex={setShowAnnex} />}
              </>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}

function ModalAnnex({ uri, setShowAnnex }: { uri: string; setShowAnnex: any }) {
  const res = useGetURI(uri);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Modal open={true} onClose={() => setShowAnnex(false)}>
      <Box m={2} bgcolor={theme.palette.grey[100]} height="80vh" width="50vw" maxWidth="1200px" justifyContent="center" alignItems="center">
        {loading && <Spinner />}
        {!loading && <embed src={res} width="100%" height="100%" style={{ borderRadius: 4 }} type="application/pdf" />}
      </Box>
      <Box flexDirection="row" alignItems="center" justifyContent="center" p={1}>
        <Button onClick={() => setShowAnnex(false)}>Fermer</Button>
      </Box>
    </Modal>
  );
}
