import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useAppContext } from "@/app/common/provider/AppProvider";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { Box, Text } from "@/ui";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Document, Patient } from "@/types/taxi";
import "react-calendar/dist/Calendar.css";
import { groupBy } from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { DocumentItem } from "@/components/taxi/DocumentItem";

export default function DocsToSend() {
  const theme = useTheme();
  const { client, user } = useMongoContext();
  const { setLoading } = useAppContext();
  const { dbName } = useApplicationContext();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const [groupedDocument, setGroupedDocuemnt] = useState<{ patient: Patient; docs: Document[] }[]>();

  const taxi_username = user?.identities[0]?.id;

  // Get data function
  async function getData() {
    try {
      setLoading(true);
      const patientCollection = client.db(dbName).collection("Patient");
      const documentCollection = client.db(dbName).collection("Document");
      const pat = await patientCollection.find({ username: taxi_username, deleted: { $ne: true } });
      const docs = await documentCollection.find({ username: taxi_username, deleted: { $ne: true }, status: "uploaded", patient: { $exists: true } });
      const grouped = groupBy(docs, "patient");
      setDocuments(docs);
      const mapped = Object.keys(grouped).map((key, i) => {
        const patient = pat.find((p) => p._id === key);
        return {
          patient: patient,
          docs: grouped[key],
        };
      });
      setGroupedDocuemnt(mapped);
      // pat?.sort((a, b) => String(a?.lastname).toLowerCase().trim().localeCompare(String(b?.lastname).toLowerCase().trim()));
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user && client) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client]);

  function handleSelectAll() {
    if (selectedDocuments?.length === documents?.length) {
      setSelectedDocuments([]);
    } else {
      setSelectedDocuments(documents);
    }
  }

  return (
    <>
      <Box alignItems="center" justifyContent="space-between" pb={1}>
        <Box p={2}>
          <Text>Document à envoyer en facturation</Text>
        </Box>
        <Box alignItems="center" pr={2}>
          <Box p={1} style={{ cursor: "pointer" }} onClick={() => handleSelectAll()}>
            {selectedDocuments?.length === 0 && <CheckBoxOutlineBlankIcon style={{ color: theme.palette.primary.main }} />}
            {selectedDocuments?.length > 0 && selectedDocuments?.length === documents.length && (
              <CheckBoxIcon style={{ color: theme.palette.primary.main }} />
            )}
            {selectedDocuments?.length > 0 && selectedDocuments?.length !== documents.length && (
              <IndeterminateCheckBoxIcon style={{ color: theme.palette.primary.main }} />
            )}
          </Box>
          <Text fontSize={12}>selectionner tout</Text>
          <Box alignItems="center" justifyContent="flex-end" width={70}>
            <Text>
              {selectedDocuments?.length}/{documents?.length}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box flexDirection="column" style={{ overflowY: "scroll" }} width="100%">
        {groupedDocument?.map((item) => {
          return (
            <Box my={1} key={item?.patient?._id} width="100%">
              <RenderDocumentGroupedByPatient item={item} selectedDocuments={selectedDocuments} setSelectedDocuments={setSelectedDocuments} />
            </Box>
          );
        })}
      </Box>
    </>
  );
}

function RenderDocumentGroupedByPatient({
  item,
  selectedDocuments,
  setSelectedDocuments,
}: {
  item: { patient: Patient; docs: Document[] };
  selectedDocuments: Document[];
  setSelectedDocuments: any;
}) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const countSelected = item.docs.filter((doc) => selectedDocuments?.find((d) => d._id === doc._id)).length;
  const selected = countSelected === 0 ? "none" : countSelected === item.docs.length ? "all" : "some";

  return (
    <Box
      width="100%"
      borderRadius={1}
      flexDirection="column"
      border={`solid 1px ${
        selected === "all" ? `${theme.colors.green.main}bb` : selected === "some" ? `${theme.colors.green.main}60` : `${theme.colors.light}20`
      }`}
      bgcolor={
        selected === "all"
          ? `${theme.colors.green.main}bb`
          : selected === "some"
          ? `${theme.colors.green.main}60`
          : `${theme.palette.primary.light}20`
      }
    >
      <Box flexDirection="row" p={2} alignItems="center" justifyContent="space-between" width="100%">
        <Box flexDirection="row" alignItems="center">
          <Box
            p={2}
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              let itemsFiltered = item.docs.filter((c) => !c?.media || !c?.media?.find((c) => c.provisionalHightQualityDocument));
              if (countSelected > 0) {
                const filteredSelected = selectedDocuments.filter((element) => item.docs.findIndex((exclude) => element._id === exclude._id) < 0);
                setSelectedDocuments([...filteredSelected]);
              } else {
                setSelectedDocuments([...selectedDocuments, ...itemsFiltered]);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            {selected === "none" && <CheckBoxOutlineBlankIcon style={{ color: theme.palette.primary.main }} />}
            {selected === "all" && <CheckBoxIcon style={{ color: theme.palette.primary.main }} />}
            {selected === "some" && <IndeterminateCheckBoxIcon style={{ color: theme.palette.primary.main }} />}
          </Box>

          <Box>
            <Text>
              {item?.patient?.lastname} {item?.patient?.name}
            </Text>
          </Box>
        </Box>
        <Box
          justifyContent="center"
          alignItems="center"
          onClick={() => setOpen(!open)}
          bgcolor={
            selected === "all"
              ? `${theme.colors.green.main}aa`
              : selected === "some"
              ? `${theme.colors.green.main}70`
              : `${theme.palette.primary.light}30`
          }
          borderRadius={1}
          p={2}
          style={{ cursor: "pointer" }}
        >
          <Box style={{ transform: open ? "rotate(-180deg)" : "rotate(0deg)", transition: "all ease 300ms" }}>
            <ExpandMoreIcon style={{ color: theme.palette.primary.main }} />
          </Box>
        </Box>
      </Box>
      {open && (
        <Box flexDirection="column">
          {item?.docs?.map((doc) => {
            return (
              <Box m={1}>
                <DocumentItem
                  selectable
                  document={doc}
                  isSelected={!!selectedDocuments.find((d) => d._id === doc._id)}
                  setIsSelected={() => {
                    if (!!selectedDocuments.find((d) => d._id === doc._id)) {
                      setSelectedDocuments(selectedDocuments.filter((d) => d._id !== doc._id));
                    } else {
                      setSelectedDocuments([...selectedDocuments, doc]);
                    }
                  }}
                />
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
