/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Text } from "@/ui";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { format } from "date-fns";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Care, Prescription } from "@/types/nurse";
import { useOfficeContext } from "@/app/nurse/provider/OfficeProvider";
import { CareItem } from "./CareItem";

export function PrescriptionItem({ prescription, cares }: { prescription: Prescription; cares: Care[] }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { currentOfficeInfo } = useOfficeContext();
  async function getData() {}

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box
        key={prescription?._id}
        width="100%"
        borderRadius={1}
        border="solid 1px"
        borderColor={`${theme.palette.primary.light}90`}
        bgcolor={`${theme.palette.primary.light}10`}
        position="relative"
        flexDirection="column"
        mt={3}
      >
        <Box alignItems="center" style={{ cursor: "pointer" }} onClick={() => setOpen(!open)} minHeight={70} bgcolor={`${theme.palette.primary.light}10`}>
          <Box alignItems="center" justifyContent="center" flexDirection="column" height="100%">
            {cares?.map((care, i) => {
              console.log("=>  care:", care);
              return (
                <Box
                  key={care?._id + i}
                  height={`${100 / (cares?.length || 100)}%`}
                  width={10}
                  bgcolor={care.category?.color}
                  style={{
                    borderTopLeftRadius: i === 0 ? 3 : 0,
                    borderBottomLeftRadius: (cares?.length || 0) - 1 !== i || open ? 0 : 3,
                  }}
                />
              );
            })}
          </Box>
          <Box flexDirection="column" flex={1} p={2}>
            {prescription.name && (
              <Box flexDirection="column" mt={1}>
                <Text textAlign="justify">{prescription.name}</Text>
              </Box>
            )}
            {prescription?.prescriptionDate && (
              <Box mt={1}>
                <Text>Date d'ordonnance : {format(prescription?.prescriptionDate, "dd MMMM yyyy à HH:mm")}</Text>
              </Box>
            )}
            {prescription?.prescriptionDate && (
              <Box mt={1}>
                <Text>Créée le : {format(prescription?.createdAt, "dd MMMM yyyy à HH:mm")}</Text>
              </Box>
            )}
          </Box>
          <Box justifyContent="center" alignItems="center" p={2}>
            <Box style={{ transform: open ? "rotate(-180deg)" : "rotate(0deg)", transition: "all ease 300ms" }}>
              <ExpandMoreIcon style={{ color: theme.palette.primary.main }} />
            </Box>
          </Box>
        </Box>
        {open && (
          <Box pt={3} flexDirection="column" alignItems="center">
            {cares.map((care) => {
              return (
                <Box p={1} width="100%">
                  <CareItem care={care} prescription={prescription} />
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
}
