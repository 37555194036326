import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useAppContext } from "@/app/common/provider/AppProvider";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { Box, Text } from "@/ui";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Patient } from "@/types/taxi";
import "react-calendar/dist/Calendar.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useQueryParams } from "@/utils/useQueryParams";
import { gradient } from "@/theme/ThemeProvider";
import PersonIcon from "@mui/icons-material/Person";
import { getRefreshToken } from "@/utils/localStorage";

export default function PatientsListView() {
  const theme = useTheme();
  const { client, user } = useMongoContext();
  const { setLoading, loading } = useAppContext();
  const { dbName } = useApplicationContext();
  const [patients, setPatients] = useState<Patient[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<Patient>();
  const taxi_username = user?.identities[0]?.id;
  const location = useLocation();
  const params = useQueryParams();
  const navigate = useNavigate();

  // Get data function
  async function getData() {
    const token = getRefreshToken();
    console.log("=>  token:", token);
    try {
      setLoading(true);
      const patientCollection = client.db(dbName).collection("Patient");
      const p = await patientCollection.find({ username: taxi_username, deleted: { $ne: true } });
      p?.sort((a, b) => String(a?.lastname).toLowerCase().trim().localeCompare(String(b?.lastname).toLowerCase().trim()));

      setPatients(p);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user && client) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client]);

  return (
    <Box flexDirection="column" width="98%" height="98%" alignItems="center" style={{ backgroundColor: "#fffffff6", borderRadius: 5 }}>
      <Box justifyContent="center" alignItems="center" width="100%" height="60px" position="relative">
        <Box
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          position="relative"
          borderBottom={`solid 1px ${theme.colors.light}`}
        >
          <Box justifyContent="center" alignItems="center">
            <Text fontWeight={300} fontSize={26} pl={3}>
              Patient
            </Text>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          width: "100%",
          height: "calc(100% - 60px)",
          position: "relative",
        }}
        justifyContent="center"
        alignItems="center"
      >
        {!patients?.length && !loading && (
          <Box style={{ position: "absolute" }} width="50%" justifyContent="center" alignItems="center">
            <Box
              pb={1}
              fontSize={20}
              fontWeight="300"
              flexDirection="row"
              flexWrap="wrap"
              textAlign="center"
              justifyContent="center"
              alignItems="center"
            >
              Aucun patient
            </Box>
          </Box>
        )}
        {patients?.length > 0 && (
          <>
            <Box flexDirection="column" width="350px" pr={2} pl={2} style={{ overflowY: "scroll" }} height="100%">
              {patients
                ?.sort((a, b) => String(a?.lastname).toLowerCase().trim().localeCompare(String(b?.lastname).toLowerCase().trim()))
                ?.map((patient, index) => {
                  if (location.pathname.includes(`${patient?._id}`) && !location.pathname.includes(`${selectedPatient?._id}`)) {
                    setSelectedPatient(patient);
                    let to = queryString.stringify({ ...params }, { arrayFormat: "index" });
                    navigate(`${patient?._id}?${to}`, { replace: true });
                  }
                  return (
                    <Box
                      onClick={() => {
                        setSelectedPatient(patient);
                        let to = queryString.stringify({ ...params }, { arrayFormat: "index" });
                        navigate(`${patient?._id}?${to}`, { replace: true });
                      }}
                      key={patient._id}
                      alignItems="center"
                      justifyContent="space-between"
                      style={{
                        margin: 5,
                        width: "90%",
                        borderRadius: 25,
                        paddingLeft: 15,
                        minHeight: 35,
                        cursor: "pointer",
                        position: "relative",
                        backgroundColor: selectedPatient?._id === patient._id ? theme.colors.light?.toString() : "#f5f5f5",
                      }}
                    >
                      <Box bgcolor="#fff" width={25} height={25} borderRadius={50} mr={1}>
                        <Box
                          width={25}
                          height={25}
                          borderRadius={50}
                          bgcolor={`${theme.colors.gradient[String(index % 8) as keyof gradient]}30`}
                          border={`1px solid ${theme.colors.gradient[String(index % 8) as keyof gradient]}`}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <PersonIcon
                            style={{
                              color: theme.colors.gradient[String(index % 8) as keyof gradient],
                              fontSize: 18,
                            }}
                          />
                        </Box>
                      </Box>
                      <Text display="block" fontWeight="400" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" width="100%">
                        {patient.lastname} {patient.name}
                      </Text>
                    </Box>
                  );
                })}
            </Box>
            <Box width="calc(100% - 350px)" pl={2} height="100%">
              {selectedPatient && <Outlet context={{ patient: selectedPatient }} />}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
