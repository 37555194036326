export function getStatus(status: "to-do" | "done" | "ready-to-bill" | "put-on-hold" | "billed") {
  switch (status) {
    case "to-do":
      return "À faire";
    case "done":
      return "À valider";
    case "ready-to-bill":
      return "Prête à facturer";
    case "billed":
      return "Facturée";
    case "put-on-hold":
      return "Mise en attente";
    default:
      break;
  }
}

export function getBillingMode(billingMode: string) {
  switch (billingMode) {
    case "degrade":
      return "Dégradé";
    case "tla":
      return "TLA";
    default:
      break;
  }
}
