import React from "react";
import AccordionMUI from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

interface AccordionProps {
  title: string;
  children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
  return (
    <AccordionMUI style={{ margin: "10px 0", borderRadius: "3px", boxShadow: "0px 0px 0px 1px rgba(0,0,0,0.2)" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "#d53ea0" }} />}
        aria-controls={`panel-${title}-content`}
        id={`panel-${title}-header`}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ width: "100%", overflowX: "auto" }}>{children}</AccordionDetails>
    </AccordionMUI>
  );
};

export { Accordion };
