/* eslint-disable react-hooks/exhaustive-deps */
import { fetchDownloadURLMedia } from "@/api/taxi/fetchDownloadURLMedia";
import { fetchDownloadURLPath } from "@/api/taxi/fetchDownloadURLPath";
import { useEffect, useState } from "react";

export function useGetMultiUri(uris: string[]) {
  const [responses, setResponses] = useState<string[]>([]);

  async function main() {
    try {
      const array = [];
      for (let index = 0; index < uris.length; index++) {
        const uri = uris[index];
        if (!String(uri)?.startsWith("https")) {
          const res = await fetchDownloadURLMedia({ path: uri });
          if (res) array.push(res);
        }
        // old path system, retrocompatibility
        if (String(uri)?.startsWith("https")) {
          const res = await fetchDownloadURLPath({ path: uri });
          if (res) array.push(res);
        }
      }
      setResponses(array);
      // new media system
    } catch (error) {
      console.error("Log error use get uri:", error);
    }
  }

  useEffect(() => {
    main();
  }, []);

  return responses;
}
