import { Patient, Prescription, SeanceWithEntities, UserWithRoleInfo } from "@/types/nurse";
import { Box, Button, Text } from "@/ui";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material";
import Draggable from "react-draggable";
import { Resizable, ResizableBox } from "react-resizable";
import PersonIcon from "@mui/icons-material/Person";
import ArticleIcon from "@mui/icons-material/Article";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PixIcon from "@mui/icons-material/Pix";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import WarningIcon from "@mui/icons-material/Warning";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { formatSocialSecurityNumber } from "@/utils/formatSocialSecurityNumber";
import { getBillingMode } from "@/components/common/getStatus";
import { format } from "@/utils/format";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "@/app/common/provider/AppProvider";
import qs from "query-string";
import { useQueryParams } from "@/utils/useQueryParams";
import { ModalConfirmDone } from "./ModalUpdateSeance";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { useOfficeContext } from "@/app/nurse/provider/OfficeProvider";

export function ModalDetailDay({
  selectedDay,
  setSelectedDay,
  patient,
  praticiens,
  setNeedRefresh,
}: {
  selectedDay: { seances: SeanceWithEntities[]; date: Date };
  setSelectedDay: any;
  patient: Patient;
  praticiens: (UserWithRoleInfo & { color: string })[];
  setNeedRefresh: any;
}) {
  const { dbName } = useApplicationContext();
  const { client, user } = useMongoContext();
  const { setLoading } = useAppContext();
  const { currentOfficeInfo } = useOfficeContext();
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);
  const [size, setSize] = useState({
    height: window?.innerHeight,
    width: window?.innerWidth * 0.28,
  });

  async function getData() {
    try {
      setLoading(true);
      const prescriptionsCollection = client.db(dbName).collection("Prescription");
      const prescriptionsQuery: Prescription[] = await prescriptionsCollection.find({ deleted: false });
      setPrescriptions(prescriptionsQuery);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (client && user) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, user]);

  useEffect(() => {
    setSetCurrentSeance(selectedDay?.seances?.[0]);
  }, [selectedDay]);
  const [displayMode, setDiplayMode] = useState<"row" | "column">("column");
  const dragRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [positionSeance, setPositionSeance] = useState<"fixedLeft" | "free">("fixedLeft");
  const [currentSeance, setSetCurrentSeance] = useState<SeanceWithEntities | null>(null);
  const theme = useTheme();
  const params = useQueryParams();
  const navigate = useNavigate();
  const correctDate = currentSeance?.plannedOn < new Date();

  function getStatus(status: "to-do" | "done" | "ready-to-bill" | "put-on-hold" | "billed") {
    switch (status) {
      case "to-do":
        return "Séance à faire";
      case "done":
        return "Séance faite";
      case "ready-to-bill":
        return "Prête à facturer";
      case "billed":
        return "Facturée";
      case "put-on-hold":
        return "Mise en attente";
      default:
        break;
    }
  }

  function onResize(event, { node, size, handle }) {
    setSize({ width: size.width, height: size.height });
    if (size.width > size.height && displayMode === "column") setDiplayMode("row");
    if (size.width < size.height && displayMode === "row") setDiplayMode("column");
  }

  function ondrag({ x, y }) {
    setPosition({ x, y });
  }

  function isPrescriptionIncomplete(seance: SeanceWithEntities): boolean {
    return seance.cares.some((care) => {
      const prescription = prescriptions.find((p) => p._id === care.prescription);
      return (!prescription?.media?._id || !prescription?.prescriptionDate) && !prescription?.empty;
    });
  }

  async function updateSeanceToToDo() {
    try {
      setLoading(true);
      const seancesCollection = client.db(dbName).collection("Seance");
      await seancesCollection.updateOne({ _id: currentSeance._id }, { $set: { status: "to-do" }, $unset: { doneBy: "" } });
      toast.success("Séance mise à jour");
      currentSeance.status = "to-do";
      setNeedRefresh(true);
      let to = qs.stringify({ ...params }, { arrayFormat: "index" });
      navigate(`/nurse/app/offices/${currentOfficeInfo?.officeId}/agenda/${patient._id}?${to}`, { replace: true });
    } catch (e) {
      console.log("=> error:", e);
      toast.error("Une erreur est survenue lors de la mise à jour de votre séance.");
    } finally {
      setLoading(false);
    }
  }

  async function updateSeanceToDone(values: any) {
    try {
      setLoading(true);
      if (values?.praticien?.value.userId) {
        const seancesCollection = client.db(dbName).collection("Seance");
        const surcharges = [];
        if (values?.mie) surcharges.push("mie");
        if (values?.mci) surcharges.push("mci");
        if (values?.mau) surcharges.push("mau");

        const set = {
          status: "done",
          doneBy: values?.praticien?.value.userId,
          nightRate: values?.nightRate,
          billingMode: values?.billingMode?.value,
          relocation: values?.relocation?.value,
          ...(!!surcharges?.length ? { surcharges } : {}),
          ...(values?.kmSupp ? { kmSupp: String(values?.kmSupp) } : {}),
          ...(values?.replacing?.value?.userId ? { replacing: values?.replacing?.value?.userId } : {}),
        };

        const unset = {
          ...(!values?.replacing ? { replacing: "" } : {}),
          ...(!values?.kmSupp ? { kmSupp: "" } : {}),
          ...(!surcharges?.length ? { surcharges: "" } : {}),
        };

        await seancesCollection.updateOne(
          { _id: currentSeance._id },
          {
            $set: set,
            $unset: unset,
          }
        );
        toast.success("Séance mise à jour");
        currentSeance.status = "done";
        currentSeance.doneBy = String(values?.praticien?.value.userId);
        currentSeance.nightRate = values?.nightRate;
        currentSeance.surcharges = surcharges;
        currentSeance.kmSupp = values?.kmSupp ? String(values?.kmSupp) : "";
        currentSeance.billingMode = values?.billingMode?.value;
        currentSeance.relocation = values?.relocation?.value;
        currentSeance.replacing = String(values?.replacing?.value?.userId);
        setNeedRefresh(true);
        let to = qs.stringify({ ...params }, { arrayFormat: "index" });
        navigate(`/nurse/app/offices/${currentOfficeInfo?.officeId}/agenda/${patient._id}?${to}`, { replace: true });
      }
    } catch (e) {
      console.log("=> error:", e);
      toast.error("Une erreur est survenue lors de la mise à jour de votre séance.");
    } finally {
      setLoading(false);
    }
  }

  if (!selectedDay) return <Box />;

  const createBy = praticiens.find((praticien) => praticien?.userId === currentSeance?.createdBy);
  const doneBy = praticiens.find((praticien) => praticien?.userId === currentSeance?.doneBy);
  const readyToBillBy = praticiens.find((praticien) => praticien?.userId === currentSeance?.readyToBillBy);
  const billedBy = praticiens.find((praticien) => praticien?.userId === currentSeance?.billedBy);
  const replacing = praticiens.find((praticien) => praticien?.userId === currentSeance?.replacing);

  return (
    <Draggable
      handle=".handleDrag"
      disabled={positionSeance === "fixedLeft"}
      bounds={{
        left: 0,
        top: 0,
        right: window?.innerWidth,
        bottom: window?.innerHeight,
      }}
      position={positionSeance === "fixedLeft" ? { x: 0, y: 0 } : position}
      onDrag={(e, data) => ondrag(data)}
    >
      <Resizable
        width={size.width}
        height={size.height}
        minConstraints={[300, 300]}
        maxConstraints={[window?.innerWidth || 1200, window?.innerHeight || 800]}
        onResize={onResize}
      >
        <ResizableBox ref={dragRef} style={{ minWidth: size.width, minHeight: size.height }} width={size.width} height={size.height} handle={<Box />}>
          <Box
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            style={{
              backgroundColor: "#fff",
              boxShadow: "rgb(161, 161, 161) 0px 0px 5px 0px",
              borderRadius: 5,
              overflow: "hidden",
            }}
          >
            {/* header */}
            <Box
              width="100%"
              height="30px"
              bgcolor={theme.palette.primary.light}
              pl={1}
              pr={1}
              className="handleDrag"
              justifyContent="space-between"
              alignItems="center"
            >
              {selectedDay?.date && (
                <Text display="block" fontWeight="500" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" width="100%">
                  Détail de la journée du {format(selectedDay?.date, "iiii dd MMMM")}
                </Text>
              )}
              <Box justifyContent="center" alignItems="center">
                <Box
                  onClick={() => {
                    setSize({
                      height: window?.innerHeight,
                      width: window?.innerWidth * 0.28,
                    });
                    setPositionSeance("fixedLeft");
                    setDiplayMode("column");
                  }}
                  style={{ cursor: "pointer", marginRight: 10 }}
                >
                  <Box width={20} height={15} style={{ border: "solid 2px #181818d2" }} p="2px">
                    <Box width="30%" height="100%" bgcolor="#181818d2" />
                  </Box>
                </Box>
                <Box
                  onClick={() => {
                    setPositionSeance("free");
                    setSize({ height: 600, width: 1000 });
                    setDiplayMode("row");
                  }}
                  style={{ cursor: "pointer", marginRight: 20 }}
                >
                  <Box width={20} height={15} style={{ border: "solid 2px #181818d2" }} p="2px">
                    <Box width="100%" height="100%" bgcolor="#181818d2" />
                  </Box>
                </Box>
                <Box onClick={() => setSelectedDay(null)} style={{ cursor: "pointer" }}>
                  <CloseIcon style={{ color: "#181818", fontSize: 20 }} />
                </Box>
              </Box>
            </Box>
            {/* body */}
            <Box width="100%" height="calc(100% - 30px)" justifyContent="center" alignItems="flex-start" flexDirection={displayMode}>
              {/* seances map*/}
              <Box
                height={displayMode === "row" ? "100%" : "120px"}
                width={displayMode === "row" ? "30%" : "100%"}
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                flexDirection={displayMode === "row" ? "column" : "row"}
                style={{
                  overflow: "hidden",
                  overflowX: displayMode === "row" ? "hidden" : "scroll",
                  overflowY: displayMode === "row" ? "scroll" : "hidden",
                }}
              >
                {selectedDay?.seances
                  ?.sort((a, b) => new Date(a.plannedOn).getTime() - new Date(b.plannedOn).getTime())
                  ?.map((seance) => (
                    <Box
                      key={`x${seance?._id}-${seance.status}`}
                      p={2}
                      m={2}
                      minWidth={displayMode === "row" ? "90%" : "200px"}
                      bgcolor={theme.colors.seanceStatus[seance.status]}
                      flexDirection="column"
                      style={{
                        boxShadow: "rgb(161, 161, 161) 0px 0px 2px 0px",
                        borderRadius: 5,
                        cursor: "pointer",
                        outline: seance?._id === currentSeance?._id ? "solid #8c8cf8 2px" : "none",
                      }}
                      onClick={() => setSetCurrentSeance(seance)}
                    >
                      {seance?.plannedOn && (
                        <Text fontSize={14} style={{ fontWeight: 500 }}>
                          Seance de {seance.plannedOn && format(seance?.plannedOn, "HH:mm")}
                        </Text>
                      )}
                      <Text fontSize={14} style={{ fontWeight: 500 }}>
                        {getStatus(seance.status)}
                      </Text>
                    </Box>
                  ))}
              </Box>
              {/* detail seance */}
              {!!currentSeance && (
                <>
                  {isPrescriptionIncomplete(currentSeance) && (
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyItems="center"
                      p={2}
                      m={2}
                      style={{
                        backgroundColor: "#FFEBEB",
                        borderRadius: "5px",
                      }}
                    >
                      <WarningIcon style={{ color: "#FF0000", fontSize: "24px" }} />
                      <Text
                        style={{
                          color: "#FF0000",
                          textAlign: "left",
                          marginLeft: "10px",
                        }}
                      >
                        Ordonnance incomplète, veuillez vous connecter à l'application pour modifier l'ordonnance.
                      </Text>
                    </Box>
                  )}
                  <Box
                    height={displayMode === "row" ? "100%" : "calc(100% - 120px)"}
                    width={displayMode === "row" ? "70%" : "100%"}
                    style={{ overflowY: "scroll", position: "relative" }}
                    flexDirection="column"
                  >
                    <Box height={60} p={3} flexDirection="row" alignItems="center" width="100%" bgcolor={theme.colors.bgPale as string}>
                      <ArticleIcon style={{ color: "#000", fontSize: 20 }} />
                      <Box pr={1} />
                      Traitements
                    </Box>
                    <Box flexDirection="column" pb={1}>
                      {currentSeance.cares?.map((filteredCare) => {
                        return (
                          <Box key={filteredCare?._id} justifyContent="flex-start" alignItems="flex-start" p={1} pb={0}>
                            <Box
                              style={{
                                borderRadius: 20,
                                minHeight: 14,
                                minWidth: 14,
                                backgroundColor: filteredCare?.category?.color,
                                margin: 2,
                                marginRight: 5,
                              }}
                            />
                            <p
                              style={{
                                fontSize: "12px",
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              {filteredCare?.name}{" "}
                              {filteredCare?.quotations?.map((q, i) => (
                                <span
                                  key={`${filteredCare._id}-${q.keyLetter.label}-${q.coefficient.value}${i}`}
                                  style={{
                                    color: "#ff008d",
                                    fontSize: "11px",
                                    fontWeight: 600,
                                    margin: 0,
                                  }}
                                >
                                  ({q.keyLetter.label} {q.coefficient.value}){filteredCare?.quotations?.length - 1 !== i && <Text> + </Text>}
                                </span>
                              ))}
                            </p>
                          </Box>
                        );
                      })}
                    </Box>
                    {currentSeance.status !== "to-do" && (
                      <>
                        <Box height={60} p={3} flexDirection="row" alignItems="center" width="100%" bgcolor={theme.colors.bgPale as string}>
                          <InfoIcon style={{ color: "#000", fontSize: 20 }} />
                          <Box pr={1} />
                          Informations
                        </Box>
                        <Box flexDirection="column" pb={1}>
                          <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
                            <AccessTimeIcon
                              style={{
                                color: "#000",
                                fontSize: 18,
                                margin: 2,
                                marginRight: 5,
                              }}
                            />
                            <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                              {currentSeance?.duration} minutes
                            </Text>
                          </Box>
                          {!!currentSeance?.surcharges?.length && (
                            <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
                              <PostAddIcon
                                style={{
                                  color: "#000",
                                  fontSize: 18,
                                  margin: 2,
                                  marginRight: 5,
                                }}
                              />
                              <Box fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                                {currentSeance?.surcharges?.map((maj) => (
                                  <Text
                                    style={{
                                      textTransform: "uppercase",
                                      marginRight: 10,
                                    }}
                                  >
                                    {maj}
                                  </Text>
                                ))}
                              </Box>
                            </Box>
                          )}
                          {currentSeance?.billingMode && (
                            <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
                              <PixIcon
                                style={{
                                  color: currentSeance?.billingMode !== patient?.billingMode ? "#ff0000" : "#000",
                                  fontSize: 18,
                                  margin: 2,
                                  marginRight: 5,
                                }}
                              />
                              <Text
                                fontSize={12}
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  color: currentSeance?.billingMode !== patient?.billingMode ? "#ff0000" : "",
                                }}
                              >
                                {getBillingMode(currentSeance?.billingMode)}
                              </Text>
                            </Box>
                          )}
                          {currentSeance?.relocation?.label && (
                            <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
                              <EditRoadIcon
                                style={{
                                  color: currentSeance?.billingMode !== patient?.billingMode ? "#ff0000" : "#000",
                                  fontSize: 18,
                                  margin: 2,
                                  marginRight: 5,
                                }}
                              />
                              <Text
                                fontSize={12}
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  color: currentSeance?.billingMode !== patient?.billingMode ? "#ff0000" : "",
                                }}
                              >
                                {currentSeance?.relocation?.label} {currentSeance?.kmSupp ? `- ${currentSeance?.kmSupp} kms` : ""}
                              </Text>
                            </Box>
                          )}
                          {/* {replacing && (
                          <Box justifyContent="flex-start" alignItems="center" p={1} pb={0} flexDirection="row">
                            <CheckCircleIcon
                              style={{
                                color: "#000",
                                fontSize: 18,
                                margin: 2,
                                marginRight: 5,
                              }}
                            />
                            <Box>
                              <Text
                                fontSize={12}
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  alignItems: "center",
                                  marginRight: 5,
                                }}
                              >
                                Séance réalisé par
                                <PersonIcon style={{ color: doneBy?.color, fontSize: 20 }} />
                                {doneBy?.userFirstName} {doneBy?.userLastName},
                              </Text>

                              <Text
                                fontSize={12}
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  alignItems: "center",
                                  marginRight: 5,
                                }}
                              >
                                Remplaçant de{" "}
                                <PersonIcon
                                  style={{
                                    color: replacing?.color,
                                    fontSize: 20,
                                  }}
                                />
                                {replacing?.userFirstName} {replacing?.userLastName}
                              </Text>
                            </Box>
                          </Box>
                        )} */}
                        </Box>
                      </>
                    )}
                    <Box height={60} p={3} flexDirection="row" alignItems="center" width="100%" bgcolor={theme.colors.bgPale as string}>
                      <PersonIcon style={{ color: "#000", fontSize: 20 }} />
                      <Box pr={1} />
                      Patient
                    </Box>
                    <Box flexDirection="column" pb={1}>
                      <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
                        <PersonIcon
                          style={{
                            color: "#000",
                            fontSize: 18,
                            margin: 2,
                            marginRight: 5,
                          }}
                        />
                        <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                          {patient?.firstname} {patient?.lastname}
                        </Text>
                      </Box>
                      {patient?.socialSecurityNumber && (
                        <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
                          <HealthAndSafetyIcon
                            style={{
                              color: "#000",
                              fontSize: 18,
                              margin: 2,
                              marginRight: 5,
                            }}
                          />
                          <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                            {formatSocialSecurityNumber(patient?.socialSecurityNumber)}
                          </Text>
                        </Box>
                      )}
                      {patient?.birth && (
                        <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
                          <InsertInvitationIcon
                            style={{
                              color: "#000",
                              fontSize: 18,
                              margin: 2,
                              marginRight: 5,
                            }}
                          />
                          <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                            {patient?.birth && format(patient?.birth, "dd MMMM yyyy")}
                          </Text>
                        </Box>
                      )}
                      {patient?.address && (
                        <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
                          <LocationOnIcon
                            style={{
                              color: "#000",
                              fontSize: 18,
                              margin: 2,
                              marginRight: 5,
                            }}
                          />
                          <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                            {patient?.address}
                          </Text>
                        </Box>
                      )}
                      {patient?.phoneNumber && (
                        <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
                          <LocalPhoneIcon
                            style={{
                              color: "#000",
                              fontSize: 18,
                              margin: 2,
                              marginRight: 5,
                            }}
                          />
                          <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                            {patient?.phoneNumber}
                          </Text>
                        </Box>
                      )}
                    </Box>
                    <Box height={60} p={3} flexDirection="row" alignItems="center" width="100%" bgcolor={theme.colors.bgPale as string}>
                      <AccessTimeFilledIcon style={{ color: "#000", fontSize: 20 }} />
                      <Box pr={1} />
                      Historique
                    </Box>
                    <Box flexDirection="column" pb={1}>
                      {createBy && currentSeance?.createdAt && (
                        <Box justifyContent="flex-start" alignItems="center" p={1} flexWrap="wrap">
                          <AccessTimeIcon style={{ fontSize: 18, marginRight: 5 }} />
                          <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                            Séance créée par{" "}
                          </Text>
                          <Text
                            fontSize={12}
                            style={{
                              fontSize: "12px",
                              fontWeight: 600,
                              alignItems: "center",
                              marginRight: 5,
                            }}
                          >
                            <PersonIcon style={{ color: createBy?.color, fontSize: 20 }} />
                            {createBy?.userFirstName} {createBy?.userLastName}
                          </Text>
                          <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                            le {currentSeance.createdAt && format(currentSeance.createdAt, "dd MMMM yyyy à HH:mm")}
                          </Text>
                        </Box>
                      )}

                      {doneBy && currentSeance.plannedOn && currentSeance.status !== "to-do" && (
                        <Box justifyContent="flex-start" alignItems="center" p={1} flexWrap="wrap">
                          <AccessTimeIcon style={{ fontSize: 18, marginRight: 5 }} />
                          <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                            Séance validée par{" "}
                          </Text>
                          <Text
                            fontSize={12}
                            style={{
                              fontSize: "12px",
                              fontWeight: 600,
                              alignItems: "center",
                              marginRight: 5,
                            }}
                          >
                            <PersonIcon style={{ color: doneBy?.color, fontSize: 20 }} />
                            {doneBy?.userFirstName} {doneBy?.userLastName}
                          </Text>
                          {replacing && (
                            <Text
                              fontSize={12}
                              style={{
                                fontSize: "12px",
                                fontWeight: 600,
                                alignItems: "center",
                                marginRight: 5,
                              }}
                            >
                              remplaçant de
                              <PersonIcon
                                style={{
                                  color: replacing?.color,
                                  fontSize: 20,
                                }}
                              />
                              {replacing?.userFirstName} {replacing?.userLastName}
                            </Text>
                          )}
                          <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                            le {currentSeance.plannedOn && format(currentSeance.plannedOn, "dd MMMM yyyy à HH:mm")}
                          </Text>
                        </Box>
                      )}

                      {readyToBillBy && currentSeance.status !== "to-do" && (
                        <Box justifyContent="flex-start" alignItems="center" p={1} flexWrap="wrap">
                          <AccessTimeIcon style={{ fontSize: 18, marginRight: 5 }} />
                          <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                            Séance envoyé pour facturation par{" "}
                          </Text>
                          <Text
                            fontSize={12}
                            style={{
                              fontSize: "12px",
                              fontWeight: 600,
                              alignItems: "center",
                              marginRight: 5,
                            }}
                          >
                            <PersonIcon
                              style={{
                                color: readyToBillBy?.color,
                                fontSize: 20,
                              }}
                            />
                            {readyToBillBy?.userFirstName} {readyToBillBy?.userLastName}
                          </Text>
                          <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                            le {currentSeance.readyToBillAt && format(currentSeance.readyToBillAt, "dd MMMM yyyy à HH:mm")}
                          </Text>
                        </Box>
                      )}

                      {billedBy && currentSeance.status !== "to-do" && (
                        <Box justifyContent="flex-start" alignItems="center" p={1} flexWrap="wrap">
                          <AccessTimeIcon style={{ fontSize: 18, marginRight: 5 }} />
                          <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                            Séance facturée pour{" "}
                          </Text>
                          <Text
                            fontSize={12}
                            style={{
                              fontSize: "12px",
                              fontWeight: 600,
                              alignItems: "center",
                              marginRight: 5,
                            }}
                          >
                            <PersonIcon style={{ color: billedBy?.color, fontSize: 20 }} />
                            {billedBy?.userFirstName} {billedBy?.userLastName}
                          </Text>
                          <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 600 }}>
                            le {currentSeance.billedAt && format(currentSeance.billedAt, "dd MMMM yyyy à HH:mm")}
                          </Text>
                        </Box>
                      )}
                    </Box>
                    <Box p={5} />
                    {/* FOOTER */}

                    {correctDate && currentSeance?.status === "to-do" && (
                      <Box
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        p={2}
                        width={displayMode === "row" ? "70%" : "100%"}
                        style={{ position: "fixed", bottom: 0 }}
                      >
                        <ModalConfirmDone
                          patient={patient}
                          onConfirm={updateSeanceToDone}
                          buttonText="Valider la séance"
                          praticiens={praticiens}
                          updateType="updateOneDone"
                        />
                      </Box>
                    )}
                    {correctDate && currentSeance?.status === "done" && (
                      <Box
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        p={2}
                        width={displayMode === "row" ? "70%" : "100%"}
                        style={{ position: "fixed", bottom: 0 }}
                      >
                        <Button onClick={updateSeanceToToDo}>
                          <Text>Dévalider la séance</Text>
                        </Button>
                        <Box p={1} />
                        {/* <ModalConfirmSend
                          patient={patient}
                          onConfirm={updateSeanceToReadyToBill}
                          buttonText="Envoyer à Cofidoc"
                          praticiens={praticiens}
                          updateType="updateOne"
                        /> */}
                      </Box>
                    )}
                  </Box>
                </>
              )}
              {!currentSeance && (
                <Box
                  height={displayMode === "row" ? "100%" : "calc(100% - 95px)"}
                  width={displayMode === "row" ? "70%" : "100%"}
                  style={{ overflowY: "scroll" }}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  Sélectionnez une séance pour afficher le détail
                </Box>
              )}
            </Box>
          </Box>
        </ResizableBox>
      </Resizable>
    </Draggable>
  );
}
