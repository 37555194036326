import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useAppContext } from "@/app/common/provider/AppProvider";
import { Box, Button, Card, Text } from "@/ui";
import { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { useTheme } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { CofidocBilling, fetchCofidocBilling } from "@/api/taxi/fetchCofidocBilling";
import { CofidocHistory, fetchCofidocHistory } from "@/api/taxi/fetchCofidocHistory";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { addMonths, eachMonthOfInterval, format, subMonths } from "date-fns";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function DataGraph() {
  const theme = useTheme();
  const { client, user } = useMongoContext();
  const { setLoading, loading } = useAppContext();
  const [history, setHistory] = useState<CofidocHistory[]>();
  const [billing, setBilling] = useState<CofidocBilling[]>();
  const [start, setStart] = useState(subMonths(new Date(), 2));
  const [end, setEnd] = useState(addMonths(new Date(), 2));

  // Get data function
  async function getData() {
    try {
      setLoading(true);
      const h = await fetchCofidocHistory();
      setHistory(h);
      const b = await fetchCofidocBilling();
      setBilling(b);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user && client) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client, start, end]);

  const options = {
    plugins: {
      title: {
        display: false,
      },
    },
    responsive: true,
  };

  const labels = eachMonthOfInterval({ start, end }).map((date) => format(date, "MMMM yyyy"));

  const billingAmounts = labels.map((label, index) => {
    const date = new Date(start.getFullYear(), start.getMonth() + index, 1);
    return billing?.reduce((total, b) => {
      const billingDate = new Date(b.date);
      if (billingDate.getMonth() === date.getMonth() && billingDate.getFullYear() === date.getFullYear()) {
        return total + Number(b.amount);
      }
      return total;
    }, 0);
  });

  const turnoverAmounts = labels.map((label, index) => {
    const date = new Date(start.getFullYear(), start.getMonth() + index, 1);
    return history?.reduce((total, h) => {
      const historyDate = new Date(h.date);
      if (historyDate.getMonth() === date.getMonth() && historyDate.getFullYear() === date.getFullYear()) {
        return total + h.turnover;
      }
      return total;
    }, 0);
  });

  const data = {
    labels,
    datasets: [
      {
        label: "Chiffre d'affaire",
        data: turnoverAmounts,
        borderColor: `${theme.colors.blueSky.main}`,
        backgroundColor: `${theme.colors.blueSky.main}50`,
        borderWidth: 2,
        borderRadius: 5,
        stack: "Stack 0",
      },
      {
        label: "Factures Cofidoc",
        data: billingAmounts,
        borderColor: `${theme.colors.magenta.main}`,
        backgroundColor: `${theme.colors.magenta.main}50`,
        borderWidth: 2,
        borderRadius: 5,
        stack: "Stack 1",
      },
    ],
  };

  const NavigationBar = () => {
    return (
      <Card display="flex" justifyContent="space-between" alignItems="center" width="100%" p={2}>
        <Button
          onClick={() => {
            setStart(subMonths(start, 1));
            setEnd(subMonths(end, 1));
          }}
        >
          <ArrowBackIcon />
        </Button>
        <Text>
          {format(new Date(start), "MMMM yyyy")} à {format(new Date(end), "MMMM yyyy")}
        </Text>
        <Button
          onClick={() => {
            setStart(addMonths(start, 1));
            setEnd(addMonths(end, 1));
          }}
        >
          <ArrowForwardIcon />
        </Button>
      </Card>
    );
  };

  return (
    <>
      <NavigationBar />
      <Box flexDirection="column" width="100%" height="100%">
        {!loading && <Bar data={data} options={options} />}
      </Box>
    </>
  );
}