import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useUserClient } from "@/app/nurse/provider/UserClientProvider";
import { Patient, Relocation } from "@/types/nurse";
import { Box, Text, TextField } from "@/ui";
import RadioGroup from "@/ui/field/RadioGroupField";
import { SelectField } from "@/ui/field/SelectField";
import { Divider, FormLabel, Radio, useTheme } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";

type Props = { initialValues: Partial<Patient>; handleSubmit: any };

export const PatientForm = ({ initialValues, handleSubmit }: Props) => {
  const [relocations, setRelocations] = useState([]);
  const theme = useTheme();
  const { dbName } = useApplicationContext();
  const { client } = useMongoContext();
  const { isKine } = useUserClient();

  async function getData() {
    const relocationCollection = client.db(dbName).collection("Relocations");
    const res = await relocationCollection.find({});
    setRelocations(res);
  }

  useEffect(() => {
    getData();
  }, []);

  const commonsRelocation = relocations?.filter((c) => c._id === "ifd");
  const infiRelocationsIds = ["ik", "iks", "ikm"];
  const kineRelocations = relocations?.filter((c) => !infiRelocationsIds.includes(c._id));
  const infiRelocations = relocations?.filter((c) => infiRelocationsIds.includes(c._id));
  const entries = isKine ? [...kineRelocations] : [...infiRelocations, ...commonsRelocation];
  console.log("=>  entries:", entries);

  return (
    <Box flexDirection="column" width="100%" height="100%" minHeight="100%">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue, values }) => {
          console.log("values?.relocation", values?.relocation);

          return (
            <Box flexDirection="column" width="100%" p={2} height="100%" minHeight="100%">
              <Box width="100%" justifyContent="center" alignItems="center" p={2} flexDirection="column">
                <Text fontSize={20}>Nouveau patient</Text>
                <Text fontSize={12} p={1}>
                  Saisissez les informations de votre patient
                </Text>
              </Box>
              <Divider style={{ width: "100%", backgroundColor: theme.palette.primary.light }} />
              <Box flexDirection="column" alignItems="center" style={{ overflowY: "scroll" }} p={2} height="100%">
                <Box width="80%" p={2}>
                  <TextField label="Numéro de sécurité social" name="socialSecurityNumber" width="100%" />
                </Box>
                <Box width="80%" p={2}>
                  <TextField label="Nom de famille" name="lastname" width="100%" />
                </Box>
                <Box width="80%" p={2}>
                  <TextField label="Prénom" name="firstname" width="100%" />
                </Box>
                <Box width="80%" p={2}>
                  <TextField label="Date de naissance" name="birth" width="100%" />
                </Box>
                <Box width="80%" p={2}>
                  <TextField label="Prénom" name="name" width="100%" />
                </Box>
                <Box width="80%" p={2} flexDirection="column">
                  <FormLabel>Déplacement</FormLabel>
                  <Box flexDirection="row" alignItems="center">
                    <Box flexDirection="row" alignItems="center" flexWrap="wrap" mb={1} mr={3}>
                      <Radio
                        sentry-label={`Event: Selectionner domicile`}
                        onClick={() => setFieldValue("relocation", { _id: "ifd", label: "IFD" })}
                        color="primary"
                        value={""}
                        checked={values?.relocation?._id !== "office" && values?.relocation?._id !== "none" ? true : false}
                      />
                      <Text>Domicile</Text>
                    </Box>
                    <Box flexDirection="row" alignItems="center" flexWrap="wrap" mb={1} mr={3}>
                      <Radio
                        sentry-label={`Event: Selectionner cabinet`}
                        onClick={() => {
                          setFieldValue("relocation", { _id: "office", label: "Cabinet" });
                          setFieldValue("kmSupp", null);
                        }}
                        color="primary"
                        value={""}
                        checked={values?.relocation?._id === "office" ? true : false}
                      />
                      <Text>Cabinet</Text>
                    </Box>
                    <Box flexDirection="row" alignItems="center" flexWrap="wrap" mb={1} mr={3}>
                      <Radio
                        onClick={() => {
                          setFieldValue("relocation", { _id: "none", label: "Aucun" });
                          setFieldValue("kmSupp", null);
                        }}
                        sentry-label={`Event: Selectionner aucun`}
                        color="primary"
                        value={""}
                        checked={values?.relocation?._id === "none" ? true : false}
                      />
                      <Text>Aucun</Text>
                    </Box>
                  </Box>
                </Box>
                {values?.relocation?._id !== "office" && values?.relocation?._id !== "none" && (
                  <Box width="80%" p={2} flexDirection="column">
                    <Field
                      component={SelectField}
                      data={[...relocations]}
                      defaultValueByIndex={relocations?.findIndex((relocation) => relocation?._id === values?.relocation?._id)}
                      rowTextForSelection={(item: Relocation) => item?.label}
                      buttonTextAfterSelection={(item: Relocation) => item?.label}
                      sentry-label={`Event: Selectionner indemnité`}
                      onSelect={(item: Relocation) => {
                        setFieldValue("relocation", item);
                        if (item?._id === "ifd") setFieldValue("kmSupp", null);
                        if (item?._id === "none") setFieldValue("kmSupp", null);
                      }}
                      searchPlaceHolder="ifd"
                      label="Indemnité"
                      name="relocation"
                      width="100%"
                    />

                    <Box width="100%" pt={2} zIndex={0}>
                      {!!values?.relocation?._id && ["ik", "ikm", "iks"].includes(values?.relocation?._id) && (
                        <TextField label="Kilomètres réels" name="kmSupp" width="100%" />
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};
