import { Box, Text } from "@/ui";
import { useTheme } from "@mui/material";
import "react-calendar/dist/Calendar.css";
import History from "./History";
import DocsToSend from "./DocsToSend";

export default function Activity() {
  const theme = useTheme();

  return (
    <Box flexDirection="column" width="98%" height="98%" alignItems="center" style={{ backgroundColor: "#fffffff6", borderRadius: 5 }}>
      <Box justifyContent="center" alignItems="center" width="100%" height="60px" position="relative">
        <Box
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          position="relative"
          borderBottom={`solid 1px ${theme.colors.light}`}
        >
          <Box justifyContent="center" alignItems="center">
            <Text fontWeight={300} fontSize={26} pl={3}>
              Activité
            </Text>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          width: "100%",
          height: "calc(100% - 60px)",
          position: "relative",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Box height="100%" width="60%" p={2} flexDirection="column">
          <DocsToSend />
        </Box>
        <Box height="98%" borderRight={`solid 1px ${theme.colors.light}`} />
        <Box height="100%" width="40%" p={2} flexDirection="column">
          <History />
        </Box>
      </Box>
    </Box>
  );
}
