export const BSI_FORFAIT_ID = [
  'ec883a1e-bbd7-431b-a6ba-44812c0678a2', // BSA
  '8874c567-9ad2-4d0a-956d-3349cda2f044', //BSB
  '5fad57c0-781c-4187-8f6c-fca123fdbcb8', // BSC
];

export const PRISE_DE_SANG = '5ec4d48b-3b96-4a97-9001-cdac2e0c1202';
export const DEXTRO = '9851dd2b-804d-4368-be0c-8c45ff250da0';
export const INSULINE = 'bbef5e17-3ee9-41af-a8f4-306d23509d00';
export const DEXTO_INSULINE = '66fa2313-6d65-4c4a-ba68-60ac9e54ab32';
export const DEXTO_2_INSULINE = '7603330d-c8f5-4d6d-acd6-749f0072928d';
export const AUTER_PST = 'c880d718-7e41-4e38-a2a1-a6a91780cf6f';
export const IFI_ID = '4853195f-cc99-4dac-8094-30fe42ca4464';
export const IFI_ID_COUPLE = '795a6681-ae58-428e-a9f8-590e46a9e497';
export const IFI_IDS = [IFI_ID, IFI_ID_COUPLE];
export const PSM_L_C = '075e67cf-a9ac-418b-bf81-7415be235830';
export const AUTRES_PST = 'c880d718-7e41-4e38-a2a1-a6a91780cf6f';
export const PSM_L_C_I = '83538b59-c4e7-493f-bf4c-70105e619e9d';
export const BILANS_CATEGORY = '3p4q5r6s-7t8u-9v0w-1x2y-3z4a5b6c7d8';
export const NEUROLOGIE_CATEGORY = '8q9r0s1t-2u3v-4w5x-6y7z-8a9b0c1d2e3f';
export const NEUROLOGIE_BILAN_QUOTATION = 'f5c176fd-e84c-4192-a4dd-2204ec17bf00';
export const BILANS = ['cdb655d1-b2d4-477d-b1b8-6beefb0c4685', 'f5c176fd-e84c-4192-a4dd-2204ec17bf00'];
