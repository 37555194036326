import AppNurse from "./nurse/App";
import AppTaxi from "./taxi/App";
import { AppRouter } from "./Router";
import { AppThemeProvider } from "@/theme/ThemeProvider";
import { useApplicationContext } from "./common/provider/ApplicationContext";

function AppSelector() {
  const { application } = useApplicationContext();

  return (
    <>
      {(application === "inovidel" || application === "mobility") && <AppNurse />}
      {application === "taxi" && <AppTaxi />}
      {!application && (
        <AppThemeProvider>
          <AppRouter />
        </AppThemeProvider>
      )}
    </>
  );
}

export default AppSelector;
