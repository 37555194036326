import { FieldProps } from "formik";
import { Box } from "@/ui";
import { Checkbox as MUICheckbox } from "@mui/material";
import ErrorText from "../ErrorText";

export const CheckboxField = ({ field: { name, onChange, value }, form: { errors }, ...props }: FieldProps) => {
  const hasError = errors[name];
  return (
    <Box style={{ position: "relative", zIndex: 0 }}>
      <MUICheckbox name={name} checked={value} onChange={onChange} {...props} />

      {hasError && (
        <Box
          style={{
            position: "absolute",
            bottom: -20,
            height: 30,
            left: 5,
          }}
        >
          <ErrorText>{errors[name]}</ErrorText>
        </Box>
      )}
    </Box>
  );
};
