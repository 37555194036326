/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Text } from "@/ui";
import { useTheme } from "@mui/material";
import { format } from "date-fns";
import { Care, Prescription, Seance, StatusType } from "@/types/nurse";
import { BILANS_CATEGORY, BSI_FORFAIT_ID, IFI_IDS } from "@/utils/nurse/cotationsMapping";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { ReactNode, useEffect, useState } from "react";
import { getStatus } from "@/utils/nurse/getStatus";
import { getBackgroundByType } from "@/utils/nurse/getBackgroundByType";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

type CareItemProps = {
  displaySeanceNumber?: boolean;
  activePrescritpionMissingAlert?: boolean;
  care: Care;
  simple?: boolean;
  prescription?: Prescription;
};

function capitalizeFirstLetter(string: string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function CareItem({ displaySeanceNumber = true, activePrescritpionMissingAlert = true, care, simple, prescription }: CareItemProps) {
  const theme = useTheme();
  const { dbName } = useApplicationContext();
  const { client } = useMongoContext();
  const [seancesByCare, setSeanceByCare] = useState([]);
  const status = getStatus({ care });
  async function getData() {
    const seanceCollection = client.db(dbName).collection("Seance");
    const res = (await seanceCollection.find({ "cares._id": care._id, deleted: false })) as Seance[];
    setSeanceByCare(res);
  }

  useEffect(() => {
    getData();
  }, []);

  const ifIfiOrBsi = [...BSI_FORFAIT_ID, ...IFI_IDS].includes(care.cofidocCareId);
  const isBsi = [...BSI_FORFAIT_ID].includes(care.cofidocCareId);
  const isBilanForKine = BILANS_CATEGORY.includes(care?.category?._id);
  const hoursMorning = care.morning && care.preferredScheduledMorning && `${care.preferredScheduledMorning}`;
  const hoursMidday = care.midday && care.preferredScheduledMidday && `${care.preferredScheduledMidday}`;
  const hoursAfternoon = care.afternoon && care.preferredScheduledAfternoon && `${care.preferredScheduledAfternoon}`;
  const hoursNight = care.night && care.preferredScheduledNight && `${care.preferredScheduledNight}`;

  const formattedPhrase = [hoursMorning, hoursMidday, hoursAfternoon, hoursNight]
    ?.filter(Boolean)
    ?.reduce((acc, current, currentIndex, array): any => {
      const isLast = currentIndex + 1 === array?.length;
      acc += `${current} ${!isLast ? " - " : ""}`;
      return acc;
    }, "");

  return (
    <Box
      sentry-label={`Event: Naviguer vers l'édition d'une ordonnance`}
      onClick={() => {
        // setActionOpen && setActionOpen(undefined);
        // navigation.navigate("EditPrescription", { prescriptionId: care.prescription?._id || "" });
      }}
      bgcolor={theme.colors.white}
      borderRadius={1}
      flexDirection="row"
      width="100%"
      alignItems="center"
    >
      <Box justifyContent="center" alignItems="center" p={2}>
        <Box bgcolor="#fff" width={30} height={30} borderRadius={50}>
          <Box
            justifyContent="center"
            alignItems="center"
            bgcolor={`${care.category?.color}50`}
            border={`1px solid ${care.category?.color}`}
            style={{ borderRadius: 7, minHeight: 30, maxHeight: 30, minWidth: 30, maxWidth: 30 }}
          >
            <LocalHospitalIcon style={{ color: care.category?.color }} fontSize="small" />
          </Box>
        </Box>
      </Box>
      <Box flexDirection="column" justifyContent="space-between">
        <Box flexDirection="row" justifyContent="flex-start" alignItems="center" p={1}>
          <Text color={theme.colors.grey.main} fontSize={16} fontWeight="400" flexDirection="row" mr={1}>
            {capitalizeFirstLetter(care.name)}{" "}
          </Text>
          {care?.quotations?.map((q, i) => (
            <Text
              key={`${care._id}-${q.keyLetter.label}-${q.coefficient.value}${i}`}
              color={theme.palette.primary.main}
              fontSize={11}
              fontWeight="bold"
            >
              ({q.keyLetter.label}
              {!ifIfiOrBsi && ` ${q.coefficient.value}`}){care?.quotations?.length - 1 !== i && <Text> + </Text>}
            </Text>
          ))}
        </Box>
        <Box px={1}>
          {!care.nbMaxOfSeance ? (
            <>
              <Text color={theme.colors.grey.main} fontWeight="500" fontSize={12} flexDirection="row" mr={1}>
                Du
              </Text>
              <Text color={theme.colors.grey.main} fontWeight="800" fontSize={12} mr={1}>
                {care.start ? format(care.start, "dd MMMM yyyy") : ""}
              </Text>
              <Text color={theme.colors.grey.main} fontWeight="500" fontSize={12} mr={1} flexDirection="row">
                au
              </Text>
              <Text color={theme.colors.grey.main} fontWeight="800" fontSize={12} mr={1} flexDirection="row">
                {care.end ? format(care.end, "dd MMMM yyyy") : ""}
              </Text>
            </>
          ) : (
            <Text color={theme.colors.grey.main} fontWeight="500" fontSize={12} mr={1} flexDirection="row">
              Soins à la séance
            </Text>
          )}
        </Box>
        <Box px={1}>
          {!care.nbMaxOfSeance && !isBsi ? (
            <>
              {formattedPhrase && (
                <>
                  <Text color={theme.colors.grey.main} fontWeight="500" fontSize={12} mr={1}>
                    Passage à
                  </Text>
                  <Text marginRight={5} color={theme.colors.grey.main} fontWeight="800" fontSize={12} mr={1}>
                    {formattedPhrase}
                  </Text>
                </>
              )}
            </>
          ) : null}
        </Box>
        {displaySeanceNumber && (
          <Box justifyContent="flex-start" alignItems="center" flexDirection="row" p={1}>
            <Box
              flexDirection="row"
              alignItems="center"
              bgcolor={`${theme.colors.grey.light}80`}
              borderRadius={15}
              px={4}
              py={1}
              justifyContent="center"
            >
              <Text color={theme.colors.grey.main} fontWeight="600" fontSize={12}>
                ({care?.nbSeanceTodo || 0})
              </Text>
              <Text color={theme.colors.yellow.main} fontWeight="600" fontSize={12}>
                ({care?.nbSeanceDone || 0})
              </Text>
              <Text color={theme.colors.seanceReadyToBill} fontWeight="600" fontSize={12}>
                ({care?.nbSeanceReadyToBill || 0})
              </Text>
              <Text color={theme.colors.grey.main} fontWeight="600" fontSize={12}>
                {" "}
                / {care.nbMaxOfSeance ? care.nbMaxOfSeance : seancesByCare?.length}
              </Text>
            </Box>
            <Text fontSize={10} fontWeight="500" mr={2}></Text>
            {status !== "pending" && (
              <Box mr={2}>
                <Status type={care?.nbSeanceDone === 0 && status !== "over" ? "notStarted" : status}>
                  {status !== "over" && care?.nbSeanceDone === 0 ? "Nouveau" : "Terminé"}
                </Status>
              </Box>
            )}
            {activePrescritpionMissingAlert && !prescription?.media && !care?.prescription && (
              <Box px={6} py={0} borderRadius={30} bgcolor={theme?.colors?.red?.main}>
                <Text fontSize={10} fontWeight="500" color="#fff">
                  Ordonnance manquante
                </Text>
              </Box>
            )}
          </Box>
        )}
        {simple && (
          <Box justifyContent="flex-start" alignItems="center" flexDirection="row" p={1}>
            <Box
              flexDirection="row"
              alignItems="center"
              bgcolor={`${theme.colors.grey.light}50`}
              borderRadius={15}
              px={6}
              py={2}
              justifyContent="center"
            >
              <Text color={theme.colors.yellow.main} fontWeight="600" fontSize={14}>
                ({care?.nbSeanceDone || 0})
              </Text>
              <Text color={theme.colors.seanceReadyToBill} fontWeight="600" fontSize={14}>
                ({care?.nbSeanceReadyToBill || 0})
              </Text>
              <Text color={theme.colors.grey.main} fontWeight="600" fontSize={14}>
                {" "}
                / {care.nbMaxOfSeance ? care.nbMaxOfSeance : seancesByCare?.length}
              </Text>
            </Box>
            <Text fontSize={10} fontWeight="500" mr={2}></Text>
            {status !== "pending" && (
              <Box mr={2}>
                <Status type={care?.nbSeanceDone === 0 && status !== "over" ? "notStarted" : status}>
                  {status !== "over" && care?.nbSeanceDone === 0 ? "Nouveau" : "Terminé"}
                </Status>
              </Box>
            )}
            {activePrescritpionMissingAlert && !prescription?.media && !care?.prescription && (
              <Box px={6} py={0} borderRadius={30} bgcolor={theme?.colors?.red?.main}>
                <Text fontSize={10} fontWeight="500" color="#fff">
                  Ordonnance manquante
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

type StatusProps = {
  children: ReactNode;
  type: StatusType;
};

export function Status({ children, type }: StatusProps) {
  if (type === "pending") return <Box />;

  return (
    <Box px={6} py={0} borderRadius={30} bgcolor={getBackgroundByType(type)}>
      <Text fontSize={10} fontWeight="600" color={type === "over" ? "#fff" : "#fff"}>
        {children}
      </Text>
    </Box>
  );
}
