import { Link, useTheme } from "@mui/material";
import { Box } from "./Box";

export function Href({
  textconfirm,
  withconfirm,
  onClick,
  children,
  href,
  target,
  ...props
}: {
  textconfirm?: string;
  withconfirm?: boolean;
  onClick?: any;
  children: any;
  href: any;
  target: any;
}) {
  const theme = useTheme();
  return (
    <Link
      style={{ textDecoration: "none" }}
      href={href}
      target={target}
      className="cofidocHref"
      {...props}
    >
      <Box
        p={3}
        borderRadius={50}
        color="white"
        bgcolor={theme.colors.primary}
        sx={{
          borderWidth: 2,
          borderColor: theme.colors.primary,
          borderStyle: "solid",
          "&:hover": {
            backgroundColor: "white",
            color: theme.colors.primary,
          },
        }}
      >
        {children}
      </Box>
    </Link>
  );
}
