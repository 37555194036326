import { Care, StatusType } from "@/types/nurse";
import { compareAsc, isWithinInterval } from "date-fns";

export function getStatus({ care }: { care: Care }): StatusType {
  if (care?.stop) return "over";
  if (care?.nbMaxOfSeance) {
    if (care?.nbSeanceDone === 0) return "notStarted";
    if (care?.nbSeanceDone && care?.nbSeanceDone < care.nbMaxOfSeance) return "pending";
    if (care?.nbSeanceDone && care?.nbSeanceDone >= care.nbMaxOfSeance) return "over";
    return "pending";
  } else if (care?.nbSeance) {
    if (care?.nbSeanceDone === 0) return "notStarted";
    if (care?.nbSeanceDone && care?.nbSeanceDone < care?.nbSeance) return "pending";
    if (care?.nbSeanceDone && care?.nbSeanceDone >= care?.nbSeance) return "over";
  } else {
    const currentDate = new Date();
    const isCurrentDateWithinInterval =
      care?.start && care?.end && care?.start <= care?.end ? isWithinInterval(currentDate, { start: care?.start, end: care?.end }) : false;
    if (isCurrentDateWithinInterval) return "pending";
    return compareAsc(currentDate, care?.start || new Date()) === -1 ? "notStarted" : "over";
  }
  return "pending";
}
