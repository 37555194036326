import { createContext, useContext, useState } from "react";

type FormContextType = {
  patientForm: boolean;
  careForm: boolean;
  prescriptionForm: boolean;
  seanceForm: boolean;
  handleForm: (type: "patient" | "care" | "prescription" | "seance" | undefined) => void;
};

const FormContext = createContext<FormContextType>({} as FormContextType);

export function FormProvider({ children }: any) {
  const [careForm, setCareForm] = useState<boolean>(false);
  const [patientForm, setPatientForm] = useState<boolean>(false);
  const [prescriptionForm, setPrescriptionForm] = useState<boolean>(false);
  const [seanceForm, setSeanceForm] = useState<boolean>(false);

  function handleForm(type: "patient" | "care" | "prescription" | "seance") {
    switch (type) {
      case "care":
        setCareForm(true);
        setPatientForm(false);
        setPrescriptionForm(false);
        setSeanceForm(false);
        break;
      case "patient":
        setCareForm(false);
        setPatientForm(true);
        setPrescriptionForm(false);
        setSeanceForm(false);
        break;
      case "prescription":
        setCareForm(false);
        setPatientForm(false);
        setPrescriptionForm(true);
        setSeanceForm(false);
        break;
      case "seance":
        setCareForm(false);
        setPatientForm(false);
        setPrescriptionForm(false);
        setSeanceForm(true);
        break;
      case undefined:
        setCareForm(false);
        setPatientForm(false);
        setPrescriptionForm(false);
        setSeanceForm(false);
        break;
    }
  }
  return (
    <FormContext.Provider
      value={{
        patientForm,
        careForm,
        prescriptionForm,
        seanceForm,
        handleForm,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}

export function useFormContext() {
  const formcontext = useContext(FormContext);
  return formcontext;
}
